:root {
  --alpha-button-background: 1;
  --alpha-button-border: 1;
  --alpha-link: 0.85;
  --alpha-badge-border: 0.1;
}

@media (max-width: 767px) {
  #chat-window[class^='gorgias-chat'] {
    height: calc(100% - 121px) !important;
  }
  .only-mobile {
    display: block !important;
  }
}

.category_banner_header,
.category_banner_preheader {
  text-align: center;
  text-transform: uppercase;
}

.category_banner_preheader {
  font-weight: 500;
  line-height: 1.1;
  font-size: 17px;
  margin-bottom: 0;
  margin-top: 50px;
}

.category_banner_header {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.2rem;
}

.collection-hero__title.category_banner_header {
  margin: 15px 0 0 0;
}

.category_banner_header_left {
  display: none;
  font-weight: 600;
  font-size: 22px;
  color: #000;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
}

.collection-hero__title.category_banner_header_left {
  margin: 50px 0 20px 0;
}

.category_banner {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  overflow-x: auto;
}

.category_banner_item {
  margin: 0 1rem;
}

.category_banner img {
  max-width: 100%;
  margin: 0;
}

.category_banner_item a.banner-cta-title {
  display: block;
  color: #000;
  transform: unset;
  position: unset;
  padding-top: 10px;
  line-height: normal;
  font-size: 20px;
  width: auto;
  min-width: 100%;
  max-width: 100%;
  text-align: center;
  text-transform: lowercase;
  letter-spacing: 0.1em;
  font-weight: 700;
  border: none;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.category_banner_item p {
  text-align: center;
  font-size: 12px;
  letter-spacing: 1.2px;
  text-align: center;
  line-height: 1.5;
}

.category_banner figure img {
  max-width: 100%;
}

.fallback-preview-lp-marqee-section-title {
  padding: 50px 0 0;
}

.fallback-preview-lp-marqee-section-title h2 {
  font-size: 52px;
  line-height: 1;
  letter-spacing: .2rem;
  color: #000;
  margin-bottom: 10px;
  font-family: Baskerville MT Std !important;
  font-style: italic;
  text-transform: lowercase;
  text-align: center;
  font-weight: 100;
}

.template-summer-campaign .fallback-preview-lp-marqee-section-title h2 {
  font-family: Charpentier Sans Pro !important;
  text-transform: uppercase !important;
}

.fallback-preview-lp-details-wrp p {
  margin: 0 0 60px 0;
  text-align: center;
  font-size: 18px;
  color: #000;
  font-weight: 400;
}

.fallback-lp-featured-collection .fallback-preview-lp-grid-products {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -25px;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .product_img_wp a,
.product-upsell-items-wrapper .product_img_wp a {
  position: relative;
  overflow: hidden;
  display: block;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .product_img_wp a img,
.product-upsell-items-wrapper .product_img_wp a img {
  margin: 0 auto;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.fallback-lp-featured-collection .product_img_wp,
.fallback-preview-lp-pro-item .product_img_wp {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .product_img_wp a,
.product-upsell-items-wrapper .product_img_wp a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
}

.fallback-lp-featured-collection .product-badge,
.fallback-preview-lp-pro-item .product-badge,
.fallback-preview-lp-pro-item .new_product_badge {
  position: absolute;
  top: 10px;
  right: 10px;
  border: 1px solid #000;
  padding: 3px 10px;
  color: #000;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
  z-index: 2;
}

.ais-Hits-item .ais-hit--details .custom_current_one_color:before {
  content: unset;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .ais-hit--details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  margin-top: 10px;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .quick_view_size_wp,
.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .ais-hit--details.quick_view_opened .quick_add_btn,
.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .details_right_wrap .quick_close_btn {
  display: none;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .ais-hit--details.quick_view_opened .quick_close_btn {
  display: block;
  font-weight: 700;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .details_left_wrap {
  width: 100% !important;
  text-overflow: unset !important;
  white-space: nowrap !important;
  overflow: unset !important;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .details_left_wrap .quick_view_text {
  font-weight: 700;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .details_left_content_wp {
  white-space: normal;
  text-align: left;
  width: 65%;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .ais-hit--title {
  color: #000;
  text-transform: capitalize;
  line-height: 1.2;
  font-weight: normal;
  padding-bottom: 10px;
  margin: 0;
  letter-spacing: normal;
  font-size: 16px;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .ais-hit--price {
  font-weight: 700;
  color: #000000;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .details_right_wrap {
  width: 20% !important;
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
  padding-left: 10px;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .details_right_wrap {
  width: unset !important;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .details_right_wrap a {
  display: none;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .quick_add_btn {
  background-color: transparent;
  color: black !important;
  text-transform: uppercase;
  font-size: 16px;
  padding: 0;
  text-decoration: none;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-featured-btn.button_pos_bottom .btn {
  display: inline-block;
  max-width: 350px !important;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  border-color: gray;
  font-weight: 100;
}

.fallback-preview-lp-featured-btn.button_pos_bottom a {
  padding: 20px !important;
  line-height: 1;
  font-weight: 400;
  border: 1px solid #000 !important;
  text-decoration: none;
  color: black;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-featured-btn.button_pos_bottom {
  margin: 30px 0 60px;
  text-align: center;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper span.ais-hit--price-striked,
.product-upsell-items-wrapper .ais-hit--price-striked {
  text-decoration: line-through;
  color: #8a0000;
}

@media (min-width: 768px) {
  .desktop-hidden {
    display: none !important;
  }

  .fallback-preview-lp-details-wrp p {
    padding: 0 30px !important;
  }
}

@media (max-width: 768px) {
  .fallback-preview-lp-details-wrp p {
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    font-size: 14px;
  }

  .fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-featured-btn.button_pos_bottom {
    margin: 30px 0 30px;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .fallback-lp-featured-collection .fallback-preview-lp-grid-products {
    margin: 0 3% !important;
  }
  .only-mobile {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .mobile-hidden {
    display: none !important;
  }

  .fallback-lp-featured-collection .fallback-preview-lp-grid-products {
    margin: 0 0;
  }



  .fallback-lp-featured-collection .grid-wrapper {
    max-width: 50%;
    padding: 10px;
  }

  .fallback-lp-featured-collection .grid-wrapper:nth-child(4) {
    display: block;
  }

}

@media (max-width: 1024px) {
  .fallback-lp-featured-collection .fallback-preview-lp-grid-products {
    margin: 0 0;
  }

  .fallback-lp-featured-collection .grid-wrapper {
    padding: 10px 15px;
  }
}

.product-card-wrapper .card,
.contains-card--product {
  --border-radius: var(--product-card-corner-radius);
  --border-width: var(--product-card-border-width);
  --border-opacity: var(--product-card-border-opacity);
  --shadow-horizontal-offset: var(--product-card-shadow-horizontal-offset);
  --shadow-vertical-offset: var(--product-card-shadow-vertical-offset);
  --shadow-blur-radius: var(--product-card-shadow-blur-radius);
  --shadow-opacity: var(--product-card-shadow-opacity);
  --shadow-visible: var(--product-card-shadow-visible);
  --image-padding: var(--product-card-image-padding);
  --text-alignment: var(--product-card-text-alignment);
}

.collection-card-wrapper .card,
.contains-card--collection {
  --border-radius: var(--collection-card-corner-radius);
  --border-width: var(--collection-card-border-width);
  --border-opacity: var(--collection-card-border-opacity);
  --shadow-horizontal-offset: var(--collection-card-shadow-horizontal-offset);
  --shadow-vertical-offset: var(--collection-card-shadow-vertical-offset);
  --shadow-blur-radius: var(--collection-card-shadow-blur-radius);
  --shadow-opacity: var(--collection-card-shadow-opacity);
  --shadow-visible: var(--collection-card-shadow-visible);
  --image-padding: var(--collection-card-image-padding);
  --text-alignment: var(--collection-card-text-alignment);
}

.article-card-wrapper .card,
.contains-card--article {
  --border-radius: var(--blog-card-corner-radius);
  --border-width: var(--blog-card-border-width);
  --border-opacity: var(--blog-card-border-opacity);
  --shadow-horizontal-offset: var(--blog-card-shadow-horizontal-offset);
  --shadow-vertical-offset: var(--blog-card-shadow-vertical-offset);
  --shadow-blur-radius: var(--blog-card-shadow-blur-radius);
  --shadow-opacity: var(--blog-card-shadow-opacity);
  --shadow-visible: var(--blog-card-shadow-visible);
  --image-padding: var(--blog-card-image-padding);
  --text-alignment: var(--blog-card-text-alignment);
}

.contains-content-container,
.content-container {
  --border-radius: var(--text-boxes-radius);
  --border-width: var(--text-boxes-border-width);
  --border-opacity: var(--text-boxes-border-opacity);
  --shadow-horizontal-offset: var(--text-boxes-shadow-horizontal-offset);
  --shadow-vertical-offset: var(--text-boxes-shadow-vertical-offset);
  --shadow-blur-radius: var(--text-boxes-shadow-blur-radius);
  --shadow-opacity: var(--text-boxes-shadow-opacity);
  --shadow-visible: var(--text-boxes-shadow-visible);
}

.contains-media,
.global-media-settings {
  --border-radius: var(--media-radius);
  --border-width: var(--media-border-width);
  --border-opacity: var(--media-border-opacity);
  --shadow-horizontal-offset: var(--media-shadow-horizontal-offset);
  --shadow-vertical-offset: var(--media-shadow-vertical-offset);
  --shadow-blur-radius: var(--media-shadow-blur-radius);
  --shadow-opacity: var(--media-shadow-opacity);
  --shadow-visible: var(--media-shadow-visible);
}

/* base */

.no-js:not(html) {
  display: none !important;
}

html.no-js .no-js:not(html) {
  display: block !important;
}

.no-js-inline {
  display: none !important;
}

html.no-js .no-js-inline {
  display: inline-block !important;
}

html.no-js .no-js-hidden {
  display: none !important;
}

.page-width {
  max-width: var(--page-width);
  margin: 0 auto;
  padding: 0 1.5rem;
}

body:has(.section-header .drawer-menu) .announcement-bar-section .page-width {
  max-width: 100%;
}

/* .announcement-bar-section {
  max-height: 44px !important;
} */

.page-width.drawer-menu {
  max-width: 100%;
}

.page-width-desktop {
  padding: 0;
  margin: 0 auto;
}

.utility-bar__grid.page-width {
  padding: 0;
}

@media screen and (min-width: 750px) {
  .page-width {
    padding: 0 5rem;
  }

  .page-width--narrow {
    padding: 0 9rem;
  }

  .page-width-desktop {
    padding: 0;
  }

  .page-width-tablet {
    padding: 0 5rem;
  }
}

@media screen and (min-width: 990px) {
  .header:not(.drawer-menu).page-width {
    padding: 0;
    max-width: 1300px;
  }

  .page-width--narrow {
    max-width: 72.6rem;
    padding: 0;
  }

  .page-width-desktop {
    max-width: var(--page-width);
    padding: 0 5rem;
  }
}

.isolate {
  position: relative;
  z-index: 0;
}

.section+.section {
  margin-top: var(--spacing-sections-mobile);
}

@media screen and (min-width: 750px) {
  .section+.section {
    margin-top: var(--spacing-sections-desktop);
  }
}

.element-margin-top {
  margin-top: 5rem;
}

@media screen and (min-width: 750px) {
  .element-margin {
    margin-top: calc(5rem + var(--page-width-margin));
  }
}

.background-secondary {
  background-color: rgba(var(--color-foreground), 0.04);
}

.grid-auto-flow {
  display: grid;
  grid-auto-flow: column;
}

.page-margin,
.shopify-challenge__container {
  margin: 7rem auto;
}

.rte-width {
  max-width: 82rem;
  margin: 0 auto 2rem;
}

.list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.hidden {
  display: none !important;
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  word-wrap: normal !important;
}

.visually-hidden--inline {
  margin: 0;
  height: 1em;
}

.overflow-hidden {
  overflow: hidden;
}

.skip-to-content-link:focus {
  z-index: 9999;
  position: inherit;
  overflow: auto;
  width: auto;
  height: auto;
  clip: auto;
}

.full-width-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

::selection {
  background-color: rgba(var(--color-foreground), 0.2);
}

.text-body {
  font-size: 1.5rem;
  letter-spacing: 0.06rem;
  line-height: calc(1 + 0.8 / var(--font-body-scale));
  font-family: 'arimoregular';
  font-style: var(--font-body-style);
  font-weight: var(--font-body-weight);
}

h1,
h2,
h3,
h4,
h5,
.h0,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: 'arimoregular';
  font-style: var(--font-heading-style);
  font-weight: var(--font-heading-weight);
  letter-spacing: calc(var(--font-heading-scale) * 0.06rem);
  color: rgb(var(--color-foreground));
  line-height: calc(1 + 0.3 / max(1, var(--font-heading-scale)));
  word-break: break-word;
}

.hxl {
  font-size: calc(var(--font-heading-scale) * 5rem);
}

@media only screen and (min-width: 750px) {
  .hxl {
    font-size: calc(var(--font-heading-scale) * 6.2rem);
  }
}

.h0 {
  font-size: calc(var(--font-heading-scale) * 4rem);
}

@media only screen and (min-width: 750px) {
  .h0 {
    font-size: calc(var(--font-heading-scale) * 5.2rem);
  }
}

h1,
.h1 {
  font-size: calc(var(--font-heading-scale) * 3rem);
}

@media only screen and (min-width: 750px) {

  h1,
  .h1 {
    font-size: 36px;
  }
}

h2,
.h2 {
  font-size: calc(var(--font-heading-scale) * 2rem);
}

@media only screen and (min-width: 750px) {

  h2,
  .h2 {
    font-size: calc(var(--font-heading-scale) * 2.4rem);
  }
}

h3,
.h3 {
  font-size: calc(var(--font-heading-scale) * 1.7rem);
}

@media only screen and (min-width: 750px) {

  h3,
  .h3 {
    font-size: calc(var(--font-heading-scale) * 1.8rem);
  }
}

h4,
.h4 {
  font-family: 'arimoregular';
  font-style: var(--font-heading-style);
  font-size: calc(var(--font-heading-scale) * 1.5rem);
}

h5,
.h5 {
  font-size: calc(var(--font-heading-scale) * 1.2rem);
}

@media only screen and (min-width: 750px) {

  h5,
  .h5 {
    font-size: calc(var(--font-heading-scale) * 1.3rem);
  }
}

h6,
.h6 {
  color: rgba(var(--color-foreground), 0.75);
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
}

blockquote {
  font-style: italic;
  color: rgba(var(--color-foreground), 0.75);
  border-left: 0.2rem solid rgba(var(--color-foreground), 0.2);
  padding-left: 1rem;
}

@media screen and (min-width: 750px) {
  blockquote {
    padding-left: 1.5rem;
  }
}

.caption {
  font-size: 1rem;
  letter-spacing: 0.07rem;
  line-height: calc(1 + 0.7 / var(--font-body-scale));
}

@media screen and (min-width: 750px) {
  .caption {
    font-size: 1.2rem;
  }
}

.caption-with-letter-spacing {
  font-size: 1rem;
  letter-spacing: 0.13rem;
  line-height: calc(1 + 0.2 / var(--font-body-scale));
  text-transform: uppercase;
}

.caption-with-letter-spacing--medium {
  font-size: 1.2rem;
  letter-spacing: 0.16rem;
}

.caption-with-letter-spacing--large {
  font-size: 1.4rem;
  letter-spacing: 0.18rem;
}

.caption-large,
.customer .field input,
.customer select,
.field__input,
.form__label,
.select__select {
  font-size: 1.3rem;
  line-height: calc(1 + 0.5 / var(--font-body-scale));
  letter-spacing: 0.04rem;
}

.color-foreground {
  color: rgb(var(--color-foreground));
}

table:not([class]) {
  table-layout: fixed;
  border-collapse: collapse;
  font-size: 1.4rem;
  border-style: hidden;
  box-shadow: 0 0 0 0.1rem rgba(var(--color-foreground), 0.2);
  /* draws the table border  */
}

table:not([class]) td,
table:not([class]) th {
  padding: 1em;
  border: 0.1rem solid rgba(var(--color-foreground), 0.2);
}

@media screen and (max-width: 749px) {
  .small-hide {
    display: none !important;
  }
}

@media screen and (min-width: 750px) and (max-width: 989px) {
  .medium-hide {
    display: none !important;
  }
}

@media screen and (min-width: 990px) {
  .large-up-hide {
    display: none !important;
  }
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}

.light {
  opacity: 0.7;
}

a:empty,
ul:empty,
dl:empty,
section:empty,
article:empty,
p:empty,
h1:empty,
h2:empty,
h3:empty,
h4:empty,
h5:empty,
h6:empty {
  display: none;
}

.link,
.customer a {
  cursor: pointer;
  display: inline-block;
  border: none;
  box-shadow: none;
  text-underline-offset: 0.3rem;
  color: rgb(var(--color-link));
  background-color: transparent;
  font-size: 1.2rem;
}

.link--text {
  color: rgb(var(--color-foreground));
}

.link--text:hover {
  color: rgba(var(--color-foreground), 0.75);
}

.link-with-icon {
  display: inline-flex;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  text-decoration: none;
  margin-bottom: 4.5rem;
  white-space: nowrap;
}

.link-with-icon .icon {
  width: 1.5rem;
  margin-left: 1rem;
}

a:not([href]) {
  cursor: not-allowed;
}

.circle-divider::after {
  content: '\2022';
  margin: 0 1.3rem 0 1.5rem;
}

.circle-divider:last-of-type::after {
  display: none;
}

hr {
  border: 0.5px solid #000;
  height: 0.1rem;
  width: 80%;
  background-color: rgba(var(--color-foreground), 0.2);
  display: block;
  margin: 5rem 0;
}

@media screen and (min-width: 750px) {
  hr {
    margin: 0;
  }
}

.full-unstyled-link {
  text-decoration: none;
  color: currentColor;
  display: block;
}

.placeholder {
  background-color: rgba(var(--color-foreground), 0.04);
  color: rgba(var(--color-foreground), 0.55);
  fill: rgba(var(--color-foreground), 0.55);
}

details>* {
  box-sizing: border-box;
}

.break {
  word-break: break-word;
}

.visibility-hidden {
  visibility: hidden;
}

@media (prefers-reduced-motion) {
  .motion-reduce {
    transition: none !important;
    animation: none !important;
  }
}

:root {
  --duration-short: 100ms;
  --duration-default: 200ms;
  --duration-announcement-bar: 250ms;
  --duration-medium: 300ms;
  --duration-long: 500ms;
  --duration-extra-long: 600ms;
  --duration-extended: 3s;
  --ease-out-slow: cubic-bezier(0, 0, 0.3, 1);
  --animation-slide-in: slideIn var(--duration-extra-long) var(--ease-out-slow) forwards;
  --animation-fade-in: fadeIn var(--duration-extra-long) var(--ease-out-slow);
}

.underlined-link,
.customer a,
.inline-richtext a {
  text-underline-offset: 0.3rem;
  text-decoration-thickness: 0.1rem;
  transition: text-decoration-thickness ease 100ms;
}

.underlined-link,
.customer a {
  color: rgba(var(--color-link), var(--alpha-link));
}

.inline-richtext a,
.rte.inline-richtext a {
  color: currentColor;
}

.underlined-link:hover,
.customer a:hover,
.inline-richtext a:hover {
  color: rgb(var(--color-link));
  text-decoration-thickness: 0.2rem;
}

.icon-arrow {
  width: 1.5rem;
}

h3 .icon-arrow,
.h3 .icon-arrow {
  width: calc(var(--font-heading-scale) * 1.5rem);
}

/* arrow animation */
.animate-arrow .icon-arrow path {
  transform: translateX(-0.25rem);
  transition: transform var(--duration-short) ease;
}

.animate-arrow:hover .icon-arrow path {
  transform: translateX(-0.05rem);
}

/* base-details-summary */
summary {
  cursor: pointer;
  list-style: none;
  position: relative;
}

summary .icon-caret {
  position: absolute;
  height: 0.6rem;
  right: 1.5rem;
  top: calc(50% - 0.2rem);
}

summary::-webkit-details-marker {
  display: none;
}

.disclosure-has-popup {
  position: relative;
}

.disclosure-has-popup[open]>summary::before {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: block;
  cursor: default;
  content: ' ';
  background: transparent;
}

.disclosure-has-popup>summary::before {
  display: none;
}

.disclosure-has-popup[open]>summary+* {
  z-index: 100;
}

@media screen and (min-width: 750px) {
  .disclosure-has-popup[open]>summary+* {
    z-index: 4;
  }

  .facets .disclosure-has-popup[open]>summary+* {
    z-index: 2;
  }
}

/* base-focus */
/*
  Focus ring - default (with offset)
*/

*:focus {
  outline: 0;
  box-shadow: none;
}

*:focus-visible {
  border: 2px solid black;
}

/* Fallback - for browsers that don't support :focus-visible, a fallback is set for :focus */
.focused,
.no-js *:focus {
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: 0.3rem;
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)), 0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3);
}

/* Negate the fallback side-effect for browsers that support :focus-visible */
.no-js *:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

/*
  Focus ring - inset
*/

.focus-inset:focus-visible {
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: -0.2rem;
  box-shadow: 0 0 0.2rem 0 rgba(var(--color-foreground), 0.3);
}

.focused.focus-inset,
.no-js .focus-inset:focus {
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: -0.2rem;
  box-shadow: 0 0 0.2rem 0 rgba(var(--color-foreground), 0.3);
}

.no-js .focus-inset:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

/*
  Focus ring - none
*/

/* Dangerous for a11y - Use with care */
.focus-none {
  box-shadow: none !important;
  outline: 0 !important;
}

.focus-offset:focus-visible {
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: 1rem;
  box-shadow: 0 0 0 1rem rgb(var(--color-background)), 0 0 0.2rem 1.2rem rgba(var(--color-foreground), 0.3);
}

.focus-offset.focused,
.no-js .focus-offset:focus {
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: 1rem;
  box-shadow: 0 0 0 1rem rgb(var(--color-background)), 0 0 0.2rem 1.2rem rgba(var(--color-foreground), 0.3);
}

.no-js .focus-offset:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

/* component-title */
.title,
.title-wrapper-with-link {
  margin: 3rem 0 2rem;
}

.title-wrapper-with-link .title {
  margin: 0;
}

.title .link {
  font-size: inherit;
}

.title-wrapper {
  margin-bottom: 3rem;
}

.title-wrapper-with-link {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
}

.title--primary {
  margin: 4rem 0;
}

.title-wrapper--self-padded-tablet-down,
.title-wrapper--self-padded-mobile {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media screen and (min-width: 750px) {
  .title-wrapper--self-padded-mobile {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 990px) {

  .title,
  .title-wrapper-with-link {
    margin: 5rem 0 3rem;
  }

  .title--primary {
    margin: 2rem 0;
  }

  .title-wrapper-with-link {
    align-items: center;
  }

  .title-wrapper-with-link .title {
    margin-bottom: 0;
  }

  .title-wrapper--self-padded-tablet-down {
    padding-left: 0;
    padding-right: 0;
  }
}

.title-wrapper-with-link .link-with-icon {
  margin: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.title-wrapper-with-link .link-with-icon svg {
  width: 1.5rem;
}

.title-wrapper-with-link a {
  margin-top: 0;
  flex-shrink: 0;
}

.title-wrapper--no-top-margin {
  margin-top: 0;
}

.title-wrapper--no-top-margin>.title {
  margin-top: 0;
}

.subtitle {
  font-size: 1.8rem;
  line-height: calc(1 + 0.8 / var(--font-body-scale));
  letter-spacing: 0.06rem;
  color: rgba(var(--color-foreground), 0.7);
}

.subtitle--small {
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
}

.subtitle--medium {
  font-size: 1.6rem;
  letter-spacing: 0.08rem;
}

/* component-grid */
.grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  padding: 0;
  list-style: none;
  column-gap: var(--grid-mobile-horizontal-spacing);
  row-gap: var(--grid-mobile-vertical-spacing);
}

@media screen and (min-width: 750px) {
  .grid {
    column-gap: var(--grid-desktop-horizontal-spacing);
    row-gap: var(--grid-desktop-vertical-spacing);
  }
}

.grid:last-child {
  margin-bottom: 0;
}

.grid__item {
  width: calc(25% - var(--grid-mobile-horizontal-spacing) * 3 / 4);
  max-width: calc(50% - var(--grid-mobile-horizontal-spacing) / 2);
  flex-grow: 1;
  flex-shrink: 0;
}

@media screen and (min-width: 750px) {
  .grid__item {
    width: calc(25% - var(--grid-desktop-horizontal-spacing) * 3 / 4);
    max-width: calc(50% - var(--grid-desktop-horizontal-spacing) / 2);
  }
}

.grid--gapless.grid {
  column-gap: 0;
  row-gap: 0;
}

@media screen and (max-width: 749px) {
  .grid__item.slider__slide--full-width {
    width: 100%;
    max-width: none;
  }
}

.grid--1-col .grid__item {
  max-width: 100%;
  width: 100%;
}

.grid--3-col .grid__item {
  width: calc(33.33% - var(--grid-mobile-horizontal-spacing) * 2 / 3);
}

@media screen and (min-width: 750px) {
  .grid--3-col .grid__item {
    width: calc(33.33% - var(--grid-desktop-horizontal-spacing) * 2 / 3);
  }
}

.grid--2-col .grid__item {
  width: calc(50% - var(--grid-mobile-horizontal-spacing) / 2);
}

@media screen and (min-width: 750px) {
  .grid--2-col .grid__item {
    width: calc(50% - var(--grid-desktop-horizontal-spacing) / 2);
  }

  .grid--4-col-tablet .grid__item {
    width: calc(25% - var(--grid-desktop-horizontal-spacing) * 3 / 4);
  }

  .grid--3-col-tablet .grid__item {
    width: calc(33.33% - var(--grid-desktop-horizontal-spacing) * 2 / 3);
  }

  .grid--2-col-tablet .grid__item {
    width: calc(50% - var(--grid-desktop-horizontal-spacing) / 2);
  }
}

@media screen and (max-width: 989px) {
  .grid--1-col-tablet-down .grid__item {
    width: 100%;
    max-width: 100%;
  }

  .slider--tablet.grid--peek {
    margin: 0;
    width: 100%;
  }

  .slider--tablet.grid--peek .grid__item {
    box-sizing: content-box;
    margin: 0;
  }

  .slider.slider--tablet .scroll-trigger.animate--slide-in,
  .slider.slider--mobile .scroll-trigger.animate--slide-in {
    animation: none;
    opacity: 1;
    transform: inherit;
  }

  .scroll-trigger:not(.scroll-trigger--offscreen) .slider--tablet {
    animation: var(--animation-slide-in);
  }
}

@media screen and (min-width: 990px) {
  .grid--6-col-desktop .grid__item {
    width: calc(16.66% - var(--grid-desktop-horizontal-spacing) * 5 / 6);
    max-width: calc(16.66% - var(--grid-desktop-horizontal-spacing) * 5 / 6);
  }

  .grid--5-col-desktop .grid__item {
    width: calc(20% - var(--grid-desktop-horizontal-spacing) * 4 / 5);
    max-width: calc(20% - var(--grid-desktop-horizontal-spacing) * 4 / 5);
  }

  .grid--4-col-desktop .grid__item {
    width: calc(25% - var(--grid-desktop-horizontal-spacing) * 3 / 4);
    max-width: calc(25% - var(--grid-desktop-horizontal-spacing) * 3 / 4);
  }

  .grid--3-col-desktop .grid__item {
    width: calc(33.33% - var(--grid-desktop-horizontal-spacing) * 2 / 3);
    max-width: calc(33.33% - var(--grid-desktop-horizontal-spacing) * 2 / 3);
  }

  .grid--2-col-desktop .grid__item {
    width: calc(50% - var(--grid-desktop-horizontal-spacing) / 2);
    max-width: calc(50% - var(--grid-desktop-horizontal-spacing) / 2);
  }

  .slider.slider--desktop .scroll-trigger.animate--slide-in {
    animation: none;
    opacity: 1;
    transform: inherit;
  }

  .scroll-trigger:not(.scroll-trigger--offscreen) .slider--desktop {
    animation: var(--animation-slide-in);
  }
}

@media screen and (min-width: 990px) {
  .grid--1-col-desktop {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .grid--1-col-desktop .grid__item {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 749px) {
  .grid--peek.slider--mobile {
    margin: 0;
    width: 100%;
  }

  .grid--peek.slider--mobile .grid__item {
    box-sizing: content-box;
    margin: 0;
  }

  .grid--peek .grid__item {
    min-width: 35%;
  }

  /* Fix to show some space at the end of our sliders in all browsers */
  .grid--peek.slider:after {
    margin-left: calc(-1 * var(--grid-mobile-horizontal-spacing));
  }

  .grid--2-col-tablet-down .grid__item {
    width: calc(50% - var(--grid-mobile-horizontal-spacing) / 2);
  }

  .slider--tablet.grid--peek.grid--2-col-tablet-down .grid__item,
  .grid--peek .grid__item {
    width: calc(50% - var(--grid-mobile-horizontal-spacing));
  }

  .slider--tablet.grid--peek.grid--1-col-tablet-down .grid__item,
  .slider--mobile.grid--peek.grid--1-col-tablet-down .grid__item {
    width: calc(94.5% - var(--grid-mobile-horizontal-spacing) - 3rem);
  }
}

@media screen and (min-width: 750px) and (max-width: 989px) {
  .slider--tablet.grid--peek .grid__item {
    width: calc(25% - var(--grid-desktop-horizontal-spacing) - 3rem);
  }

  .slider--tablet.grid--peek.grid--3-col-tablet .grid__item {
    width: calc(33.33% - var(--grid-desktop-horizontal-spacing) - 3rem);
  }

  .slider--tablet.grid--peek.grid--2-col-tablet .grid__item,
  .slider--tablet.grid--peek.grid--2-col-tablet-down .grid__item {
    width: calc(50% - var(--grid-desktop-horizontal-spacing) - 3rem);
  }

  .slider--tablet.grid--peek .grid__item:first-of-type {
    margin-left: 1.5rem;
  }

  .grid--2-col-tablet-down .grid__item {
    width: calc(50% - var(--grid-desktop-horizontal-spacing) / 2);
  }

  .grid--1-col-tablet-down.grid--peek .grid__item {
    width: calc(100% - var(--grid-desktop-horizontal-spacing) - 3rem);
  }
}

/* component-media */
.media {
  display: block;
  background-color: rgba(var(--color-foreground), 0.1);
  position: relative;
  overflow: hidden;
}

.media--transparent {
  background-color: transparent;
}

.media>*:not(.zoom):not(.deferred-media__poster-button),
.media model-viewer {
  display: block;
  max-width: 100%;
  height: 100%;
  width: 100%;
}

.media>img {
  object-fit: cover;
  object-position: center center;
  transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.media--square {
  padding-bottom: 100%;
}

.media--portrait {
  padding-bottom: 125%;
}

.media--landscape {
  padding-bottom: 66.6%;
}

.media--cropped {
  padding-bottom: 56%;
}

.media--16-9 {
  padding-bottom: 56.25%;
}

.media--circle {
  padding-bottom: 100%;
  border-radius: 50%;
}

.media.media--hover-effect>img+img {
  opacity: 0;
}

@media screen and (min-width: 990px) {
  .media--cropped {
    padding-bottom: 63%;
  }
}

deferred-media {
  display: block;
}

/* component-button */
/* Button - default */

.cart__ctas .cart__checkout-button.button {
  background-color: black;
  color: white;
}

.button--secondary,
.button--tertiary {
  --color-button: var(--color-secondary-button);
  --color-button-text: var(--color-secondary-button-text);
}

.button--tertiary {
  --alpha-button-background: 0;
  --alpha-button-border: 0.2;
}

.button,
.shopify-challenge__button,
.customer button,
button.shopify-payment-button__button--unbranded,
.shopify-payment-button [role='button'],
.cart__dynamic-checkout-buttons [role='button'],
.cart__dynamic-checkout-buttons iframe {
  --shadow-horizontal-offset: var(--buttons-shadow-horizontal-offset);
  --shadow-vertical-offset: var(--buttons-shadow-vertical-offset);
  --shadow-blur-radius: var(--buttons-shadow-blur-radius);
  --shadow-opacity: var(--buttons-shadow-opacity);
  --shadow-visible: var(--buttons-shadow-visible);
  --border-opacity: calc(1 - var(--buttons-border-opacity));
  border-radius: var(--buttons-radius-outset);
  position: relative;
}

.button,
.shopify-challenge__button,
.customer button,
button.shopify-payment-button__button--unbranded {
  min-width: calc(12rem + var(--buttons-border-width) * 2);
  min-height: calc(4.5rem + var(--buttons-border-width) * 2);
  text-transform: uppercase;
}

.shopify-payment-button__button--branded {
  z-index: auto;
}

.cart__dynamic-checkout-buttons iframe {
  box-shadow: var(--shadow-horizontal-offset) var(--shadow-vertical-offset) var(--shadow-blur-radius) rgba(var(--color-shadow), var(--shadow-opacity));
}

.button,
.shopify-challenge__button,
.customer button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
  cursor: pointer;
  font: inherit;
  font-size: 1.5rem;
  text-decoration: none;
  width: 260px;
  max-width: 260px;
  height: 60px;
  color: #000;
  font-family: 'arimoregular';
  appearance: none;
  background-color: #ffff;
}

.button:before,
.shopify-challenge__button:before,
.customer button:before,
.shopify-payment-button__button--unbranded:before,
.shopify-payment-button [role='button']:before,
.cart__dynamic-checkout-buttons [role='button']:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: var(--buttons-radius-outset);
  box-shadow: var(--shadow-horizontal-offset) var(--shadow-vertical-offset) var(--shadow-blur-radius) rgba(var(--color-shadow), var(--shadow-opacity));
}

.button:after,
.shopify-challenge__button:after,
.customer button:after,
.shopify-payment-button__button--unbranded:after {
  content: '';
  position: absolute;
  top: var(--buttons-border-width);
  right: var(--buttons-border-width);
  bottom: var(--buttons-border-width);
  left: var(--buttons-border-width);
  z-index: 1;
  border-radius: var(--buttons-radius);
  box-shadow: 0 0 0 calc(var(--buttons-border-width) + var(--border-offset)) rgba(var(--color-button-text), var(--border-opacity)),
    0 0 0 var(--buttons-border-width) rgba(var(--color-button), var(--alpha-button-background));
  transition: box-shadow var(--duration-short) ease;
}

/* .button:not([disabled]):hover::after,
.shopify-challenge__button:hover::after,
.customer button:hover::after,
.shopify-payment-button__button--unbranded:hover::after {

  border: 2px solid #553D0C !important;

} */

.button--secondary:after {
  --border-opacity: var(--buttons-border-opacity);
}

.button:focus-visible,
.button:focus,
.button.focused,
.shopify-payment-button__button--unbranded:focus-visible,
.shopify-payment-button [role='button']:focus-visible,
.shopify-payment-button__button--unbranded:focus,
.shopify-payment-button [role='button']:focus {
  outline: 0;
}

.button:focus:not(:focus-visible):not(.focused),
.shopify-payment-button__button--unbranded:focus:not(:focus-visible):not(.focused),
.shopify-payment-button [role='button']:focus:not(:focus-visible):not(.focused) {
  box-shadow: inherit;
}

.button::selection,
.shopify-challenge__button::selection,
.customer button::selection {
  background-color: rgba(var(--color-button-text), 0.3);
}

.button,
.button-label,
.shopify-challenge__button,
.customer button {
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  line-height: calc(1 + 0.2 / var(--font-body-scale));
}

.button--tertiary {
  font-size: 1.2rem;
  padding: 1rem 1.5rem;
  cursor: pointer;
}

.button--small {
  padding: 1.2rem 2.6rem;
}

/* Button - other */

.button:disabled,
.button[aria-disabled='true'],
.button.disabled,
.customer button:disabled,
.customer button[aria-disabled='true'],
.customer button.disabled,
.quantity__button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

/* #sticky-add-to-cart-btn:disabled {
  opacity: 1;
} */

.button--full-width {
  display: flex;
  width: 100%;
}

.button.loading {
  color: transparent;
  position: relative;
}

@media screen and (forced-colors: active) {
  .button.loading {
    color: rgb(var(--color-foreground));
  }
}

.button.loading>.loading__spinner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
}

.button.loading>.loading__spinner .spinner {
  width: fit-content;
}

.button.loading>.loading__spinner .path {
  stroke: rgb(var(--color-button-text));
}

/* Button - social share */

.share-button {
  display: block;
  position: relative;
}

.share-button details {
  width: fit-content;
}

.share-button__button {
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: rgb(var(--color-link));
  margin-left: 0;
  padding-left: 0;
  min-height: 4.4rem;
}

details[open]>.share-button__fallback {
  animation: animateMenuOpen var(--duration-default) ease;
}

.share-button__button:hover {
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}

.share-button__button,
.share-button__fallback button {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.share-button__button .icon-share {
  height: 1.2rem;
  margin-right: 1rem;
  min-width: 1.3rem;
}

.share-button__fallback {
  display: flex;
  align-items: center;
  position: absolute;
  top: 3rem;
  left: 0.1rem;
  z-index: 3;
  width: 100%;
  min-width: max-content;
  border-radius: var(--inputs-radius);
  border: 0;
}

.share-button__fallback:after {
  pointer-events: none;
  content: '';
  position: absolute;
  top: var(--inputs-border-width);
  right: var(--inputs-border-width);
  bottom: var(--inputs-border-width);
  left: var(--inputs-border-width);
  border: 0.1rem solid transparent;
  border-radius: var(--inputs-radius);
  box-shadow: 0 0 0 var(--inputs-border-width) rgba(var(--color-foreground), var(--inputs-border-opacity));
  transition: box-shadow var(--duration-short) ease;
  z-index: 1;
}

.share-button__fallback:before {
  background: rgb(var(--color-background));
  pointer-events: none;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--inputs-radius-outset);
  box-shadow: var(--inputs-shadow-horizontal-offset) var(--inputs-shadow-vertical-offset) var(--inputs-shadow-blur-radius) rgba(var(--color-shadow), var(--inputs-shadow-opacity));
  z-index: -1;
}

.share-button__fallback button {
  width: 4.4rem;
  height: 4.4rem;
  padding: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: var(--inputs-border-width);
}

.share-button__fallback button:hover {
  color: rgba(var(--color-foreground), 0.75);
}

.share-button__fallback button:hover svg {
  transform: scale(1.07);
}

.share-button__close:not(.hidden)+.share-button__copy {
  display: none;
}

.share-button__close,
.share-button__copy {
  background-color: transparent;
  color: rgb(var(--color-foreground));
}

.share-button__copy:focus-visible,
.share-button__close:focus-visible {
  background-color: rgb(var(--color-background));
  z-index: 2;
}

.share-button__copy:focus,
.share-button__close:focus {
  background-color: rgb(var(--color-background));
  z-index: 2;
}

.field:not(:focus-visible):not(.focused)+.share-button__copy:not(:focus-visible):not(.focused),
.field:not(:focus-visible):not(.focused)+.share-button__close:not(:focus-visible):not(.focused) {
  background-color: inherit;
}

.share-button__fallback .field:after,
.share-button__fallback .field:before {
  content: none;
}

.share-button__fallback .field {
  border-radius: 0;
  min-width: auto;
  min-height: auto;
  transition: none;
}

.share-button__fallback .field__input:focus,
.share-button__fallback .field__input:-webkit-autofill {
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: 0.1rem;
  box-shadow: 0 0 0 0.1rem rgb(var(--color-background)), 0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3);
}

.share-button__fallback .field__input {
  box-shadow: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  filter: none;
  min-width: auto;
  min-height: auto;
}

.share-button__fallback .field__input:hover {
  box-shadow: none;
}

.share-button__fallback .icon {
  width: 1.5rem;
  height: 1.5rem;
}

.share-button__message:not(:empty) {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 0;
  padding: 0.8rem 0 0.8rem 1.5rem;
  margin: var(--inputs-border-width);
}

.share-button__message:not(:empty):not(.hidden)~* {
  display: none;
}

/* component-form */
.field__input,
.select__select,
.customer .field input,
.customer select {
  -webkit-appearance: none;
  appearance: none;
  background-color: rgb(var(--color-background));
  color: rgb(var(--color-foreground));
  font-family: 'arimoregular';
  font-style: var(--font-body-style);
  font-weight: var(--font-body-weight);
  font-size: 1.6rem;
  width: 22rem;
  box-sizing: border-box;
  transition: box-shadow var(--duration-short) ease;
  border-radius: var(--inputs-radius);
  height: 4.5rem;
  min-height: calc(var(--inputs-border-width) * 2);
  min-width: calc(7rem + (var(--inputs-border-width) * 2));
  position: relative;
  border: 0;
}

.field:before,
.select:before,
.localization-form__select:before {
  pointer-events: none;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--inputs-radius-outset);
  box-shadow: var(--inputs-shadow-horizontal-offset) var(--inputs-shadow-vertical-offset) var(--inputs-shadow-blur-radius) rgba(var(--color-shadow), var(--inputs-shadow-opacity));
  z-index: -1;
}

.customer .field:before,
.customer select:before {
  box-shadow: none;
}

.customer .field:after,
.customer select:after {
  box-shadow: none;
}

.field:after,
.select:after,
.localization-form__select:after {
  pointer-events: none;
  content: '';
  position: absolute;
  top: var(--inputs-border-width);
  right: var(--inputs-border-width);
  bottom: var(--inputs-border-width);
  left: var(--inputs-border-width);
  border: 0.1rem solid transparent;
  border-radius: var(--inputs-radius);
  box-shadow: 0 0 0 var(--inputs-border-width) rgba(var(--color-foreground), var(--inputs-border-opacity));
  transition: box-shadow var(--duration-short) ease;
  z-index: 1;
}

.select__select {
  font-family: 'arimoregular';
  font-style: var(--font-body-style);
  font-weight: var(--font-body-weight);
  font-size: 1.2rem;
  color: rgba(var(--color-foreground), 0.75);
}

.field:hover.field:after,
.select:hover.select:after,
.select__select:hover.select__select:after,
.customer .field:hover.field:after,
.customer select:hover.select:after,
.localization-form__select:hover.localization-form__select:after {
  box-shadow: 0 0 0 calc(0.1rem + var(--inputs-border-width)) rgba(var(--color-foreground), var(--inputs-border-opacity));
  outline: 0;
  border-radius: var(--inputs-radius);
}

.field__input:focus-visible,
.select__select:focus-visible,
.customer .field input:focus-visible,
.customer select:focus-visible,
.localization-form__select:focus-visible.localization-form__select:after {
  outline: 0;
  border-radius: 2px;
}

.field__input:focus,
.select__select:focus,
.customer .field input:focus,
.customer select:focus,
.localization-form__select:focus.localization-form__select:after {
  box-shadow: 0 0 0 calc(0.1rem + var(--inputs-border-width)) rgba(var(--color-foreground));
  outline: 0;
  border-radius: 2px;
}

.localization-form__select:focus {
  outline: 0;
  box-shadow: none;
}

.text-area,
.select {
  display: flex;
  position: relative;
  width: 100%;
}

/* Select */

.select .icon-caret,
.customer select+svg {
  height: 0.6rem;
  pointer-events: none;
  position: absolute;
  top: calc(50% - 0.2rem);
  right: calc(var(--inputs-border-width) + 1.5rem);
}

.select__select,
.customer select {
  cursor: pointer;
  line-height: calc(1 + 0.6 / var(--font-body-scale));
  padding: 0 calc(var(--inputs-border-width) + 3rem) 0 2rem;
  margin: var(--inputs-border-width);
  min-height: calc(var(--inputs-border-width) * 2);
}

/* Field */

.field {
  position: relative;
  width: 19rem;
  display: flex;
  transition: box-shadow var(--duration-short) ease;
}

.customer .field {
  display: flex;
}

.field--with-error {
  flex-wrap: wrap;
}

.field__input,
.customer .field input {
  flex-grow: 1;
  text-align: left;
  padding: 1.5rem 0;
  margin: var(--inputs-border-width);
  transition: box-shadow var(--duration-short) ease;
}

.field__label,
.customer .field label {
  font-size: 1.2rem;
  left: calc(var(--inputs-border-width) + 1.5rem);
  top: calc(1rem + var(--inputs-border-width));
  margin-bottom: 0;
  pointer-events: none;
  position: absolute;
  transition: top var(--duration-short) ease, font-size var(--duration-short) ease;
  color: rgba(var(--color-foreground), 0.75);
}

.field__input:focus~.field__label,
.field__input:not(:placeholder-shown)~.field__label,
.field__input:-webkit-autofill~.field__label,
.customer .field input:focus~label,
.customer .field input:not(:placeholder-shown)~label,
.customer .field input:-webkit-autofill~label {
  font-size: 1rem;
  top: calc(var(--inputs-border-width) + 1.5rem);
  left: calc(var(--inputs-border-width) + 2rem);
  letter-spacing: 0.04rem;
}

.field input:focus~label {
  top: calc(var(--inputs-border-width) + -1.2rem);
}

.field__input:focus,
.field__input:not(:placeholder-shown),
.field__input:-webkit-autofill,
.field input:focus,
.customer .field input:not(:placeholder-shown),
.customer .field input:-webkit-autofill {
  /* padding: 2.2rem 1.5rem 0.8rem 2rem; */
  margin: var(--inputs-border-width);
}

.field__input::-webkit-search-cancel-button,
.customer .field input::-webkit-search-cancel-button {
  display: none;
}

.field__input::placeholder,
.customer .field input::placeholder {
  opacity: 1;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-style: italic;
  letter-spacing: 2px;
  font-family: 'arimoregular';
}

.field__button {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: currentColor;
  cursor: pointer;
  display: flex;
  height: 2.4rem;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 4.4rem;
}

.field__button>svg {
  height: 2.5rem;
  width: 2.5rem;
}

.field__input:-webkit-autofill~.field__button,
.field__input:-webkit-autofill~.field__label,
.customer .field input:-webkit-autofill~label {
  color: rgb(0, 0, 0);
}

/* Text area */

.text-area {
  font-family: 'arimoregular';
  font-style: var(--font-body-style);
  font-weight: var(--font-body-weight);
  min-height: 10rem;
  resize: none;
}

input[type='checkbox'] {
  display: inline-block;
  width: auto;
  margin-right: 0.5rem;
}

/* Form global */

.form__label {
  display: block;
  margin-bottom: 0.6rem;
}

.form__message {
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  line-height: 1;
  margin-top: 1rem;
}

.form__message--large {
  font-size: 1.6rem;
}

.customer .field .form__message {
  font-size: 1.4rem;
  text-align: left;
}

.form__message .icon,
.customer .form__message svg {
  flex-shrink: 0;
  height: 1.3rem;
  margin-right: 0.5rem;
  width: 1.3rem;
}

.form__message--large .icon,
.customer .form__message svg {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
}

.customer .field .form__message svg {
  align-self: start;
}

.form-status {
  margin: 0;
  font-size: 1.6rem;
}

.form-status-list {
  padding: 0;
  margin: 2rem 0 4rem;
}

.form-status-list li {
  list-style-position: inside;
}

.form-status-list .link::first-letter {
  text-transform: capitalize;
}

/* component-quantity */
.quantity {
  color: rgba(var(--color-foreground));
  position: relative;
  width: calc(14rem / var(--font-body-scale) + var(--inputs-border-width) * 2);
  display: flex;
  border-radius: var(--inputs-radius);
  min-height: calc((var(--inputs-border-width) * 2) + 4.5rem);
}

.quantity:after {
  pointer-events: none;
  content: '';
  position: absolute;
  top: var(--inputs-border-width);
  right: var(--inputs-border-width);
  bottom: var(--inputs-border-width);
  left: var(--inputs-border-width);
  border: 0.1rem solid transparent;
  border-radius: var(--inputs-radius);
  box-shadow: 0 0 0 var(--inputs-border-width) rgba(var(--color-foreground), var(--inputs-border-opacity));
  transition: box-shadow var(--duration-short) ease;
  z-index: 1;
}

.quantity:before {
  background: rgb(var(--color-background));
  pointer-events: none;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--inputs-radius-outset);
  box-shadow: var(--inputs-shadow-horizontal-offset) var(--inputs-shadow-vertical-offset) var(--inputs-shadow-blur-radius) rgba(var(--color-shadow), var(--inputs-shadow-opacity));
  z-index: -1;
}

.quantity__input {
  color: currentColor;
  font-family: 'arimoregular';
  font-style: var(--font-body-style);
  font-weight: var(--font-body-weight);
  font-size: 1.6rem;
  font-weight: 500;
  opacity: 0.85;
  text-align: center;
  background-color: transparent;
  border: 0;
  padding: 0 0.5rem;
  width: 100%;
  flex-grow: 1;
  -webkit-appearance: none;
  appearance: none;
}

.quantity__button {
  width: calc(4.5rem / var(--font-body-scale));
  flex-shrink: 0;
  font-size: 1.8rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(var(--color-foreground));
  padding: 0;
}

.quantity__button:first-child {
  margin-left: calc(var(--inputs-border-width));
}

.quantity__button:last-child {
  margin-right: calc(var(--inputs-border-width));
}

.quantity__button svg {
  width: 1rem;
  pointer-events: none;
}

.quantity__button:focus-visible,
.quantity__input:focus-visible {
  background-color: rgb(var(--color-background));
  z-index: 2;
}

.quantity__button:focus,
.quantity__input:focus {
  background-color: rgb(var(--color-background));
  z-index: 2;
}

.quantity__button:not(:focus-visible):not(.focused),
.quantity__input:not(:focus-visible):not(.focused) {
  box-shadow: inherit;
  background-color: inherit;
}

.quantity__input:-webkit-autofill,
.quantity__input:-webkit-autofill:hover,
.quantity__input:-webkit-autofill:active {
  box-shadow: 0 0 0 10rem rgb(var(--color-background)) inset !important;
  -webkit-box-shadow: 0 0 0 10rem rgb(var(--color-background)) inset !important;
}

.quantity__input::-webkit-outer-spin-button,
.quantity__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantity__input[type='number'] {
  -moz-appearance: textfield;
}

.quantity__rules {
  margin-top: 0.5rem;
  position: relative;
  font-size: 1.2rem;
}

.quantity__rules .caption {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
}

.quantity__rules .divider+.divider::before {
  content: '\2022';
  margin: 0 0.5rem;
}

.quantity__rules-cart {
  position: relative;
}

product-info .loading__spinner:not(.hidden)~*,
.quantity__rules-cart .loading__spinner:not(.hidden)~* {
  visibility: hidden;
}

/* component-modal */
.modal__toggle {
  list-style-type: none;
}

.no-js details[open] .modal__toggle {
  position: absolute;
  z-index: 5;
}

.modal__toggle-close {
  display: none;
}

.no-js details[open] svg.modal__toggle-close {
  display: flex;
  z-index: 1;
  height: 1.7rem;
  width: 1.7rem;
}

.modal__toggle-open {
  display: flex;
}

.no-js details[open] .modal__toggle-open {
  display: none;
}

.no-js .modal__close-button.link {
  display: none;
}

.modal__close-button.link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem;
  height: 4.4rem;
  width: 4.4rem;
  background-color: transparent;
}

.modal__close-button .icon {
  width: 1.7rem;
  height: 1.7rem;
}

.modal__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(var(--color-background));
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media-modal {
  cursor: zoom-out;
}

.media-modal .deferred-media {
  cursor: initial;
}

/* component-cart-count-bubble */
.cart-count-bubble:empty {
  display: none;
}

.cart-count-bubble {
  position: absolute;
  background-color: rgb(var(--color-button));
  color: rgb(var(--color-button-text));
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  bottom: 1.3rem;
  line-height: calc(1 + 0.1 / var(--font-body-scale));
  color: #fff;
}

/* utility-bar */
.utility-bar {
  height: 100%;
}

.utility-bar--bottom-border {
  border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.08);
}

@media screen and (max-width: 480px) {
  .utility-bar {
    max-height: 60px;
  }

  .announcement-bar__announcement p {
    margin: 0;
  }
}

@media screen and (min-width: 990px) {
  .utility-bar--bottom-border-social-only {
    border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.08);
  }
}

.utility-bar__grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'announcements';
}

.utility-bar__grid .list-social {
  justify-content: flex-start;
  align-content: center;
  margin-left: -1.2rem;
  grid-area: social-icons;
}

@media screen and (max-width: 989px) {
  .utility-bar .utility-bar__grid .list-social {
    display: none;
  }
}

.utility-bar .list-social__item .icon {
  scale: 0.9;
}

@media screen and (min-width: 990px) {
  .utility-bar__grid--3-col {
    grid-template-columns: 3fr 4fr 3fr;
    grid-template-areas: 'social-icons announcements language-currency';
  }

  .utility-bar__grid--2-col {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'social-icons language-currency';
  }

  .announcement-bar.announcement-bar--one-announcement,
  .announcement-bar--one-announcement .announcement-bar__link {
    width: fit-content;
    margin: auto;
  }
}

.announcement-bar,
.announcement-bar__announcement {
  color: rgb(var(--color-foreground));
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  grid-area: announcements;
  text-align: center;
}

.announcement-bar .slider--everywhere {
  margin-bottom: 0;
  scroll-behavior: auto;
}

.utility-bar__grid .announcement-bar-slider {
  width: 100%;
}

.utility-bar__grid .announcement-bar-slider {
  width: 100%;
}

.announcement-bar-slider,
.announcement-bar-slider .slider {
  width: 100%;
}

.announcement-bar .slider-button--next {
  /* margin-right: -4.5rem; */
  min-width: 44px;
}

.announcement-bar .slider-button--prev {
  /* margin-left: -4.5rem; */
  min-width: 44px;
}

.announcement-bar .slider-button--next:focus-visible,
.announcement-bar .slider-button--prev:focus-visible,
.utility-bar .list-social__link:focus-visible {
  outline-offset: -0.3rem;
  box-shadow: 0 0 0 -0.2rem rgb(var(--color-foreground));
}

.localization-wrapper {
  grid-area: language-currency;
  align-self: center;
  display: flex;
  justify-content: flex-end;
}

.localization-wrapper .localization-selector+.disclosure__list-wrapper {
  animation: animateMenuOpen var(--duration-default) ease;
}

.utility-bar .localization-wrapper .disclosure .localization-form__select,
.utility-bar .localization-wrapper .disclosure__link {
  font-size: calc(var(--font-heading-scale) * 1.3rem);
}

@media screen and (min-width: 990px) {
  body:has(.section-header .header:not(.drawer-menu)) .utility-bar .page-width {
    padding: 0;
  }

  .announcement-bar-slider {
    width: 60%;
  }

  .announcement-bar .slider-button {
    height: 3.8rem;
  }
}

.announcement-bar__link {
  display: flex;
  width: 100%;
  text-decoration: none;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.announcement-bar__link:hover {
  text-decoration: underline;
}

.announcement-bar__link .icon-arrow {
  display: inline-block;
  pointer-events: none;
  margin-left: 0.8rem;
  vertical-align: middle;
  margin-bottom: 0.2rem;
}

.announcement-bar__message {
  text-align: center;
  padding: 1em 1.2em;
  margin: 0;
  letter-spacing: 0;
  min-height: unset;
  line-height: 1;
  font-size: 11.9px;
}

.announcement-bar-slider--fade-in-next .announcement-bar__message,
.announcement-bar-slider--fade-in-previous .announcement-bar__message,
.announcement-bar-slider--fade-out-next .announcement-bar__message,
.announcement-bar-slider--fade-out-previous .announcement-bar__message {
  animation-duration: var(--duration-announcement-bar);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.announcement-bar-slider--fade-in-next .announcement-bar__message {
  --announcement-translate-from: -1.5rem;
  /* Prevent flicker */
  opacity: 0;
  animation-name: translateAnnouncementSlideIn;
  animation-delay: var(--duration-announcement-bar);
}

.announcement-bar-slider--fade-in-previous .announcement-bar__message {
  --announcement-translate-from: 1.5rem;
  /* Prevent flicker */
  opacity: 0;
  animation-name: translateAnnouncementSlideIn;
  animation-delay: var(--duration-announcement-bar);
}

.announcement-bar-slider--fade-out-next .announcement-bar__message {
  --announcement-translate-to: 1.5rem;
  animation-name: translateAnnouncementSlideOut;
}

.announcement-bar-slider--fade-out-previous .announcement-bar__message {
  --announcement-translate-to: -1.5rem;
  animation-name: translateAnnouncementSlideOut;
}

@keyframes translateAnnouncementSlideIn {
  0% {
    opacity: 0;
    transform: translateX(var(--announcement-translate-from));
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes translateAnnouncementSlideOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(var(--announcement-translate-to));
  }
}

/* section-header */
.section-header.shopify-section-group-header-group {
  z-index: 3;
}

/* Love Lemons */
.fallback-lp-featured-collection .fallback-preview-lp-grid-wrapper {
  margin-left: 0 !important;
  margin-right: 0 !important;
}


@media (max-width: 1440px) {

  #shopify-section-template--15637176778818__dv-love-for-lemons-featured .fallback-preview-lp-featured-grid-wrapper,
  #shopify-section-template--15637176778818__dv-love-for-lemons-featured .fallback-preview-lp-featured-grid-wrapper.section-spring_campaign_24 {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.dv-love-for-lemons-featured-wrapper .fallback-preview-lp-grid-wrapper {
  padding: 0 0 50px;
  max-width: unset !important;
  width: unset !important;
  margin-left: unset !important;
  margin-right: unset !important;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .quick_add_btn a:focus,
.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .quick_add_btn a:hover {
  text-decoration: underline !important;
}

.dv-love-for-lemons-featured-wrapper .fallback-text-btn-wrapper {
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px 10px;
}

.dv-love-for-lemons-featured-wrapper .dv-love-for-lemons-wrapper-img-wrp {
  max-width: 140px;
  width: 100%;
  margin: 40px auto;
}

.dv-love-for-lemons-featured-wrapper .dv-love-for-lemons-wrapper-img-wrp img {
  width: 100%;
  height: 100%;
  display: block;
}

.fallback-text-btn-wrapper .dv-love-for-lemons-wrapper-title-wrp p {
  line-height: 1.5;
  font-size: 19px;
  color: #000;
  text-align: center;
  letter-spacing: 1px;
}

.dv-love-for-lemons-featured-wrapper .fallback-preview-lp-grid-products {
  grid-gap: 10px;
  grid-column: 1fr 1fr;
  display: grid !important;
  grid-template-columns: repeat(4, 25%);
  gap: 10px;
  padding: 0;
  justify-content: center;
}

.fallback-preview-lp-featured-grid-wrapper.dv-love-for-lemons-featured-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper {
  margin: 0;
}

.love-for-lemon-grid .product_cart_image_container {
  position: relative;
}

.dv-love-for-lemons-featured-btn {
  position: absolute;
  bottom: 0;
  color: #fff;
  right: 0;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .quick_add_btn {
  text-transform: uppercase;
  font-size: 16px;
  color: #fff !important;
  padding: 10px !important;
  margin-bottom: 10px;
}

.dv-love-for-lemons-featured-title-wrp h2 {
  padding: 10px 40px;
  text-align: center;
  color: #000;
  font-size: 32px;
  letter-spacing: 1.5px;
  font-family: Baskerville MT Std !important;
  text-transform: lowercase;
}

.section-dv-love-for-lemons .featured-grid-wrapper-title-wrp {
  text-align: center;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}

.section-dv-love-for-lemons .featured-grid-wrapper-title-wrp p {
  font-size: 19px;
  letter-spacing: 1px;
  margin-bottom: 30px;
  color: #000;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .ais-hit--title a {
  color: #000;
  text-transform: capitalize;
  line-height: 1.2;
  font-weight: 400 !important;
  padding-bottom: 10px;
  margin: 0;
  letter-spacing: normal;
  font-size: 16px;
}

@media (max-width: 767px) {
  .dv-love-for-lemons-featured-wrapper .fallback-preview-lp-grid-products {
    grid-template-columns: repeat(2, 50%);
  }

  .dv-love-for-lemons-featured-wrapper .fallback-text-btn-wrapper p {
    line-height: 1.6;
    font-size: 14px;
  }
}

@media (max-width: 420px) {
  .dv-love-for-lemons-featured-wrapper .dv-love-for-lemons-featured-title-wrp h2 {
    padding: 10px 20px;
    font-size: 21px;
    margin: 0;
  }
}

@media (max-width: 540px) {

  .fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .quick_add_btn,
  .fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .ais-hit--title {
    font-size: 14px;
  }
}

@media (max-width: 575px) {
  .section-dv-love-for-lemons .featured-grid-wrapper-title-wrp p {
    line-height: 1.5;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 30px !important;
  }
}

@media (max-width: 575px) {
  .section-dv-love-for-lemons .featured-grid-wrapper-title-wrp {
    padding-bottom: 10px !important;
    padding-left: 0;
    padding-right: 0;
    max-width: 270px;
    width: 100%;
  }
}

@media (max-width: 540px) {
  .fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .ais-hit--title a {
    font-size: 14px;
  }

  .fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .ais-hit--price {
    font-size: 12px;
  }

  .fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .details_left_content_wp {
    width: 80%;
  }
}

/* End Love Lemons */

/* Trevor Project*/

.trevor-project-container .fallback-preview-lp-featured-grid-wrapper {
  padding: 40px 0 0;
  margin: 40px auto;
}

.trevor-project-container .featured-grid-wrapper-title-wrp h2,
.trevor-project-container .featured-grid-wrapper-title-wrp .h2 {
  text-transform: lowercase;
  font-style: normal;
  font-family: arimoregular !important;
  text-align: center;
  margin-top: 0;
}

.trevor-project-container.wrapper .__wrapper {
  padding: 0 !important;
}

@media (max-width: 768px) {
  .trevor-project-container.wrapper .shopify-section.customizable-banner .__wrapper img.__desktop-hidden {
    display: block;
  }

  .trevor-project-container.wrapper .shopify-section.customizable-banner .__wrapper img.__mobile-hidden {
    display: none;
  }


  .trevor-project-container.wrapper .shopify-section.customizable-banner .__wrapper {
    height: fit-content;
  }

  .comunity-section .comunity-section-wrapper .container .hadding-section h2 {
    font-size: 32px !important;
    margin: 0 !important;
  }
}

.comunity-section .comunity-section-wrapper .container .hadding-section h2 {
  font-weight: 100 !important;
  font-family: 'arimoregular';
  font-size: 32pt;
  line-height: 1.2;
  text-transform: lowercase;
  letter-spacing: 2.6px;
}

.comunity-section .comunity-section-wrapper .container .section-sub-hadding {
  margin: 20px auto 0 auto;
  width: 85%;
}

.comunity-section .comunity-section-wrapper .container .section-sub-hadding h6 {
  font-weight: 400 !important;
  font-family: 'arimoregular';
  line-height: 1.8;
  font-size: 20px;
  color: #000;
}

@media only screen and (max-width: 1440px) {
  .comunity-section .comunity-section-wrapper .comunity-top {
    margin: 85px auto 55px;
  }
}

@media only screen and (max-width: 1024px) {
  .comunity-section .comunity-section-wrapper .comunity-top {
    margin: 60px auto 0 !important;
  }
}

@media only screen and (max-width: 425px) {
  .comunity-section .comunity-section-wrapper .comunity-top {
    max-width: 100%;
    justify-content: space-between;
    padding: 0 30px;
  }
}

.comunity-section .comunity-section-wrapper .comunity-top .comunity-left-img {
  max-width: 105px;
  width: 100%;
}

.comunity-section .comunity-section-wrapper .comunity-top .center-text {
  margin: 7px 50px 0;
}

.comunity-section .comunity-section-wrapper .comunity-top .comunity-right-img {
  max-width: 233px;
  width: 100%;
}

.comunity-section .comunity-section-wrapper .comunity-top .comunity-right-img img {
  width: 100%;
}

.partnrship-section .partnrship-boxs {
  display: flex;
  align-items: center;
}

.min-partnrship-right {
  max-width: 61.4%;
  width: 100%;
  position: relative;
  z-index: 2;
  /* margin-left: -5%; */
  padding: 20px 0 20px 20px;
}

.partnrship-right_details {
  padding: 55px 80px;
  background: #fff;
  z-index: 1;
  margin-top: 0;
  position: relative;
}

@media only screen and (max-width: 375px) {
  .min-partnrship-right {
    padding: 0 0 25px 25px;
  }
}

@media only screen and (max-width: 1280px) {
  .donate-section .top-collection-btn .top-collection-btn_wrp {
    padding: 80px 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .min-partnrship-right {
    max-width: 100%;
    margin-left: 0;
  }

  .partnrship-section .partnrship-boxs {
    align-items: flex-end;
  }

  .partnrship-section .partnrship-boxs {
    padding-bottom: 0;
    flex-direction: column-reverse;
  }

  .partnrship-right_details {
    padding: 0 20px 30px;
    width: 100%;
  }

  .partnrship-right_details,
  .main-img-box_wrp {
    text-align: center;
  }

  .donate-left-container,
  .donate-section .top-collection-btn .top-collection-btn_wrp .top-left-btn,
  .donate-section .top-collection-btn .top-collection-btn_wrp .top-right-btn {
    max-width: 100%;
  }

  .min-partnrship-right .donate-left-container {
    max-width: 100% !important;
  }

  .donate-section .donate-row {
    flex-direction: column;
    align-items: center;
    padding: 70px 0 90px;
  }

  .min-partnrship-right .donate-left-container {
    max-width: 100%;
    padding: 0 25px 40px 0;
    margin-bottom: 0;
  }

  .min-partnrship-right .donate-left-container {
    max-width: 100%;
    height: 80%;
    bottom: 0;
    top: unset;
  }

  .donate-section .donate-row .donate-left {
    max-width: 100% !important;
    padding: 0 20px 20px 0 !important;
  }

  .donate-left .donate-left-container {
    max-width: 100% !important;
  }

  .donate-section .donate-row .donate-left h3,
  .donate-section .donate-row .donate-left .h3 {
    text-align: center;
  }

  .donate-section .donate-row .donate-left p {
    text-align: center;
    margin-top: 10px !important;
    line-height: 2 !important;
  }

  .only-moblie.arrow-moblie {
    display: none;
  }

  .donate-section .main-img-box-cs {
    max-width: 60%;
    margin-top: -90px;
    z-index: 3;
    align-self: center;
  }
}

@media only screen and (max-width: 480px) {

  .donate-section .donate-row {
    display: flex;
    align-items: flex-end;
    padding: 120px 0 50px !important;
  }

  .comunity-section .comunity-section-wrapper .container .section-sub-hadding h6 {
    font-size: 14px !important;
    line-height: 1.6 !important;
  }

  .main-img-box_wrp {
    padding: 20px 40px 120px !important;
  }

  .featured-grid-wrapper-title-wrp h2 {
    font-size: 30px !important;
    margin-left: 30px;
    margin-right: 30px;
  }

  .fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-featured-btn.button_pos_bottom {
    padding: 0 40px;
  }

}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .partnrship-right_details {
    padding: 20px;
  }

  .main-img-box_wrp {
    padding: 20px !important;
  }

  .donate-section .donate-row .donate-right {
    max-width: 67.1%;
    margin-bottom: 25%;
  }

  .donate-section .donate-row .donate-left p {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
}

@media only screen and (max-width: 1440px) {

  .partnrship-section .partnrship-boxs .min-partnrship-right h2,
  .partnrship-section .partnrship-boxs .min-partnrship-right .h2 {
    margin-top: 0;
  }
}

@media only screen and (max-width: 1280px) {

  .partnrship-section .partnrship-boxs .min-partnrship-right h2,
  .partnrship-section .partnrship-boxs .min-partnrship-right .h2 {
    line-height: 1;
    margin-top: 0;
    margin-bottom: 18px;
    letter-spacing: 5px;
  }
}

@media only screen and (max-width: 1440px) {

  .partnrship-section .partnrship-boxs .min-partnrship-right p,
  .donate-section .donate-row .donate-left p {
    font-size: 16px !important;
    line-height: 32px;
  }
}

.min-partnrship-right .donate-left-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  max-width: 80%;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}

.donate-left .donate-left-container {
  right: 0 !important;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  max-width: 80%;
}

@media only screen and (max-width: 1280px) {
  .main-img-box_wrp {
    padding: 30px 40px;
  }
}

.main-img-box_wrp {
  padding: 60px 90px;
  background: #fff;
  text-align: right;
  position: relative;
  z-index: 2;
}

.main-img-box_wrp h3 {
  margin: 0 !important;
}

.featured-grid-wrapper-title-wrp h2 {
  text-align: center;
  text-transform: lowercase;
  font-style: normal;
  font-family: arimoregular !important;
  font-size: 40px;
}

.fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-featured-btn.button_pos_bottom .btn {
  max-width: 350px !important;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  border-color: gray;
}

.fallback-lp-featured-collection .fallback-preview-lp-featured-btn a {
  display: block;
  max-width: unset;
  height: unset;
  padding: 15px 60px;
  font-weight: 600;
  text-transform: uppercase;
  font-weight: normal;
}

.partnrship-section .partnrship-boxs .min-partnrship-right p {
  line-height: 32px !important;
}

@media only screen and (max-width: 375px) {
  .donate-section .top-collection-btn .top-collection-btn_wrp {
    padding: 80px 50px !important;
  }
}

/* End Trevor Project*/

/* The holiday gift card*/

.section-Gift .fallback-preview-lp-featured-btn.desk-only {
  text-wrap: nowrap;
}

.grid-number-3 .grid-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  margin: -10px -5px 0;
}

@media (min-width: 767px) {
  .mobile-only {
    display: none !important;
  }
}

.grid-number-3 .grid-wrapper .img-wrapper {
  max-width: 33.33%;
  flex: 0 0 33.33%;
  padding: 10px 5px 0;
}

.grid-number-3 .grid-wrapper .img-wrapper>a {
  position: relative;
}

.grid-number-3 .grid-wrapper .img-wrapper.top>a:before,
.grid-number-3 .grid-wrapper .img-wrapper.bottom>a:before,
.grid-number-3 .grid-wrapper .img-wrapper.center>a:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: .2;
  z-index: 1;
}

.fallback-preview-lp-hero-wrp img,
.page_the-holiday-gift-guide-2022 .grid-wrapper .img-wrapper img {
  width: 100%;
}

.grid-number-3 .grid-wrapper .img-wrapper img {
  margin: 0 auto;
  display: block;
  width: 100%;
}

@media (min-width: 769px) {
  .hero-mob {
    display: none !important;
  }
}

section.fallback-preview-lp-featured-grid-wrapper.section-template__15639250174018__25a17551_0ec8_4e24_b68e_8c761606aa69.section-Gift.Guide.\32 022.container.page-width {
  padding: 0 !important;
}

.fallback-lp-featured-collection .featured-grid-wrapper-section-title {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}

.fallback-lp-featured-collection .fallback-text-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.featured-grid-wrapper-title-wrp.text--left {
  text-align: left;
}

.fallback-lp-featured-collection .featured-grid-wrapper-title-wrp {
  margin: 0;
  max-width: 100%;
}

.featured-grid-wrapper-title-wrp h2 {
  margin-bottom: 20px;
}

.featured-grid-wrapper-title-wrp h2 {
  font-style: italic;
  font-family: Baskerville !important;
  text-transform: lowercase;
  font-size: 40px;
  color: #000;
  letter-spacing: 2px;
  text-align: left;
}

.page_the-holiday-gift-guide-2022 .featured-grid-wrapper-title-wrp p {
  letter-spacing: .5px;
}

.featured-grid-wrapper-title-wrp p {
  margin-bottom: 5px;
}

.featured-grid-wrapper-title-wrp p {
  color: #000;
}

.fallback-lp-featured-collection .fallback-preview-lp-featured-btn {
  padding: 0;
}

.fallback-preview-lp-featured-btn {
  text-align: end;
  padding: 20px 160px;
}

@media (min-width: 767px) {
  .desk-only {
    display: block !important;
  }

}

.fallback-lp-featured-collection .fallback-preview-lp-featured-btn a {
  display: block;
  max-width: unset;
  height: unset;
  padding: 15px 60px;
  font-weight: 600;
  text-transform: uppercase;
}

.fallback-preview-lp-featured-btn a {
  color: #000;
  font-weight: 600;
  font-family: arimoregular;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 0;
  border: 2px solid #000000;
  background-color: transparent;
  max-width: 28.847%;
  height: 70px;
  font-size: 19px;
  letter-spacing: 2px;
  line-height: 37px;
  text-decoration: none;
}

.fallback-lp-featured-collection .fallback-preview-lp-grid-wrapper {
  max-width: 1600px;
  margin: 0 auto;
  /* padding: 0 15px; */
  width: 100%;
}

.fallback-lp-featured-collection .fallback-preview-lp-grid-products {
  margin: 0 !important;
  margin-bottom: 10px !important;
}

p.ais-hit--price {
  margin: 0;
}

section.fallback-preview-lp-hero-section.section-template--15639250174018__eb7c4250-b336-4b55-adfb-f907f9f07317.fb-lp-section-template--15639250174018__eb7c4250-b336-4b55-adfb-f907f9f07317.container {
  padding: 0 !important;
}

.fallback-preview-lp-hero-btn .btn.focus,
.fallback-preview-lp-hero-btn .btn:focus,
.fallback-preview-lp-hero-btn .btn:hover,
.fallback-preview-lp-featured-btn .btn.focus,
.fallback-preview-lp-featured-btn .btn:focus,
.fallback-preview-lp-featured-btn .btn:hover {
  background: #000 !important;
  opacity: 1;
  color: #fff !important;
  transition: 0.3s all ease-in-out;
}

.fallback-preview-lp-hero-btn .btn.focus,
.fallback-preview-lp-hero-btn .btn:focus,
.fallback-preview-lp-hero-btn .btn:hover,
.fallback-preview-lp-featured-btn .btn.focus,
.fallback-preview-lp-featured-btn .btn:focus,
.fallback-preview-lp-featured-btn a:hover {
  text-decoration: none !important;
}

.fallback-lp-featured-collection .fallback-preview-lp-featured-btn a {
  display: block;
  max-width: unset;
  height: unset;
  padding: 15px 60px;
  font-weight: 600;
  text-transform: uppercase;
}

section.fallback-preview-lp-featured-grid-wrapper.section-template__15639250174018__bed28cde_51cd_43d1_a469_7c42981caabe.section-Gift.Guide.\32 022.container.page-width {
  padding: 0 !important;
}

section.fallback-preview-lp-hero-section.section-template--15639250174018__0dab00c9-e889-4b45-8c0e-653425af692c.fb-lp-section-template--15639250174018__0dab00c9-e889-4b45-8c0e-653425af692c.container {
  padding: 0 !important;
}

section.fallback-preview-lp-featured-grid-wrapper.section-template__15639250174018__c630f2fc_2d78_4f80_a8af_ef48054f9a95.section-Gift.Guide.\32 022.container.page-width {
  padding: 0 !important;
}

section.fallback-preview-lp-hero-section.section-template--15639250174018__c319dfd2-7334-46eb-b3f4-eb3c26a1ab54.fb-lp-section-template--15639250174018__c319dfd2-7334-46eb-b3f4-eb3c26a1ab54.container {
  padding: 0 !important;
}

section.fallback-preview-lp-featured-grid-wrapper.section-template__15639250174018__50db15fe_3ef8_430b_b200_4958af9253d8.section-Gift.Guide.\32 022.container.page-width {
  padding: 0 !important;
}


@media (max-width: 991px) {
  .image-grid.section_template--15627073749058__398bf939-b223-4a90-8a24-a7d633b42ea6.grid-number-3 {
    padding: 40px 0px 40px 0px;
  }
}

@media (min-width: 767px) {
  .mobile-only {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .grid-number-3 .grid-wrapper {
    margin: 0;
  }

  .grid-number-3 .grid-wrapper .img-wrapper {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0;
    margin-bottom: 30px;
  }

  .hero-mob {
    display: block !important;
  }

  .hero-desk {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .fallback-lp-featured-collection .fallback-preview-lp-featured-btn a {
    font-weight: 600;
    padding: 15px 20px;
    text-transform: uppercase;
    text-wrap: nowrap;
  }

  .fallback-lp-featured-collection .fallback-text-btn-wrapper {
    padding-bottom: 0;
  }

  #shopify-section-template--15639250108482__bed28cde-51cd-43d1-a469-7c42981caab1 .fall-campaign-lp {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  #shopify-section-template--15639250108482__bed28cde-51cd-43d1-a469-7c42981caabn .fall-campaign-lp {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  #shopify-section-template--15639250108482__bed28cde-51cd-43d1-a469-7c42981caabb .fall-campaign-lp {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  #shopify-section-template--15639250108482__bed28cde-51cd-43d1-a469-7c42981caabe .fall-campaign-lp {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media (max-width: 991px) {

  section.fallback-preview-lp-featured-grid-wrapper.section-template__15639250174018__25a17551_0ec8_4e24_b68e_8c761606aa69.section-Gift.Guide.\32 022.container.page-width {
    margin: 0 !important;
    padding: 20px 0 !important;
  }

  section.fallback-preview-lp-featured-grid-wrapper.section-template__15639250174018__bed28cde_51cd_43d1_a469_7c42981caabe.section-Gift.Guide.\32 022.container.page-width {
    margin: 0 !important;
    padding: 20px 0 !important;
  }

  section.fallback-preview-lp-featured-grid-wrapper.section-template__15639250174018__c630f2fc_2d78_4f80_a8af_ef48054f9a95.section-Gift.Guide.\32 022.container.page-width {
    margin: 0 !important;
    padding: 20px 0 !important;
  }

  section.fallback-preview-lp-featured-grid-wrapper.section-template__15639250174018__50db15fe_3ef8_430b_b200_4958af9253d8.section-Gift.Guide.\32 022.container.page-width {
    margin: 0 !important;
    padding: 20px 0 !important;
  }

  .fallback-preview-lp-featured-btn a,
  .fallback-preview-lp-hero-btn .btn {
    font-size: 14px;
    max-width: 100%;
    margin: 0 auto;
  }

  .featured-grid-wrapper-title-wrp h2 {
    margin-top: 0 !important;
    margin-bottom: 10px !important;
  }

  .featured-grid-wrapper-title-wrp p {
    margin-top: 0 !important;
  }

}

@media only screen and (max-width: 480px) {

  .fallback-preview-lp-hero-wrp,
  .fallvack-video-wrapper {
    height: fit-content !important;
  }
}

.section-template--15639250174018__b67da1ab-41d0-41fb-b925-29b0f734ed92.fallback-preview-lp-hero-section {
  padding: 0 !important;
}

.section-template--15639250174018__main.fallback-preview-lp-hero-section {
  padding: 0 !important;
}

@media (max-width: 991px) {
  .image-grid.section_template--15639250174018__398bf939-b223-4a90-8a24-a7d633b42ea6.grid-number-3 {
    padding: 40px 0px 0px 0px !important;
  }

  .image-grid .grid-wrapper-text p {
    color: #000;
    font-weight: 400;
    text-align: center;
    font-size: 16px;
    padding-bottom: 35px;
    margin: 0 !important;
  }

}

@media (max-width: 767px) {
  .page_the-holiday-gift-guide-2022 .fallback-preview-lp-featured-grid-wrapper {
    padding: 50px 0 70px;
  }

  .fallback-lp-featured-collection .featured-grid-wrapper-title-wrp {
    max-width: 100%;
    padding: 0 0 15px 15px;
  }

  .seasonal_edit_lp .fallback-featured-product-right .fallback-featured-product-head,
  .featured-grid-wrapper-title-wrp h2 {
    font-size: 30px;
  }
}

@media (max-width: 1024px) {
  .fallback-lp-featured-collection .fallback-text-btn-wrapper {
    padding-bottom: 0;
  }
}

@media (max-width: 991px) {
  .featured-grid-wrapper-title-wrp h2 {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {

  .seasonal_edit_lp .fallback-featured-product-right .fallback-featured-product-par,
  .featured-grid-wrapper-title-wrp p {
    font-size: 14px;
    margin-top: 8px;
    line-height: 16px;
  }
}

@media only screen and (max-width: 480px) {
  .section-Gift .featured-grid-wrapper-title-wrp h2 {
    font-size: 30px !important;
    margin: 0 !important;
    margin-bottom: 10px !important;
  }
}

@media (max-width: 767px) {
  .fallback-lp-featured-collection .fallback-preview-lp-featured-btn {
    max-width: 100%;
    margin: 10px auto 0;
    padding: 0 40px;
  }
}

@media (max-width: 1024px) {
  .fallback-lp-featured-collection .fallback-preview-lp-featured-btn a {
    font-weight: 600;
    padding: 15px 20px;
    text-transform: uppercase;
  }
}

/* End the holiday gift card*/

/* Social club */
.__pf.__pf_ZVDlAkFw .pf-50_ {
  color: white !important;
}
/* End social club*/

/* Fall campaign*/

[id$="template--15639250108482__ddcb87fc-626b-4a0a-826c-c31d9cf97c11"] .__wrapper {
  padding: 0 !important;
}

[id$="template--15639250108482__ddcb87fc-626b-4a0a-826c-c31d9cf97c13"] .__wrapper {
  padding: 0 !important;
}

.section-template--15639250108482__16576008324e6438d9.fallback-preview-lp-hero-section {
  padding: 0 !important;
}

.section-template--15639250108482__16576008324e6438d9 .fallback-preview-lp-hero-btn.on_banner_btn .btn {
  width: fit-content;
}

[id$="template--15639250108482__ddcb87fc-626b-4a0a-826c-c31d9cf97c20"] .__wrapper {
  padding: 0 !important;
}

[id$="template--15639250108482__ddcb87fc-626b-4a0a-826c-c31d9cf97c17"] .__wrapper {
  padding: 0 !important;
}

#shopify-section-template--15639250108482__bed28cde-51cd-43d1-a469-7c42981caabn .fall-campaign-lp {
  padding-top: 0 !important;
}

#shopify-section-template--15639250108482__bed28cde-51cd-43d1-a469-7c42981caab1 .fall-campaign-lp {
  padding-top: 0 !important;
}

#shopify-section-template--15639250108482__bed28cde-51cd-43d1-a469-7c42981caabb .fall-campaign-lp {
  padding-top: 0 !important;
}

#shopify-section-template--15639250108482__bed28cde-51cd-43d1-a469-7c42981caabe .fall-campaign-lp {
  padding-top: 0 !important;
}

.fallback-preview-lp-marqee-section-title.fall-campaign-lp {
  padding: 50px 0 0 !important;
}

.fallback-preview-lp-details-section {
  margin-bottom: 50px;
}

.fallback-preview-lp-details-section.fall-campaign-lp,
.fallback-preview-lp-marqee-section-title.fall-campaign-lp {
  text-align: center;
}

.fallback-preview-lp-marqee-section-title h3 {
  letter-spacing: .2rem;
  color: #000;
  font-size: 24px;
  font-weight: 500 !important;
}

.fallback-preview-lp-marqee-section-title.fall-campaign-lp h2 {
  font-family: 'arimoregular' !important;
  font-weight: 700;
  padding: 10px 0;
  letter-spacing: .4rem;
  font-style: normal;
  text-transform: uppercase;
  margin-top: 0 !important;
}

.fall-campaign-lp.fallback-preview-lp-featured-grid-wrapper .featured-grid-wrapper-title-wrp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.featured-grid-wrapper-title-wrp.text--right {
  text-align: right;
}

.fallback-lp-featured-collection .featured-grid-wrapper-title-wrp {
  margin: 0;
  max-width: 100%;
  width: 100%;
}

.fall-campaign-lp.fallback-preview-lp-featured-grid-wrapper .featured-grid-wrapper-title-wrp h2 {
  font-family: 'arimoregular' !important;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 28px;
  margin: 0;
  width: 100%;
  max-width: calc(100% - 800px);
  text-align: left;
  letter-spacing: 1px;
  margin-left: 15px;
}

.fall-campaign-lp.fallback-preview-lp-featured-grid-wrapper .featured-grid-wrapper-title-wrp p {
  width: 100%;
  max-width: 650px;
  line-height: 1.2;
  margin-right: 15px;
}

.section-template--15639250108482__16576008324e6438d9 .fallback-preview-lp-hero-btn.on_banner_btn .btn {
  padding: 19px 45px !important;
  text-decoration: none !important;
  font-weight: 600;
  letter-spacing: 2px;
}

@media (max-width: 991px) {

  .section-dv-love-for-lemons .fallback-gift-lp-grid-wrapper,
  .fall-campaign-lp.fallback-preview-lp-featured-grid-wrapper,
  .section-spring_campaign_24 .fallback-gift-lp-grid-wrapper {
    padding: 0 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .fallback-preview-lp-marqee-section-title h3 {
    font-size: 18px;
  }

  .fall-campaign-lp .fallback-preview-lp-details-wrp {
    max-width: 100% !important;
  }

  .fallback-preview-lp-details-section.fall-campaign-lp .fallback-preview-lp-details-wrp p {
    font-size: 12px;
  }

  .fall-campaign-lp.fallback-preview-lp-featured-grid-wrapper .featured-grid-wrapper-title-wrp {
    padding: 0;
    text-align: center;
  }

  .fall-campaign-lp.fallback-preview-lp-featured-grid-wrapper .featured-grid-wrapper-title-wrp h2 {
    width: 100%;
    max-width: 100%;
    font-size: 20px !important;
    text-align: center;
    margin-top: 10px !important;
  }

  .fall-campaign-lp.fallback-preview-lp-featured-grid-wrapper .featured-grid-wrapper-title-wrp p {
    width: 100%;
    max-width: 300px;
    margin: 8px auto 5px;
    font-size: 12px;
  }

  .fall-campaign-lp.fallback-preview-lp-featured-grid-wrapper {
    padding: 20px 0;
  }
}

.fallback-preview-lp-details-section.fall-campaign-lp .fallback-preview-lp-details-wrp p {
  letter-spacing: 1px;
}

@media (max-width: 991px) {
  .fallback-preview-lp-hero-btn {
    padding: 0 20px;
  }

  .fallback-preview-lp-featured-btn a,
  .fallback-preview-lp-hero-btn .btn {
    font-size: 14px;
    max-width: 100%;
    margin: 0 auto;
  }

  .section-template--15639250108482__16576008324e6438d9 .fallback-preview-lp-hero-btn.on_banner_btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    width: 100%;
    text-align: center;
    text-wrap: nowrap;
  }
}

/* End fall campaign*/

/* The trend edit*/

#shopify-section-template--15639250141250__1657617396c70b8630 .fallback-preview-lp-featured-grid-wrapper, .fallback-preview-lp-featured-grid-wrapper.section-spring_campaign_24 {
  padding-right: 0 !important;
}

.fallback-preview-lp-featured-grid-wrapper {
  overflow: hidden;
}

.fallback-preview-lp-featured-grid-wrapper {
  position: relative;
  padding: 60px 0;
  margin: 60px 0;
}

.fallback-featured-img-wrp a {
  position: relative;
  overflow: hidden;
  display: block;
  padding-top: 95%;
}

.featured-grid-wrapper-section-title {
  margin-right: calc((100% - 1400px) / 2);
  padding: 20px 70px 30px 0;
  display: flex;
  align-items: center;
  justify-content: end;
}

.featured-grid-wrapper-title-wrp {
  text-align: left;
  max-width: 400px;
  width: 100%;
  margin-left: 50px;
}

@media (max-width: 1440px) {

  .fallback-preview-lp-featured-grid-wrapper,
  .fallback-preview-lp-featured-grid-wrapper.section-spring_campaign_24 {
    position: relative;
    padding: 35px 0;
    margin: 35px 0;
  }
}

.fallback-preview-lp-featured-grid-img {
  width: 100%;
  max-width: 58%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.fallback-featured-img-wrp a {
  position: relative;
  overflow: hidden;
  display: block;
  padding-top: 95%;
}

.fallback-featured-img-wrp a img {
  margin: 0 auto;
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: 1400px) {
  .featured-grid-wrapper-section-title {
    margin-right: 0;
    padding: 20px 20px 60px 0;
    max-width: 40%;
    width: 100%;
    margin-left: auto;
  }
}

.featured-grid-wrapper-title-wrp {
  text-align: left;
  max-width: 400px;
  width: 100%;
  margin-left: 50px;
}

.fallback-preview-lp-grid-wrapper {
  max-width: 58%;
  width: 100%;
  margin-left: auto;
  margin-right: 0;
}

.fallback-preview-lp-featured-btn {
  text-align: end;
  padding: 20px 160px;
}

@media (max-width: 991px) {

  .fallback-featured-image-right .fallback-preview-lp-featured-btn,
  .fallback-preview-lp-featured-btn {
    padding: 20px;
    padding-bottom: 40px;
  }
}

.fallback-preview-lp-hero-btn {
  margin-top: 30px;
  margin-bottom: 70px;
}

.section-template--15627073683522__16576008324e6438d9 .fallback-preview-lp-hero-btn .btn {
  border: 2px solid #000000;
  background-color: #ffffff;
  color: #000000;
}

.fallback-preview-lp-hero-btn .btn {
  font-size: 19px;
  letter-spacing: 2px;
  max-width: 450px;
  height: 70px;
  vertical-align: middle;
  line-height: 37px;
  font-weight: 600;
  font-family: arimoregular;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none !important;
}

section.fallback-preview-lp-hero-section.section-template--15639250141250__main.fb-lp-section-template--15639250141250__main.container {
  padding: 0;
}

@media (max-width: 2560px) {

  section.fallback-preview-lp-hero-section.section-template--15639250141250__16576008324e6438d9.fb-lp-section-template--15639250141250__16576008324e6438d9.container {
    padding: 60px 0;
  }
}

@media (max-width: 1440px) {
  section.fallback-preview-lp-hero-section.section-template--15639250141250__16576008324e6438d9.fb-lp-section-template--15639250141250__16576008324e6438d9.container {
    padding: 35px 0;
  }
}

@media (max-width: 768px) {
  section.fallback-preview-lp-hero-section.section-template--15639250141250__16576008324e6438d9.fb-lp-section-template--15639250141250__16576008324e6438d9.container {
    padding: 0 !important;
  }
}

@media (max-width: 991px) {
  .fallback-preview-lp-marqee-section {
    padding: 31px 0 26px !important;
  }

  .fallback-preview-lp-grid-wrapper,
  .featured-grid-wrapper-section-title,
  .fallback-preview-lp-featured-grid-img {
    max-width: 100%;
    position: relative;
  }

  .fallback-featured-img-wrp a {
    padding-top: 50%;
  }

  .featured-grid-wrapper-section-title {
    padding: 20px;
    margin-right: auto;
    margin-left: 0;
    justify-content: start;
  }

  .featured-grid-wrapper-title-wrp {
    margin-left: 0;
  }

  .fallback-preview-lp-featured-grid-wrapper,
  .fallback-preview-lp-featured-grid-wrapper.section-spring_campaign_24 {
    margin: 10px 0;
    padding: 20px 0;
  }

  .fallback-preview-lp-hero-btn {
    padding: 0 20px;
  }

  .fallback-preview-lp-featured-btn a,
  .fallback-preview-lp-hero-btn .btn {
    font-size: 14px;
    max-width: 100%;
    margin: 0 auto;
  }
}




/* End the trend edit*/

/* The Thailand Edit*/
/* .fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .product_img_wp a img,
.product-upsell-items-wrapper .product_img_wp {
  margin: 0 auto;
  max-height: 332px;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 480px) {

  .fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .product_img_wp a img,
  .product-upsell-items-wrapper .product_img_wp {
    max-height: 157px;
  }
}

@media (max-width: 768px) {

  .fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .product_img_wp a img,
  .product-upsell-items-wrapper .product_img_wp {
    max-height: 210px !important;
  }
}

@media (max-width: 1024px) {

  .fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .product_img_wp a img,
  .product-upsell-items-wrapper .product_img_wp {
    max-height: 272px;
  }
}

@media (max-width: 425px) {

  .fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .product_img_wp a img,
  .product-upsell-items-wrapper .product_img_wp {
    max-height: 182px !important;
  }
}

@media (max-width: 375px) {

  .fallback-preview-lp-featured-grid-wrapper .fallback-preview-lp-grid-wrapper .grid-wrapper .product_img_wp a img,
  .product-upsell-items-wrapper .product_img_wp {
    max-height: 157px !important;
  }
} */

/* End The Thailand Edit*/


/* The Seasonal Edit*/

#shopify-section-template--15639250239554__1663152370b54e3b5f section.fallback-preview-lp-img-text-section {
  padding-top: 30px;
}

.fallback-preview-lp-marqee-section {
  padding: 62px 0 54px;
}

/* End The Seasonal Edit*/

/* Tea Time */
.fallback-preview-lp-details-wrp p {
  line-height: 1.5;
}

@media (max-width: 991px) {
  .fallback-preview-lp-details-wrp p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  /* .fallback-preview-lp-hero-section {
    padding: 0 !important;
  } */
}

.fs-recommendation-widget-products-container {
  width: 100% !important;
}

img.desk-only {
  width: 100%;
}

div#fast-simon-upsell-1717060946845 div#fs-recommendation-widget-main div.widget-container div.fs-recommendation-widget-products-container.fs-rec-widget-q46gq3 {
  width: 100% !important;
}

@media (max-width: 420px) {
  .fallback-preview-lp-marqee-section-title h2 {
    font-size: 40px;
  }

  .fallback-preview-lp-details-section.page-width {
    padding: 0 10px;
  }

  .fallback-preview-lp-details-wrp {
    width: 100%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .fallback-preview-lp-details-wrp p {
    letter-spacing: normal;
    line-height: 1.5;
  }

  .__wrapper.container.page-width {
    margin-bottom: 40px !important;
    margin-top: 30px !important;
  }

}

/*End Tea Time*/
@media (max-width: 1440px) {

  .fallback-preview-lp-featured-grid-wrapper,
  .fallback-preview-lp-featured-grid-wrapper.section-spring_campaign_24 {
    position: relative;
    padding: 35px 5% !important;
    margin: 0 0 !important;
  }
}

@media only screen and (max-width: 1210px) {

  .container,
  .pcontainer {
    padding: 0 40px !important;
    width: 100%;
  }

  .fallback-preview-lp-featured-grid-wrapper,
  .fallback-preview-lp-featured-grid-wrapper.section-spring_campaign_24 {
    position: relative;
    padding: 50px 5% !important;

  }

  #shopify-section-template--15639250141250__1657617396c70b8630 .fallback-preview-lp-featured-grid-wrapper,
  .fallback-preview-lp-featured-grid-wrapper.section-spring_campaign_24 {
    padding: 0 !important;
  }
}

#shopify-section-template--15639250141250__16576015454b9c02a2 .fallback-preview-lp-details-wrp p {
  text-align: left;
  padding-bottom: 35px !important;
}

@media (max-width: 1024px) {
  #shopify-section-template--15639250141250__16576015454b9c02a2 .fallback-preview-lp-details-wrp p br {
    display: none;
  }
}

@media (max-width: 991px) {

  .fallback-preview-lp-featured-grid-wrapper,
  .fallback-preview-lp-featured-grid-wrapper.section-spring_campaign_24 {
    margin: 1px 0 !important;
    padding: 20px 5% !important;
  }
}

@media (max-width: 768px) {

  .fallback-preview-lp-featured-grid-wrapper,
  .fallback-preview-lp-featured-grid-wrapper.section-spring_campaign_24 {
    margin: 1px 0 !important;
    padding: 0px 5% !important;
  }
}

@media (max-width: 767px) {

  .fallback-lp-featured-collection .product-badge,
  .fallback-preview-lp-pro-item .new_product_badge {
    top: 1px !important;
    right: 1px !important;
    text-wrap: nowrap;
    padding: 1px 10px !important;
  }

  .container,
  .pcontainer {
    padding: 0 10px !important;
    width: 100%;
  }
}

/*product cards*/
.fallback-lp-featured-collection .grid-wrapper {
  margin: 0 !important;
  max-width: 33.33%;
  width: 100%;
  display: block;
  padding: 10px 25px;
}

@media (max-width: 1024px) {
  .fallback-lp-featured-collection .grid-wrapper {
    padding: 10px 15px;
  }
}

@media (max-width: 540px) {
  .fallback-lp-featured-collection .grid-wrapper {
    padding: 10px 5px;
    max-width: 50%;
  }
}

/* The Weeding Shop */

.section-template--15598024884290__image_banner_0.fallback-preview-lp-hero-section {
  padding: 0 !important;
}

[id$="template--15598024884290__image_banner_1"] .__wrapper {
  padding: 0 !important;
}

[id$="template--15598024884290__image_banner_3"] .__wrapper {
  padding: 0 !important;
}

[id$="template--15598024884290__image_banner_5"] .__wrapper {
  padding: 0 !important;
}

@media (max-width: 2560px) {
  [id$="template--15598024884290__image_banner_1"] .__wrapper {
    padding-bottom: 120px !important;
  }

  [id$="template--15598024884290__image_banner_3"] .__wrapper {
    padding-bottom: 120px !important;
    padding-top: 120px !important;
  }

  [id$="template--15598024884290__image_banner_5"] .__wrapper {
    padding-bottom: 120px !important;
    padding-top: 120px !important;
  }
}

@media (max-width: 1440px) {
  [id$="template--15598024884290__image_banner_1"] .__wrapper {
    padding-bottom: 70px !important;
  }

  [id$="template--15598024884290__image_banner_3"] .__wrapper {
    padding-bottom: 70px !important;
    padding-top: 70px !important;
  }

  [id$="template--15598024884290__image_banner_5"] .__wrapper {
    padding-bottom: 70px !important;
    padding-top: 70px !important;
  }
}

@media (max-width: 768px) {
  [id$="template--15598024884290__image_banner_1"] .__wrapper {
    padding-bottom: 30px !important;
  }

  [id$="template--15598024884290__image_banner_3"] .__wrapper {
    padding-bottom: 30px !important;
    padding-top: 30px !important;
  }

  [id$="template--15598024884290__image_banner_5"] .__wrapper {
    padding-bottom: 30px !important;
    padding-top: 30px !important;
  }
}

.fallback-lp-featured-collection .fallback-preview-lp-grid-wrapper {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}

@media (max-width: 480px) {
  .fallback-preview-lp-hero-wrp .desk-only {
    display: none !important;
  }

  .fallback-preview-lp-hero-wrp .mobile-only {
    display: block !important;
  }

  .fallback-preview-lp-hero-wrp a {
    display: block;
    /* height: 80vh; */
  }

  .fallback-preview-lp-details-wrp {
    margin: 0 0 !important;
  }
}

.fallback-preview-lp-hero-wrp a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 20% 50%;
}

@media (max-width: 768px) {
  .shopify-section.customizable-banner .__wrapper a img.__mobile-hidden {
    display: none;
  }

  .__wrapper.container.page-width {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .shopify-section.customizable-banner .__wrapper {
    /* height: 90vh; */
  }

  .shopify-section.customizable-banner .__wrapper a {
    /* height: 90vh; */
  }

  .shopify-section.customizable-banner .__wrapper a img.__desktop-hidden {
    display: block;
    width: 100%;
    height: fit-content;
    object-fit: cover;
    object-position: center;
  }

  [id^="shopify-section-template--15132778561669"] .__wrapper a img.__desktop-hidden {
    height: auto!important;
  }

  .widget-main .fs-recommendation-widget-title {
    background-color: #107bbd !important;
  }

  .fallback-preview-lp-details-wrp p {
    padding: 0 !important;
  }
}

/*End The Weedind Shop*/

/* Start Campaign */

[id$="template--15598024785986__image_banner_2"] .__wrapper {
  padding: 0 !important;
}

[id$="template--15598024785986__image_banner_4"] .__wrapper {
  padding: 0 !important;
}

[id$="template--15598024785986__image_banner_6"] .__wrapper {
  padding: 0 !important;
}

@media (max-width: 2560px) {
  [id$="template--15598024785986__image_banner_2"] .__wrapper {
    padding-bottom: 120px !important;
    padding-top: 120px !important;
  }

  [id$="template--15598024785986__image_banner_4"] .__wrapper {
    padding-bottom: 120px !important;
    padding-top: 120px !important;
  }

  [id$="template--15598024785986__image_banner_6"] .__wrapper {
    padding-bottom: 120px !important;
    padding-top: 120px !important;
  }
}

@media (max-width: 1440px) {
  [id$="template--15598024785986__image_banner_2"] .__wrapper {
    padding-bottom: 70px !important;
    padding-top: 70px !important;
  }

  [id$="template--15598024785986__image_banner_4"] .__wrapper {
    padding-bottom: 70px !important;
    padding-top: 70px !important;
  }

  [id$="template--15598024785986__image_banner_6"] .__wrapper {
    padding-bottom: 70px !important;
    padding-top: 70px !important;
  }
}

@media (max-width: 768px) {
  [id$="template--15598024785986__image_banner_2"] .__wrapper {
    padding-bottom: 30px !important;
    padding-top: 30px !important;
  }

  [id$="template--15598024785986__image_banner_4"] .__wrapper {
    padding-bottom: 30px !important;
    padding-top: 30px !important;
  }

  [id$="template--15598024785986__image_banner_6"] .__wrapper {
    padding-bottom: 30px !important;
    padding-top: 30px !important;
  }

}

/*End Campaign*/

/* Start Spring Campaign 2024*/

[id$="template--15598024818754__image_banner_0"] .__wrapper {
  padding: 0 !important;
}

.section-template--15598024785986__video_banner_0.fallback-preview-lp-hero-section {
  padding: 0 !important;
}

[id$="template--15598024818754__multicolumn_3"] .__wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

[id$="template--15598024818754__multicolumn_5"] .__wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

[id$="template--15598024818754__multicolumn_2"] .__wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

[id$="template--15598024818754__multicolumn_7"] .__wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (max-width: 2560px) {
  [id$="template--15598024818754__image_banner_0"] .__wrapper {
    padding-bottom: 100px !important;
  }
}

@media (max-width: 1440px) {
  [id$="template--15598024818754__image_banner_0"] .__wrapper {
    padding-bottom: 50px !important;
  }

}

@media (max-width: 768px) {
  [id$="template--15598024818754__image_banner_0"] .__wrapper {
    padding-bottom: 10px !important;
  }


}

.spring-campaign-text.fallback-preview-lp-details-section .spring-details-wrp {
  color: #1c1c1c;
  font-size: 19px;
  letter-spacing: 1px;
  line-height: 1.5;
  margin-top: 30px;
}

.__wrapper .__title h1 {
  display: none;
}

.__wrapper .__blocks .__block {
  margin-bottom: 5%;
  max-width: 50% !important;
}

@media (max-width: 1023px) {
  .__wrapper .__blocks .__block {
    max-width: 100% !important;
  }
}

#shopify-section-template--15598024818754__multicolumn_7 .__wrapper .__blocks div#column_0,
#shopify-section-template--15598024818754__multicolumn_7 .__wrapper .__blocks div#column_1,
#shopify-section-template--15598024818754__multicolumn_7 .__wrapper .__blocks div#column_2 {
  max-width: 33.33% !important;
}

.__block .__text .__title {
  display: none;
}

[id$="template--15598024818754__multicolumn_4"] .__wrapper {
  padding: 0 !important;
}

[id$="template--15598024818754__multicolumn_8"] .__wrapper {
  padding: 0 !important;
}

.spring-campaign-text.fallback-preview-lp-details-section .spring-details-wrp p {
  text-align: center;
}

@media (min-width: 0px) {
  .fallback-preview-lp-details-section.section-template--15598024818754__page_text_6 {
    display: block !important;
  }
}

@media (max-width: 480px) {
  .__wrapper .__blocks .__block {
    max-width: 100% !important;
  }
}

@media (max-width: 768px) {
  .__wrapper .__blocks div#column_0 {
    max-width: 50% !important;
  }

  .__wrapper .__blocks div#column_1 {
    max-width: 50% !important;
  }

  .__wrapper .__blocks div#column_2 {
    max-width: 100% !important;
  }

  .shopify-section.customizable-banner .__wrapper img.__desktop-hidden {
    display: none;
  }

  .shopify-section.customizable-banner .__wrapper {
    /* height: 90vh; */
  }

  .shopify-section.customizable-banner .__wrapper {
    /* height: 90vh; */
  }

  .shopify-section.customizable-banner .__wrapper img.__mobile-hidden {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 50%;
  }

  .__wrapper.container.page-width {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
}

/* End Spring Campaign 2024*/

.shopify-section-header-sticky {
  position: sticky;
  top: 0;
}

.shopify-section-header-hidden {
  top: calc(-1 * var(--header-height));
}

.shopify-section-header-hidden.menu-open {
  top: 0;
}

.section-header.animate {
  transition: top 0.15s ease-out;
}

.shopify-section-group-header-group {
  z-index: 4;
}

.section-header~.shopify-section-group-header-group {
  z-index: initial;
}

/* Main Header Layout */
.header-wrapper {
  display: block;
  background-color: rgb(var(--color-background));
}

.header-wrapper--border-bottom {
  border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.08);
}

.header {
  display: grid;
  grid-template-areas: 'left-icons heading icons';
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
}

@media screen and (max-width: 749px) {
  .header--has-app {
    grid-template-columns: auto 1fr auto;
  }
}

@media screen and (min-width: 990px) {
  .header {
    grid-template-columns: 1fr auto 1fr;
  }

  .header--top-left,
  .header--middle-left:not(.header--has-menu) {
    grid-template-areas:
      'heading icons'
      'navigation navigation';
    grid-template-columns: 1fr auto;
  }

  .header--top-left.drawer-menu,
  .header--middle-left.drawer-menu {
    grid-template-areas: 'navigation heading icons';
    grid-template-columns: auto 1fr auto;
    column-gap: 1rem;
  }

  .header--middle-left {
    grid-template-areas: 'heading navigation icons';
    grid-template-columns: auto auto 1fr;
    column-gap: 1rem;
  }

  .header--middle-center:not(.drawer-menu) {
    grid-template-areas: 'navigation heading icons';
    grid-template-columns: 1fr auto 1fr;
    column-gap: 2rem;
  }

  .header--middle-center a.header__heading-link {
    text-align: center;
  }

  .header--top-center {
    grid-template-areas:
      'left-icons heading icons'
      'navigation navigation navigation';
  }

  .header--top-center.drawer-menu {
    grid-template-areas: 'left-icons heading icons';
    grid-template-columns: 1fr auto 1fr;
  }

  .header:not(.header--middle-left, .header--middle-center) .header__inline-menu {
    margin-top: 1.05rem;
  }
}

.header *[tabindex='-1']:focus {
  outline: none;
}

.header__heading {
  margin: 0;
  line-height: 0;
}

.header>.header__heading-link {
  line-height: 0;
}

.header__heading,
.header__heading-link {
  grid-area: heading;
  justify-self: center;
}

.header__heading-link {
  display: inline-block;
  padding: 0.75rem;
  text-decoration: none;
  word-break: break-word;
}

.header__heading-link:hover .h2 {
  color: rgb(var(--color-foreground));
}

.header__heading-link .h2 {
  line-height: 1;
  color: rgba(var(--color-foreground), 0.75);
}

.header__heading-logo {
  height: auto;
  max-width: 100%;
  padding-left: 6px;
}

.header__heading-logo-wrapper {
  width: 100%;
  display: inline-block;
  transition: width 0.3s cubic-bezier(0.52, 0, 0.61, 0.99);
}

@media screen and (max-width: 989px) {

  .header__heading,
  .header__heading-link {
    text-align: center;
  }

  .header--mobile-left .header__heading,
  .header--mobile-left .header__heading-link {
    text-align: left;
    justify-self: start;
  }

  .header--mobile-left {
    grid-template-columns: auto 2fr 1fr;
  }
}

@media screen and (min-width: 990px) {

  .header--middle-left .header__heading-link,
  .header--top-left .header__heading-link {
    margin-left: -0.75rem;
  }

  .header__heading,
  .header__heading-link {
    justify-self: start;
  }

  .header--middle-center .header__heading-link,
  .header--middle-center .header__heading {
    justify-self: center;
    text-align: center;
  }

  .header--top-center .header__heading-link,
  .header--top-center .header__heading {
    justify-self: center;
    text-align: center;
  }
}

/* Header icons */
.header__icons {
  display: flex;
  grid-area: icons;
  justify-self: end;
  padding-right: 0.8rem;
}

.header__icons .shopify-app-block {
  max-width: 4.4rem;
  max-height: 4.4rem;
  overflow: hidden;
}

.header__icon:not(.header__icon--summary),
.header__icon span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__icon {
  color: rgb(var(--color-foreground));
}

.cart-count-bubble>span {
  position: relative;
  top: -1px;
}

.header__icon span {
  height: 100%;
  padding: 1px;
}

.header__search {
  display: none;
}

strong {
  color: inherit;
  font-weight: 700 !important;
  font-family: 'arimobold';
}

.header__icon::after {
  content: none;
}

.header__icon:hover .icon,
.modal__close-button:hover .icon {
  transform: scale(1.07);
}

.header__icon .icon {
  height: 2rem;
  width: 2rem;
  fill: none;
  vertical-align: middle;
}

.header__icon,
.header__icon--cart .icon {
  height: 4.4rem;
  width: 4.4rem;
  padding: 0;
}

@media (max-width: 990px) {

  .header__icon,
  .header__icon--cart .icon {
    width: 3.5rem;
  }
}

.header__icon--cart {
  position: relative;
  margin-right: -1.2rem;
}

.header__icon--menu[aria-expanded='true']::before {
  content: '';
  top: 100%;
  left: 0;
  height: calc(var(--viewport-height, 100vh) - (var(--header-bottom-position, 100%)));
  width: 100%;
  display: block;
  position: absolute;
  background: rgba(var(--color-foreground), 0.5);
}

/* Search */
menu-drawer+.header__search {
  display: none;
}

.header>.header__search {
  grid-area: left-icons;
  justify-self: start;
}

.header--top-center.drawer-menu>.header__search {
  margin-left: 3.2rem;
}

.header--top-center header-drawer {
  grid-area: left-icons;
}

.header:not(.header--has-menu) *>.header__search {
  display: none;
}

.header__search {
  display: inline-flex;
  line-height: 0;
}

.header--top-center>.header__search {
  display: none;
}

.header--top-center *>.header__search {
  display: inline-flex;
}

@media screen and (min-width: 990px) {

  .header:not(.header--top-center) *>.header__search,
  .header--top-center>.header__search {
    display: inline-flex;
  }

  .header:not(.header--top-center)>.header__search,
  .header--top-center *>.header__search {
    display: none;
  }
}

.no-js .predictive-search {
  display: none;
}

details[open]>.search-modal {
  opacity: 1;
  animation: animateMenuOpen var(--duration-default) ease;
}

details[open] .modal-overlay {
  display: block;
}

details[open] .modal-overlay::after {
  position: absolute;
  content: '';
  background-color: rgb(var(--color-foreground), 0.5);
  top: 100%;
  left: 0;
  right: 0;
  height: 100vh;
}

.no-js details[open]>.header__icon--search {
  top: 1rem;
  right: 0.5rem;
}

.search-modal {
  opacity: 0;
  border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.08);
  min-height: calc(100% + var(--inputs-margin-offset) + (2 * var(--inputs-border-width)));
  height: 100%;
}

.search-modal__content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 5rem 0 1rem;
  line-height: calc(1 + 0.8 / var(--font-body-scale));
  position: relative;
}

.search-modal__content-bottom {
  bottom: calc((var(--inputs-margin-offset) / 2));
}

.search-modal__content-top {
  top: calc((var(--inputs-margin-offset) / 2));
}

.search-modal__form {
  width: 100%;
}

.search-modal__close-button {
  position: absolute;
  right: 0.3rem;
}

@media screen and (min-width: 750px) {
  .search-modal__close-button {
    right: 1rem;
  }

  .search-modal__content {
    padding: 0 6rem;
  }
}

@media screen and (min-width: 990px) {
  .search-modal__form {
    max-width: 74.2rem;
  }

  .search-modal__close-button {
    position: initial;
    margin-left: 0.5rem;
  }
}

/* Header menu drawer */
.header__icon--menu .icon {
  display: block;
  position: absolute;
  opacity: 1;
  transform: scale(1);
  transition: transform 150ms ease, opacity 150ms ease;
}

details:not([open])>.header__icon--menu .icon-close,
details[open]>.header__icon--menu .icon-hamburger {
  visibility: hidden;
  opacity: 0;
  transform: scale(0.8);
}

.js details[open]:not(.menu-opening)>.header__icon--menu .icon-close {
  visibility: hidden;
}

.js details[open]:not(.menu-opening)>.header__icon--menu .icon-hamburger {
  visibility: visible;
  opacity: 1;
  transform: scale(1.07);
}

.js details>.header__submenu {
  opacity: 0;
  transform: translateY(-1.5rem);
}

details[open]>.header__submenu {
  animation: animateMenuOpen var(--duration-default) ease;
  animation-fill-mode: forwards;
  z-index: 1;
}

@media (prefers-reduced-motion) {
  details[open]>.header__submenu {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Header menu */
.header__inline-menu {
  margin-left: -1.2rem;
  grid-area: navigation;
  display: none;
}

.header--top-center .header__inline-menu,
.header--top-center .header__heading-link {
  margin-left: 0;
}

@media screen and (min-width: 990px) {
  .header__inline-menu {
    display: block;
  }

  .header--top-center .header__inline-menu {
    justify-self: center;
  }

  .header--top-center .header__inline-menu>.list-menu--inline {
    justify-content: center;
  }

  .header--middle-left .header__inline-menu {
    margin-left: 0;
  }
}

.header__menu {
  padding: 0 1rem;
}

.header__menu-item {
  padding: 1.2rem;
  text-decoration: none;
  color: rgba(var(--color-foreground), 0.75);
}

.header__menu-item:hover {
  color: rgb(var(--color-foreground));
}

.header__menu-item span {
  transition: text-decoration var(--duration-short) ease;
}

.header__menu-item:hover span {
  text-decoration: underline;
  text-underline-offset: 0.3rem;
  text-decoration-thickness: 1.5px;
}

details[open]>.header__menu-item {
  text-decoration: underline;
}

details[open]:hover>.header__menu-item {
  text-decoration-thickness: 0.2rem;
}

details[open]>.header__menu-item .icon-caret {
  transform: rotate(180deg);
}

.header__active-menu-item {
  color: #000;
  text-transform: uppercase;
  font-size: 16px;
  font-family: 'arimoregular';
  font-weight: 600;
}

.header__menu-item:hover .header__active-menu-item {
  text-decoration-thickness: 0.2rem;
}

.header__submenu {
  transition: opacity var(--duration-default) ease, transform var(--duration-default) ease;
}

.global-settings-popup,
.header__submenu.global-settings-popup {
  border-radius: var(--popup-corner-radius);
  border-color: rgba(var(--color-foreground), var(--popup-border-opacity));
  border-style: solid;
  border-width: var(--popup-border-width);
  box-shadow: var(--popup-shadow-horizontal-offset) var(--popup-shadow-vertical-offset) var(--popup-shadow-blur-radius) rgba(var(--color-shadow), var(--popup-shadow-opacity));
  z-index: -1;
}

.header__submenu.list-menu {
  padding: 1rem 0;
}

.header__submenu .header__submenu {
  background-color: rgba(var(--color-foreground), 0.03);
  padding: 1rem 0;
  margin: 1rem 0;
}

.header__submenu .header__menu-item:after {
  right: 2rem;
}

.header__submenu .header__menu-item {
  justify-content: space-between;
  padding: 0.8rem 2rem;
}

.header__submenu .header__menu-item:hover {
  text-decoration-line: underline;
}

.header__menu-item .icon-caret {
  right: 0.8rem;
}

.header__submenu .icon-caret {
  flex-shrink: 0;
  margin-left: 1rem;
  position: static;
}

header-menu>details,
details-disclosure>details {
  position: relative;
}

@keyframes animateMenuOpen {
  0% {
    opacity: 0;
    transform: translateY(-1.5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.overflow-hidden-mobile,
.overflow-hidden-tablet,
.overflow-hidden-desktop {
  overflow: hidden;
}

@media screen and (min-width: 750px) {
  .overflow-hidden-mobile {
    overflow: auto;
  }
}

@media screen and (min-width: 990px) {
  .overflow-hidden-tablet {
    overflow: auto;
  }
}

.badge {
  border: 1px solid transparent;
  border-radius: var(--badge-corner-radius);
  display: inline-block;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  line-height: 1;
  padding: 0.5rem 1.3rem 0.6rem 1.3rem;
  text-align: center;
  background-color: rgb(var(--color-badge-background));
  border-color: rgba(var(--color-badge-border), var(--alpha-badge-border));
  color: rgb(var(--color-badge-foreground));
  word-break: break-word;
}

.gradient {
  background: rgb(var(--color-background));
  background: var(--gradient-background);
  background-attachment: fixed;
}

@media screen and (forced-colors: active) {
  .icon {
    color: CanvasText;
    fill: CanvasText !important;
  }

  .icon-close-small path {
    stroke: CanvasText;
  }
}

.ratio {
  display: flex;
  position: relative;
  align-items: stretch;
}

.ratio::before {
  content: '';
  width: 0;
  height: 0;
  padding-bottom: var(--ratio-percent);
}

.content-container {
  border-radius: var(--text-boxes-radius);
  border: var(--text-boxes-border-width) solid rgba(var(--color-foreground), var(--text-boxes-border-opacity));
  position: relative;
}

.content-container:after {
  content: '';
  position: absolute;
  top: calc(var(--text-boxes-border-width) * -1);
  right: calc(var(--text-boxes-border-width) * -1);
  bottom: calc(var(--text-boxes-border-width) * -1);
  left: calc(var(--text-boxes-border-width) * -1);
  border-radius: var(--text-boxes-radius);
  box-shadow: var(--text-boxes-shadow-horizontal-offset) var(--text-boxes-shadow-vertical-offset) var(--text-boxes-shadow-blur-radius) rgba(var(--color-shadow), var(--text-boxes-shadow-opacity));
  z-index: -1;
}

.content-container--full-width:after {
  left: 0;
  right: 0;
  border-radius: 0;
}

@media screen and (max-width: 749px) {
  .content-container--full-width-mobile {
    border-left: none;
    border-right: none;
    border-radius: 0;
  }

  .content-container--full-width-mobile:after {
    display: none;
  }
}

.global-media-settings {
  position: relative;
  border: var(--media-border-width) solid rgba(var(--color-foreground), var(--media-border-opacity));
  border-radius: var(--media-radius);
  overflow: visible !important;
  background-color: rgb(var(--color-background));
}

.global-media-settings:after {
  content: '';
  position: absolute;
  top: calc(var(--media-border-width) * -1);
  right: calc(var(--media-border-width) * -1);
  bottom: calc(var(--media-border-width) * -1);
  left: calc(var(--media-border-width) * -1);
  border-radius: var(--media-radius);
  box-shadow: var(--media-shadow-horizontal-offset) var(--media-shadow-vertical-offset) var(--media-shadow-blur-radius) rgba(var(--color-shadow), var(--media-shadow-opacity));
  z-index: -1;
  pointer-events: none;
}

.global-media-settings--no-shadow {
  overflow: hidden !important;
}

.global-media-settings--no-shadow:after {
  content: none;
}

.global-media-settings img,
.global-media-settings iframe,
.global-media-settings model-viewer,
.global-media-settings video,
.global-media-settings .placeholder-svg {
  border-radius: calc(var(--media-radius) - var(--media-border-width));
}

.content-container--full-width,
.global-media-settings--full-width,
.global-media-settings--full-width img,
.global-media-settings--full-width video,
.global-media-settings--full-width iframe,
.global-media-settings--full-width .placeholder-svg {
  border-radius: 0;
  border-left: none;
  border-right: none;
}

/* check for flexbox gap in older Safari versions */
@supports not (inset: 10px) {
  .grid {
    margin-left: calc(-1 * var(--grid-mobile-horizontal-spacing));
  }

  .grid__item {
    padding-left: var(--grid-mobile-horizontal-spacing);
    padding-bottom: var(--grid-mobile-vertical-spacing);
  }

  @media screen and (min-width: 750px) {
    .grid {
      margin-left: calc(-1 * var(--grid-desktop-horizontal-spacing));
    }

    .grid__item {
      padding-left: var(--grid-desktop-horizontal-spacing);
      padding-bottom: var(--grid-desktop-vertical-spacing);
    }
  }

  .grid--gapless .grid__item {
    padding-left: 0;
    padding-bottom: 0;
  }

  @media screen and (min-width: 749px) {
    .grid--peek .grid__item {
      padding-left: var(--grid-mobile-horizontal-spacing);
    }
  }

  .product-grid .grid__item {
    padding-bottom: var(--grid-mobile-vertical-spacing);
  }

  @media screen and (min-width: 750px) {
    .product-grid .grid__item {
      padding-bottom: var(--grid-desktop-vertical-spacing);
    }
  }
}

.font-body-bold {
  font-weight: var(--font-body-weight-bold);
}

/* outline and border styling for Windows High Contrast Mode */
@media (forced-colors: active) {

  .button,
  .shopify-challenge__button,
  .customer button {
    border: transparent solid 1px;
  }

  .button:focus-visible,
  .button:focus,
  .button.focused,
  .shopify-payment-button__button--unbranded:focus-visible,
  .shopify-payment-button [role='button']:focus-visible,
  .shopify-payment-button__button--unbranded:focus,
  .shopify-payment-button [role='button']:focus {
    outline: solid transparent 1px;
  }

  .field__input:focus,
  .select__select:focus,
  .customer .field input:focus,
  .customer select:focus,
  .localization-form__select:focus.localization-form__select:after {
    outline: transparent solid 1px;
  }

  .localization-form__select:focus {
    outline: transparent solid 1px;
  }
}

.rte:after {
  clear: both;
  content: '';
  display: block;
}

.rte>*:first-child {
  margin-top: 0;
  font-weight: 100;
}

.rte>*:last-child {
  margin-bottom: 0;
  line-height: 20px;
  font-size: 15px;
  letter-spacing: 1px !important;
  font-weight: 100;
}

.rte table {
  table-layout: fixed;
}

@media screen and (min-width: 750px) {
  .rte table td {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}

.rte img {
  height: auto;
  max-width: 100%;
  border: var(--media-border-width) solid rgba(var(--color-foreground), var(--media-border-opacity));
  border-radius: var(--media-radius);
  box-shadow: var(--media-shadow-horizontal-offset) var(--media-shadow-vertical-offset) var(--media-shadow-blur-radius) rgba(var(--color-shadow), var(--media-shadow-opacity));
  margin-bottom: var(--media-shadow-vertical-offset);
}

.rte ul,
.rte ol {
  list-style-position: outside;
  padding-left: 2rem;
}

.rte li {
  list-style: disc;
}

.rte li:last-child {
  margin-bottom: 0;
}

.rte a {
  color: rgba(var(--color-link), var(--alpha-link));
  text-underline-offset: 0.3rem;
  text-decoration-thickness: 0.1rem;
  transition: text-decoration-thickness var(--duration-short) ease;
}

.rte a:hover {
  color: rgb(var(--color-link));
  text-decoration-thickness: 0.2rem;
}

.rte blockquote {
  display: inline-flex;
}

.rte blockquote>* {
  margin: -0.5rem 0 -0.5rem 0;
}

/* Image mask global styles */

.shape--mask {
  display: block;
  height: 0;
  width: 0;
}

.shape--arch {
  clip-path: url('#Shape-Arch');
}

.shape--blob {
  clip-path: polygon(var(--shape--blob-1));
}

.shape--chevronleft {
  clip-path: polygon(100% 0%, 85% 50%, 100% 100%, 15% 100%, 0% 50%, 15% 0%);
}

.shape--chevronright {
  clip-path: polygon(85% 0%, 100% 50%, 85% 100%, 0% 100%, 15% 50%, 0% 0%);
}

.shape--circle {
  clip-path: circle(closest-side);
}

.shape--diamond {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.shape--parallelogram {
  clip-path: polygon(15% 0, 100% 0%, 85% 100%, 0% 100%);
}

.shape--round {
  clip-path: ellipse(45% 45% at 50% 50%);
}

/* Fixed background */
.animate--fixed {
  clip-path: inset(0);
}

.animate--fixed>img:not(.zoom):not(.deferred-media__poster-button),
.animate--fixed>svg:not(.zoom):not(.deferred-media__poster-button) {
  position: fixed;
  height: 100vh;
}

/* Zoom in image on scroll */
.animate--zoom-in {
  --zoom-in-ratio: 1;
}

.animate--zoom-in>img,
.animate--zoom-in>svg {
  transition: scale var(--duration-short) linear;
  scale: var(--zoom-in-ratio);
}

/* Animations */

@media (prefers-reduced-motion: no-preference) {

  .animate--ambient>img,
  .animate--ambient>svg {
    animation: animateAmbient 30s linear infinite;
  }

  @keyframes animateAmbient {
    0% {
      transform: rotate(0deg) translateX(1em) rotate(0deg) scale(1.2);
    }

    100% {
      transform: rotate(360deg) translateX(1em) rotate(-360deg) scale(1.2);
    }
  }

  .scroll-trigger.animate--fade-in,
  .scroll-trigger.animate--slide-in {
    opacity: 0.01;
  }

  .scroll-trigger.animate--slide-in {
    transform: translateY(2rem);
  }

  .scroll-trigger:not(.scroll-trigger--offscreen).animate--fade-in {
    opacity: 1;
    animation: var(--animation-fade-in);
  }

  .scroll-trigger:not(.scroll-trigger--offscreen).animate--slide-in {
    animation: var(--animation-slide-in);
    animation-delay: calc(var(--animation-order) * 75ms);
  }

  .scroll-trigger.scroll-trigger--design-mode.animate--fade-in,
  .scroll-trigger.scroll-trigger--design-mode.animate--slide-in,
  .scroll-trigger.scroll-trigger--design-mode .slider,
  .scroll-trigger:not(.scroll-trigger--offscreen).scroll-trigger--cancel {
    opacity: 1;
    animation: none;
    transition: none;
  }

  .scroll-trigger.scroll-trigger--design-mode.animate--slide-in {
    transform: translateY(0);
  }

  @keyframes slideIn {
    from {
      transform: translateY(2rem);
      opacity: 0.01;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0.01;
    }

    to {
      opacity: 1;
    }
  }
}

/* Element Hovers: Vertical Lift, 3d Lift */

@media (prefers-reduced-motion: no-preference) and (hover: hover) {

  .animate--hover-3d-lift .card-wrapper .card--card,
  .animate--hover-3d-lift .card-wrapper .card--standard .card__inner,
  .animate--hover-3d-lift .button:not(.button--tertiary),
  .animate--hover-3d-lift .shopify-challenge__button,
  .animate--hover-3d-lift .customer button,
  .animate--hover-3d-lift .shopify-payment-button__button,
  .animate--hover-3d-lift .deferred-media .deferred-media__poster-button {
    transition: transform var(--duration-long) ease, box-shadow var(--duration-long) ease;
    transform-origin: center;
  }

  .animate--hover-3d-lift .card-wrapper:hover .card--card,
  .animate--hover-3d-lift .card-wrapper:hover .card--standard .card__inner,
  .animate--hover-3d-lift .button:not(.button--tertiary):not([disabled]):hover,
  .animate--hover-3d-lift .shopify-challenge__button:not([disabled]):hover,
  .animate--hover-3d-lift .customer button:not([disabled]):hover,
  .animate--hover-3d-lift .shopify-payment-button__button:hover,
  .animate--hover-3d-lift .deferred-media:hover .deferred-media__poster-button {
    transition: transform var(--duration-extended) ease, box-shadow var(--duration-long) ease;
    /* Slow the card transition speed while hover is active. */
    transform: rotate(1deg);
    box-shadow: -1rem -1rem 1rem -1rem rgba(0, 0, 0, 0.05), 1rem 1rem 1rem -1rem rgba(0, 0, 0, 0.05),
      0 0 0.5rem 0 rgba(255, 255, 255, 0), 0 2rem 3.5rem -2rem rgba(0, 0, 0, 0.5);
  }

  .animate--hover-3d-lift .deferred-media:hover .deferred-media__poster-button {
    transform: translate(-50%, -50%) scale(1.05);
    /* Apply a specialized transform to the video play button. */
  }

  .animate--hover-3d-lift .collage__item .card-wrapper:hover .card--card,
  .animate--hover-3d-lift .collage__item .card-wrapper:hover .card--standard .card__inner {
    transform: rotate(0.5deg);
    /* Less intense rotation for collage items. */
  }

  .animate--hover-3d-lift .product-grid .grid__item:hover,
  .animate--hover-3d-lift .collection-list .grid__item:hover,
  .animate--hover-3d-lift .collage__item:hover,
  .animate--hover-3d-lift .blog-articles .article:hover,
  .animate--hover-3d-lift .complementary-slide li:hover {
    z-index: 2;
    /* Make sure the hovered card is the topmost card. */
  }

  .animate--hover-3d-lift .product-grid {
    isolation: isolate;
    /* Ensure z-index changes here don't negatively effect other UI stacking. */
  }

  .animate--hover-3d-lift .card-wrapper .card--shape.card--standard:not(.card--text) .card__inner {
    box-shadow: none;
    transition: transform var(--duration-long) ease, filter var(--duration-long) ease;
  }

  .animate--hover-3d-lift .card-wrapper:hover .card--shape.card--standard:not(.card--text) .card__inner {
    transition: transform calc(2 * var(--duration-extended)) ease, filter var(--duration-long) ease;
    /* Slow the card transition speed while hover is active. */
    filter: drop-shadow(0rem 2rem 2rem rgba(0, 0, 0, 0.15)) drop-shadow(0rem 1rem 1rem rgba(0, 0, 0, 0.15));
  }

  .animate--hover-3d-lift .card-wrapper:hover .card--card:after,
  .animate--hover-3d-lift .card-wrapper:hover .card--standard .card__inner:after,
  .animate--hover-3d-lift .card-wrapper:hover .card--shape.card--standard:not(.card--text) .card__inner .card__media:before {
    background-image: var(--easter-egg);
    background-size: 250px 250px;
    mix-blend-mode: color-dodge;
    pointer-events: none;
    z-index: 2;
  }

  .animate--hover-3d-lift .card-wrapper:hover .card--shape.card--standard:not(.card--text) .card__inner .card__media:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .animate--hover-3d-lift .card-wrapper .card--card:before,
  .animate--hover-3d-lift .card-wrapper .card--standard .card__media:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: background-position calc(2 * var(--duration-extended)) ease, opacity var(--duration-default) ease;
    mix-blend-mode: overlay;
    background-size: 400% 100%;
    background-position: 90% 0;
    background-repeat: no-repeat;
    background-image: linear-gradient(135deg,
        rgba(255, 255, 255, 0) 45%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 55%);
  }

  .animate--hover-3d-lift .card-wrapper:hover .card--card:before,
  .animate--hover-3d-lift .card-wrapper:hover .card--standard .card__media:after {
    opacity: 0.2;
    transition: background-position 6s ease, opacity var(--duration-long) ease;
    background-position: left;
    z-index: 2;
  }

  :root {
    --easter-egg: none;
    --sparkle: url('./sparkle.gif');
  }

  .animate--hover-vertical-lift .button:not(.button--tertiary),
  .animate--hover-vertical-lift .shopify-challenge__button,
  .animate--hover-vertical-lift .customer button,
  .animate--hover-vertical-lift .shopify-payment-button__button {
    transition: transform var(--duration-default) var(--ease-out-slow);
  }

  .animate--hover-vertical-lift .card-wrapper .card--card,
  .animate--hover-vertical-lift .card-wrapper .card--standard .card__inner {
    transition: transform var(--duration-medium) var(--ease-out-slow);
  }

  .animate--hover-vertical-lift .card-wrapper:hover .card--card,
  .animate--hover-vertical-lift .card-wrapper:hover .card--standard .card__inner {
    transform: translateY(-0.75rem);
  }

  .animate--hover-vertical-lift .card-wrapper:active .card--card,
  .animate--hover-vertical-lift .card-wrapper:active .card--standard .card__inner {
    transform: translateY(-0.5rem);
  }

  .animate--hover-vertical-lift .button:not(.button--tertiary):not([disabled]):hover,
  .animate--hover-vertical-lift .shopify-challenge__button:not([disabled]):hover,
  .animate--hover-vertical-lift .customer button:not([disabled]):hover,
  .animate--hover-vertical-lift .shopify-payment-button__button:hover {
    transform: translateY(-0.25rem);
  }

  .animate--hover-vertical-lift .button:not(.button--tertiary):not([disabled]):active,
  .animate--hover-vertical-lift .shopify-challenge__button:not([disabled]):active,
  .animate--hover-vertical-lift .customer button:not([disabled]):active,
  .animate--hover-vertical-lift .shopify-payment-button__button:active {
    transform: translateY(0);
  }

  .animate--hover-vertical-lift .button:not([disabled]):hover:after,
  .animate--hover-vertical-lift .customer button:not([disabled]):hover:after,
  .animate--hover-vertical-lift .shopify-payment-button__button:not([disabled]):hover:after {
    --border-offset: 0.3px;
    /* Default is 1.3px as defined above in this file. This removes 1px to prevent the border from growing on buttons when this effect is on.  */
    box-shadow: 0 0 0 calc(var(--buttons-border-width) + var(--border-offset)) rgba(var(--color-button-text), var(--border-opacity)),
      0 0 0 var(--buttons-border-width) rgba(var(--color-button), var(--alpha-button-background));
  }

  .animate--hover-vertical-lift .button:not([disabled]).button--secondary:hover:after {
    --border-offset: 0px;
    /* Prevent the border from growing on buttons when this effect is on. */
  }
}

/* header ul li  */

ul.list-menu.list-menu--inline>li>a {
  color: #000;
  text-transform: uppercase;
  font-size: 16px;
  font-family: 'arimoregular';
  font-weight: 600;
}

h2.h1.mega-title--large {
  font-size: 59px;
  letter-spacing: 6px;
  line-height: 70px;
  font-weight: 700;
  margin: 30px 0;
  color: #ffff;
}

h2.banner__heading.inline-richtext.h0,
h2.banner__heading.inline-richtext.h1 {
  font-family: Baskerville;
  font-style: italic;
  font-weight: 400;
  color: #ffff;
  filter: drop-shadow(-10.392px 6px 125px rgba(0, 0, 0, 0.93));
}

p.banner__heading.inline-richtext.h0,
p.banner__heading.inline-richtext.h1 {
  font-family: Baskerville;
  font-style: italic;
  font-weight: 400;
  color: #ffff;
  filter: drop-shadow(-10.392px 6px 125px rgba(0, 0, 0, 0.93));
  font-size: 34px;
}

.banner__box.content-container.collage__banner__content {
  text-align: left;
}

.banner__media__mobile {
  display: none;
}



.page-width {
  padding: 0 5;
}

.collection__title.title-wrapper.title-wrapper--no-top-margin.page-width.title-wrapper--self-padded-tablet-down.collection__title--desktop-slider {
  display: flex;
  justify-content: space-between;
}

.header__icon.header__icon--account img {
  width: 15px;
  height: auto;
}

.header__icon--cart img {
  width: 15px;
  height: auto;
}

.mega-menu__link--level-2 {
  pointer-events: none;
  text-decoration: none;
  margin-bottom: 16px;
  color: #000;
}

.menu ul.main-menu>li ul.dropdownmenu>li>ul>li:first-child {
  margin-bottom: 16px;
  padding-bottom: 0;
}

.mega-menu__link--level-2.hide {
  display: none;
}

.header__menu-item span {
  color: #000;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-family: 'arimoregular';
  font-weight: 600;
}

.header__menu-item span a {
  color: #000;
  text-decoration: none;
}

.mega-menu__link {
  font-weight: 500;
}

.megaMenu_image {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 768px) {

  .header:not(.header--top-center) *>.header__search,
  .header--top-center>.header__search {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .search-modal__form.desktop__search {
    display: none;
  }
}

.collection__view-all .button {
  /* color: red; */
  border: #000 2px solid;
}

.collection__title {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  padding: 0 65px;
}

.field__input.price_field__input {
  width: 5rem;
}

.cart__warnings .button {
  background: #111;
  color: #ffff;
}

.menu-drawer__menu-item.hide {
  display: none;
}

@media only screen and (max-width: 767px) {
  .collection__title {
    flex-direction: column;
  }

  h2.banner__heading.inline-richtext.h0,
  h2.banner__heading.inline-richtext.h1 {
    font-size: 20px;
    text-align: left;
  }

  h2.h1.mega-title--large {
    font-size: 27px;
    margin: 0;
    line-height: 52px;
    letter-spacing: 2px;
  }

  .banner__buttons .button {
    margin: 0 auto !important;
    font-size: 14px;
    height: 35px !important;
    width: 150px !important;
  }

  .banner__box {
    bottom: 5rem;
  }

  .megaMenu_image {
    width: auto;
    height: auto;
  }
}

#addToCartBtn.sticky {
  position: fixed;
  /* Change to fixed positioning on scroll */
  display: block;
  /* Show it on scroll */
}

.arrow_icon {
  width: 35px;
  height: auto;
  display: block;
}

.card__information .yotpo .text-m {
  display: none;
}

.banner__content.banner__content--top-left.page-width.scroll-trigger.animate--slide-in {
  position: absolute;
  top: 0;
}

.header__icon.contact-icon img {
  width: 18px;
  height: auto;
}

div#Banner-template--15095771136066__image_banner_c_0 {
  margin: 0 50px;
}

@media only screen and (max-width: 768px) {
  div#Banner-template--15095771136066__image_banner_c_0 {
    margin: 0 20px;
  }

  .flex {
    display: flex;
    flex-direction: column;
  }
}

button#addToCartBtn:hover,
button#sticky-add-to-cart-btn:hover {
  background: #666;
}

button#addToCartBtn.clicked,
button#sticky-add-to-cart-btn.clicked {
  background: black;
}

button#addToCartBtn,
button#sticky-add-to-cart-btn {
  color: white;
  background: black;
  border: none;
  font-size: 17px;
  letter-spacing: 2px !important;
  font-family: arimoregular;
  font-weight: 100;
}

@media only screen and (max-width: 480px) {

  button#addToCartBtn,
  button#sticky-add-to-cart-btn {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1240px) {

  .sticy_bar_right button#addToCartBtn,
  button#sticky-add-to-cart-btn {
    width: 100%;
  }
}

button#addToCartBtn.hide,
button#sticky-add-to-cart-btn.hide {
  display: none;
}

form#add-to-cart-form.hide {
  display: none;
}

.flex {
  display: flex;
  justify-content: space-between;
}

/* One trust start */
.custom-onetrust-banner {
  position: fixed;
  z-index: 2147483645;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  max-height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.2);
  font-size: 16px;
}

.custom-onetrust-banner .ot-sdk-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.custom-onetrust-banner #onetrust-policy-title {
  line-height: 1.3;
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: inherit;
  color: #696969;
  font-weight: 600;
  font-size: 16px;
  font-style: italic;
}

.custom-onetrust-banner #onetrust-policy-text {
  font-size: 0.813em;
  line-height: 1.5;
  color: #696969;
  text-align: left;
  font-style: italic;
}

.custom-onetrust-banner #onetrust-policy-text a {
  color: #3860be;
  font-weight: bold;
  font-size: inherit;
  line-height: inherit;
  text-decoration: underline;
}

.custom-onetrust-banner .ot-sdk-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-onetrust-banner #onetrust-accept-btn-handler {
  padding: 12px 10px;
  line-height: 1.2;
  font-size: 0.813em;
  font-weight: 600;
  min-width: 125px;
  font-family: inherit;
  background-color: #000000;
  border-color: #000000;
  color: #ffffff;
  border: 0;
}

.custom-onetrust-banner #onetrust-button-group-parent {
  margin-left: 50px;
}

.open-cookie-popups,
.open-cookie-popup {
  display: flex;
  align-items: center;
}

.open-cookie-popups img {
  max-width: 35px;
  margin-right: 8px;
}

.open-cookie-popup {
  font-size: 16px;
  justify-content: center;
  max-width: 250px;
  margin: 0 auto;
  color: #000 !important;
}

.open-cookie-popup img {
  max-width: 50px;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .ot-sdk-row {
    flex-direction: column;
  }

  .custom-onetrust-banner #onetrust-button-group-parent {
    margin: 10px 0 0 0;
    width: 100%;
  }

  .custom-onetrust-banner .ot-sdk-container {
    padding: 10px 15px;
  }

  .custom-onetrust-banner #onetrust-accept-btn-handler {
    min-width: unset;
    width: 100%;
  }
}

/* One trust end */
.announcement-bar-slider.slider-buttons {
  bottom: 0;
}

.afterpay-paragraph {
  text-align: right;
}

/* rebooted start  */
.all_rebooted_items_wrpr.is_default_product {
  margin-top: 10px;
}

.all_rebooted_items_wrpr h4 {
  font-family: acumin-pro-wide;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.all_rebooted_items_wrpr .rebooted_options {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  border: 1px solid #000;
}

@media only screen and (min-width: 992px) {
  .all_rebooted_items_wrpr .rebooted_options {
    max-height: 550px;
  }
}

@media only screen and (max-width: 991px) {
  .all_rebooted_items_wrpr .rebooted_options {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.all_rebooted_items_wrpr .rebooted_options .rebooted_grid {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 10px;
}

@media only screen and (max-width: 991px) {
  .all_rebooted_items_wrpr .rebooted_options .rebooted_grid {
    flex: 1 0 auto;
    margin: 0;
    flex-wrap: wrap;
    flex-direction: column;
    margin-right: 10px;
    width: 250px;
  }
}

.all_rebooted_items_wrpr .rebooted_options .rebooted_grid:last-child {
  margin: 0;
}

.all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr {
  flex: 0 0 125px;
  max-width: 125px;
}

@media only screen and (max-width: 991px) {
  .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr {
    flex: auto;
    width: 100%;
    max-width: initial;
  }
}

.all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr a {
  display: block;
  background: #fafafa;
  position: relative;
  padding-bottom: 125%;
  overflow: hidden;
}

.all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr img {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  display: block;
  mix-blend-mode: multiply;
}

.all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedcnt_wrpr {
  flex: 0 0 calc(100% - 125px);
  max-width: calc(100% - 125px);
  padding: 5px 10px;
}

@media only screen and (max-width: 991px) {
  .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedcnt_wrpr {
    flex: auto;
    max-width: 100%;
    padding: 5px 10px;
    width: 100%;
    padding: 10px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedcnt_wrpr p {
  margin-bottom: 6px;
}

.all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedcnt_wrpr p b {
  font-family: acumin-pro-wide;
  text-transform: uppercase;
}

.all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebooted_atc_btn {
  border-radius: 50px;
  margin: 8px 0 0 0;
  background: transparent;
  color: #000;
  border: 1px solid #000;
  text-transform: capitalize;
  font-family: acumin-pro-wide;
}

.all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebooted_atc_btn:hover {
  color: #000 !important;
  background: white !important;
  border: 1px solid black !important;
}

.all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebooted_atc_btn {
  background: #000;
  border-radius: 0px;
  color: #ffff;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px;
  max-width: 150px;
  width: 100%;
  font-family: 'arimoregular', sans-serif !important;
}

.all_rebooted_items_wrpr .drop {
  width: 100%;
  border-radius: 60px;
  font-size: 16px;
  line-height: 1;
  padding: 5px 24px 5px 20px;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.15);
  position: relative;
  height: 40px;
  border: 0;
  line-height: 30px;
  display: block;
  position: relative;
  background-color: #fff;
}

.all_rebooted_items_wrpr .drop .sub_val {
  display: none;
}

.all_rebooted_items_wrpr .drop:after {
  content: '';
  position: absolute;
  background: url(https://cdn.shopify.com/s/files/1/2170/8465/files/down-arrow.svg?v=1613739910) no-repeat;
  background-size: 15px;
  background-position: center;
  width: 15px;
  height: 10px;
  right: 20px;
  top: 50%;
  transform: translate(0px, -50%);
  transition: all 0.2s ease-in-out;
}

.all_rebooted_items_wrpr .down .drop:after {
  transform: translate(0px, -50%) scale(-1);
}

.all_rebooted_items_wrpr #rebooted_size_filter,
.all_rebooted_items_wrpr #rebooted_condition_filter {
  position: absolute;
  list-style: none;
  background: #fff;
  padding: 15px;
  bottom: 110%;
  width: 100%;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  z-index: 9;
  max-height: 480px;
  overflow: auto;
  text-align: center;
  left: 0;
  max-height: 300px;
}

@media (max-width: 991px) {

  .all_rebooted_items_wrpr #rebooted_size_filter,
  .all_rebooted_items_wrpr #rebooted_condition_filter {
    width: calc(100% - 30px);
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.all_rebooted_items_wrpr #rebooted_size_filter li,
.all_rebooted_items_wrpr #rebooted_condition_filter li {
  font-size: 16px;
  border-bottom: 1px solid #ddd;
}

.all_rebooted_items_wrpr #rebooted_size_filter li a,
.all_rebooted_items_wrpr #rebooted_condition_filter li a {
  padding: 12px 0;
  display: block;
  width: 100%;
}

.all_rebooted_items_wrpr #rebooted_size_filter li:first-child a,
.all_rebooted_items_wrpr #rebooted_condition_filter li:first-child a {
  padding-top: 0px;
}

.all_rebooted_items_wrpr #rebooted_size_filter li:last-child,
.all_rebooted_items_wrpr #rebooted_condition_filter li:last-child {
  border-bottom: none;
}

.all_rebooted_items_wrpr #rebooted_size_filter li:last-child a,
.all_rebooted_items_wrpr #rebooted_condition_filter li:last-child a {
  padding-bottom: 0px;
}

.all_rebooted_items_wrpr #rebooted_size_filter li .with_blur .main_val,
.all_rebooted_items_wrpr #rebooted_condition_filter li .with_blur .main_val {
  color: #828282;
}

.all_rebooted_items_wrpr #rebooted_size_filter li .with_blur .sub_val span,
.all_rebooted_items_wrpr #rebooted_condition_filter li .with_blur .sub_val span {
  color: #828282;
}

.all_rebooted_items_wrpr #rebooted_size_filter li .with_blur .sub_val u,
.all_rebooted_items_wrpr #rebooted_condition_filter li .with_blur .sub_val u {
  text-transform: uppercase;
  color: #000;
}

.all_rebooted_items_wrpr a {
  text-transform: capitalize;
}

.rebooted_swatch_wrpr .select_color {
  padding: 0;
}

.rebooted_swatch_wrpr .select_color .header {
  display: none;
}

.re_booted_paragraph {
  margin: 30px 0 15px;
}

.re_booted_paragraph .re_booted_heading {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 18px;
  margin: 0 0 3px;
}

.re_booted_paragraph .re_booted_content {
  font-size: 16px;
}

.re_booted_paragraph .re_booted_content a {
  text-decoration: underline;
}

.all_conditions {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.all_conditions .condition_tag {
  padding: 7px 15px;
  border: 1px solid #000;
  border-radius: 50px;
  margin: 10px 10px 0 0;
  display: block;
  width: max-content;
  text-align: center;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.15);
}

.used_pdp.is_normal_pdp .singleProduct .cm_price_mob * {
  color: #000;
  font-weight: 900;
}

@media (min-width: 992px) {
  .used_pdp.is_normal_pdp .singleProduct .cm_price_mob * {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .used_pdp .cm_color_av {
    padding: 10px 0 5px;
  }
}

.used_pdp .cm_color_av h3 {
  color: #000;
  font-weight: normal;
  font-size: 16px;
  text-transform: capitalize;
}

.used_pdp .cm_color_av span {
  font-weight: bold;
  text-transform: uppercase;
}

@media (max-width: 991px) {
  .used_pdp .cm_color_av h3 {
    font-size: 16px;
  }
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options {
  border: 0px;
  border-top: 1px solid #ddd;
}

@media (max-width: 1080px) {
  .used_pdp .all_rebooted_items_wrpr .rebooted_options {
    padding: 15px 0 0;
  }
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid {
  margin: 0 0 30px;
  padding: 0 0 30px;
  border-bottom: 1px solid #ddd;
}

@media (max-width: 1080px) {
  .used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid {
    margin: 0 0 15px;
    padding: 0 0 15px;
  }
}

@media (max-width: 991px) {
  .used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid {
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
  }
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr {
  flex: auto;
  max-width: 170px;
  width: 100%;
  padding: 0 20px;
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr button.slick-prev,
.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr button.slick-next {
  background-image: url('https://cdn.shopify.com/s/files/1/0037/3807/5202/files/slick-b-right.png?v=1643345685');
  background-repeat: no-repeat;
  background-position: center;
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr button.slick-prev:before,
.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr button.slick-next:before {
  content: '';
  color: transparent;
}

.rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-dots li button:before {
  font-size: 12px;
}

. @media (max-width: 1080px) {
  .used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr {
    max-width: 160px;
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr {
    max-width: 140px;
    width: 100%;
  }

  .used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr a {
    padding-bottom: 120px;
  }
}

@media (max-width: 991px) {
  .used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr {
    padding: 0;
  }

  .used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr .slick-dots {
    position: static;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 10px;
  }
}

@media (max-width: 640px) {
  .used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr {
    max-width: 120px;
  }
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr a {
  display: block;
  background: #fafafa;
  position: relative;
  padding-bottom: 140px;
  overflow: hidden;
  /* @media (max-width: 991px){
  padding-bottom: 13%;
}
  */
}

@media (max-width: 1080px) {
  .used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr a {
    padding-bottom: 140px;
  }
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr a img {
  position: absolute;
  left: 0;
  bottom: auto;
  transform: none;
  display: block;
  mix-blend-mode: multiply;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr .slick-arrow {
  top: 50%;
  transform: translateY(-50%);
  background-size: 10px;
  width: 15px;
  height: 17px;
  background-position: center;
  background-size: 10px;
  padding: 0;
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr .slick-arrow.slick-prev {
  left: 0 !important;
  transform: translateY(-50%) rotate(180deg);
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr .slick-arrow.slick-next {
  right: 0 !important;
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedcnt_wrpr {
  flex: auto;
  max-width: initial;
  padding-left: 20px;
  width: auto;
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedcnt_wrpr span {
  font-size: 16px !important;
  color: #000;
}

@media (max-width: 1080px) {
  .used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedcnt_wrpr {
    padding-left: 0;
  }
}

@media (max-width: 1080px) {
  .used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedcnt_wrpr p {
    margin: 0 0 3px;
    font-size: 14px;
    line-height: 1;
  }
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedcnt_wrpr p b {
  letter-spacing: 1px;
  font-size: 18px;
}

@media (max-width: 1080px) {
  .used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedcnt_wrpr p b {
    font-size: 16px;
  }
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedcnt_wrpr button,
.rebooted_popup .add_to_cart {
  background: #000;
  border-radius: 0;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px;
  max-width: 200px;
  width: 100%;
  font-family: arimoregular, sans-serif !important;
  cursor: pointer;
  margin: 12px 0;
  text-wrap: nowrap;
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedcnt_wrpr button:hover,
.rebooted_popup .add_to_cart:hover {
  background: #fff;
  color: #000;
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedcnt_wrpr .button:disabled,
.rebooted_popup .add_to_cart:disabled {
  cursor: not-allowed;
  opacity: .5;
  background: #000;
  color: #fff;
}

@media (max-width: 767px) {
  .rebooted-cart-button {
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media (max-width: 1080px) {
  .used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedcnt_wrpr button {
    font-size: 14px;
    margin: 10px 0 0;
  }
}

.used_pdp .rebooted_filter {
  margin: 30px 0;
}

.used_pdp .rebooted_popup_wrpr {
  border-radius: 5px;
}

@media (max-width: 991px) {
  .used_pdp .rebooted_popup_wrpr {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar {
    flex: 1 0 0;
  }

  .used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-dotted.slick-slider {
    margin-bottom: 0;
  }

  .used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-dots li button {
    background: transparent;
    padding: 0;
    text-indent: -100000px;
    border: 0;
    height: 0;
    width: 0;
  }

  .used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-dots li {
    display: inline-block;
    height: 15px;
    margin: 0 5px;
    width: 15px;
    border: 2px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
  }

  .used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-dots li.slick-active {
    background: #ccc;
    background-color: #ccc;
  }
}

.used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-dots {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

@media (max-width: 767px) {
  .used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-dots {
    position: absolute;
    margin: 20px 0 0;
    bottom: -25px;
  }
}

.rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-slide {
  background-color: transparent;
}

.used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-slide video,
.used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-slide img {
  height: 100%;
  object-fit: cover;
  border-radius: 0px;
}

.right_side_bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar {
  padding: 30px 90px 30px 50px;
}

@media (max-width: 767px) {
  .used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar {
    padding: 30px 30px 30px;
    flex: 1 0 0;
  }
}

.used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .title {
  font-size: 24px;
  letter-spacing: 3px;
  line-height: 25px;
  color: #000;
  font-weight: 700;
  font-family: arimobold;
}

.used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .addtocart {
  background: #000;
  color: #fff;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 18px;
  line-height: 1;
  padding: 12px 10px;
  max-width: 200px;
  width: 100%;
  margin: 20px 0 0;
  font-family: 'arimoregular';
}

@media (max-width: 767px) {
  .used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .addtocart {
    max-width: 100%;
    width: 100%;
  }
}

.used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .type_p span:first-child {
  font-weight: bold;
}

.used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .type_p span:last-child {
  font-weight: normal;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 0;
  color: #000;
}

.used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .type_p .description.rebooted_desc {
  color: #000;
}

.used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .type_p .description.rebooted_desc {
  text-transform: none;
}

.used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .continue_shop_reboot {
  margin-top: 20px;
}

.re-vita_collection_wrap .quickbtnn {
  display: none !important;
}

@media (max-width: 767px) {
  .used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .close-icon svg path {
    fill: #fff;
  }
}

.used_pdp .rebooted_popup_wrpr button.slick-prev,
.used_pdp .rebooted_popup_wrpr button.slick-next {
  background-image: url(https://cdn.shopify.com/s/files/1/2170/8465/files/right-arrow-angle_1.png?v=1642413998);
  opacity: 1;
  background-size: 15px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  background-repeat: no-repeat;
  background-position: center;
}

.used_pdp .rebooted_popup_wrpr button.slick-prev:before,
.used_pdp .rebooted_popup_wrpr button.slick-next:before {
  content: '';
}

.rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-arrow.slick-prev {
  z-index: 1;
}

@media (max-width: 767px) {

  .used_pdp .rebooted_popup_wrpr button.slick-prev,
  .used_pdp .rebooted_popup_wrpr button.slick-next {
    background-size: 18px;
    width: 30px;
    height: 30px;
  }

  .used_pdp .rebooted_popup_wrpr button.slick-prev,
  .used_pdp .rebooted_popup_wrpr button.slick-next {
    background-size: 10px;
  }
}

.blocker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 107158;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.75);
  text-align: center;
}

.blocker:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.05em;
}

.blocker.behind {
  background-color: transparent;
}

.modal {
  display: none;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  max-width: 800px;
  box-sizing: border-box;
  width: 90%;
  background: #fff;
  padding: 15px 30px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 10px #000;
  -moz-box-shadow: 0 0 10px #000;
  -o-box-shadow: 0 0 10px #000;
  -ms-box-shadow: 0 0 10px #000;
  box-shadow: 0 0 10px #000;
  text-align: left;
}

.modal a.close-modal {
  position: absolute;
  top: -12.5px;
  right: -12.5px;
  display: block;
  width: 30px;
  height: 30px;
  text-indent: -9999px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAA3hJREFUaAXlm8+K00Acx7MiCIJH/yw+gA9g25O49SL4AO3Bp1jw5NvktC+wF88qevK4BU97EmzxUBCEolK/n5gp3W6TTJPfpNPNF37MNsl85/vN/DaTmU6PknC4K+pniqeKJ3k8UnkvDxXJzzy+q/yaxxeVHxW/FNHjgRSeKt4rFoplzaAuHHDBGR2eS9G54reirsmienDCTRt7xwsp+KAoEmt9nLaGitZxrBbPFNaGfPloGw2t4JVamSt8xYW6Dg1oCYo3Yv+rCGViV160oMkcd8SYKnYV1Nb1aEOjCe6L5ZOiLfF120EjWhuBu3YIZt1NQmujnk5F4MgOpURzLfAwOBSTmzp3fpDxuI/pabxpqOoz2r2HLAb0GMbZKlNV5/Hg9XJypguryA7lPF5KMdTZQzHjqxNPhWhzIuAruOl1eNqKEx1tSh5rfbxdw7mOxCq4qS68ZTjKS1YVvilu559vWvFHhh4rZrdyZ69Vmpgdj8fJbDZLJpNJ0uv1cnr/gjrUhQMuI+ANjyuwftQ0bbL6Erp0mM/ny8Fg4M3LtdRxgMtKl3jwmIHVxYXChFy94/Rmpa/pTbNUhstKV+4Rr8lLQ9KlUvJKLyG8yvQ2s9SBy1Jb7jV5a0yapfF6apaZLjLLcWtd4sNrmJUMHyM+1xibTjH82Zh01TNlhsrOhdKTe00uAzZQmN6+KW+sDa/JD2PSVQ873m29yf+1Q9VDzfEYlHi1G5LKBBWZbtEsHbFwb1oYDwr1ZiF/2bnCSg1OBE/pfr9/bWx26UxJL3ONPISOLKUvQza0LZUxSKyjpdTGa/vDEr25rddbMM0Q3O6Lx3rqFvU+x6UrRKQY7tyrZecmD9FODy8uLizTmilwNj0kraNcAJhOp5aGVwsAGD5VmJBrWWbJSgWT9zrzWepQF47RaGSiKfeGx6Szi3gzmX/HHbihwBser4B9UJYpFBNX4R6vTn3VQnez0SymnrHQMsRYGTr1dSk34ljRqS/EMd2pLQ8YBp3a1PLfcqCpo8gtHkZFHKkTX6fs3MY0blKnth66rKCnU0VRGu37ONrQaA4eZDFtWAu2fXj9zjFkxTBOo8F7t926gTp/83Kyzzcy2kZD6xiqxTYnHLRFm3vHiRSwNSjkz3hoIzo8lCKWUlg/YtGs7tObunDAZfpDLbfEI15zsEIY3U/x/gHHc/G1zltnAgAAAABJRU5ErkJggg==');
}

.modal-spinner {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 12px 16px;
  border-radius: 5px;
  background-color: #111;
  height: 20px;
}

.modal-spinner>div {
  border-radius: 100px;
  background-color: #fff;
  height: 20px;
  width: 2px;
  margin: 0 1px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.modal-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.modal-spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.modal-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-stretchdelay {

  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.5);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {

  0%,
  40%,
  100% {
    transform: scaleY(0.5);
    -webkit-transform: scaleY(0.5);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

a:hover,
a:focus,
a:active {
  color: #424242;
}

.product-slider.used_product_slider .slick-dots {
  bottom: 0px;
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr .slick-dots li {
  display: inline-block;
  height: 13px;
  margin: 0 5px;
  width: 13px;
  border: 2px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr .slick-dots li button {
  background: transparent;
  padding: 0;
  text-indent: -100000px;
  border: 0;
  height: 0;
  width: 0;
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr .slick-dots li button:before {
  display: none;
}

.used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr .slick-dots li.slick-active {
  background: #ccc;
  background-color: #ccc;
}

.rebooted_bold_text {
  color: #000;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'acumin-pro', sans-serif;
}

.used_pdp.template-product .product_details_outer .product-detailSlider {
  max-width: 65%;
  flex: 0 0 65%;
}

.used_pdp.template-product .product_details_outer .NewViewAll {
  max-width: 35%;
  flex: 0 0 35%;
}

.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .breadcrumb span a {
  text-decoration: underline;
}

.used_pdp .money {
  font-family: acumin-pro, sans-serif;
  color: #000;
  font-size: 18px;
  font-weight: 500;
}

.used_pdp .PercyText.NewViewAll .detail-Btnn .detail:after {
  display: none;

}

.used_pdp.template-product .product_details_outer .NewViewAll .price {
  font-size: 20px;
}

#size_drawer .detail-container {
  max-width: 100%;
  overflow-x: hidden;
}

.used_pdp .PercyText.NewViewAll .avialabel_options {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 0;
}

.used_pdp .re_booted_paragraph .re_booted_headingg {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  text-transform: lowercase;
  letter-spacing: 1px;
  padding: 0 25px 0 0;
  position: relative;
  text-decoration: none;
}

@media (max-width: 767px) {
  .rebooted_popup_wrpr .rebooted_popup_parent {
    display: block;
  }
}

@media (max-width: 1024px) {
  .used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr {
    max-width: 140px;
    padding: 0 15px;
  }

  .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebooted_atc_btn {
    max-width: 150px;
    font-size: 12px;
  }

  .used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedimg_wrpr a {
    padding-bottom: 120px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .used_pdp.template-product .product_details_outer .product-detailSlider {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }

  .used_pdp.template-product .product_details_outer .NewViewAll {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .template-product .product_details_outer .NewViewAll h1 {
    margin: 20px 0;
    padding-bottom: 0;
  }

  .used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid {
    align-items: flex-start;
  }

  .used_pdp .all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedcnt_wrpr {
    padding-left: 20px;
  }
}

@media (max-width: 768px) {
  .template-product .product_details_outer .NewViewAll .price {
    position: relative;
    right: 0;
  }

  .rebooted_bold_text {
    font-size: 14px;
  }

  .used_pdp.template-product .product_details_outer {
    padding: 0 20px !important;
  }

  .used_pdp.template-product .product_details_outer .NewViewAll .price {
    font-size: 14px;
  }

  .used_pdp .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .type_p span:last-child {
    font-size: 14px;
  }

  .product__info-wrapper.grid__item.scroll-trigger.animate--slide-in.used_pdp {
    padding: 0 20px !important;
  }


  .breadcrumb {
    display: block !important;
  }
}

/* @media (max-width: 767px) {
.rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-slide video, .rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-slide img {
  height: auto !important;
  top: 0;
  transform: none;
  object-fit: scale-down;
}
.rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-track {
  padding-bottom: 100%;
}
.rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-list, .rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-track {
  display: flex;
  height: 100%;
}
.rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar {
  height: unset !important;
}
} */
.re_vita_link_item {
  text-transform: lowercase !important;
}

.annoucement_bar_slider {
  display: none;
}

.annoucement_bar_slider.slick-initialized {
  display: block;
}

.page_re-vita .breadcrumb {
  margin-bottom: 10px;
}

.selector-wrapper {
  display: none;
}

.selector-wrapper.active {
  display: block !important;
}

/* reboot popup */
body.rebooted_open_filter .rebooted_filter_overly {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in-out;
}

.rebooted_popup.blocker {
  transition: 0.4s all;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(178, 178, 178, 0.5);
}

.rebooted_popup.blocker.active {
  opacity: 1;
  visibility: visible;
}

.isp_related_wrapper .slick-next,
.isp_related_wrapper .slick-prev {
  z-index: 2;
}

.rebooted_popup_wrpr {
  box-shadow: 5px 5px 9px #747474;
  max-width: 1060px;
  width: calc(100% - 50px);
  height: 65vh;
  margin: 0 auto;
  position: fixed;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 107159;
  border-radius: 5px;
  overflow: hidden;
  /*   @media(max-width: 1440px){
  max-width: 900px;
  width: 100%;
} */
}

@media (max-width: 991px) {
  .rebooted_popup_wrpr {
    max-width: calc(100% - 30px);
  }
}

@media (max-width: 767px) {
  .rebooted_popup_wrpr {
    height: 96%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.rebooted_popup_wrpr .rebooted_popup_parent {
  display: flex;
  flex-wrap: wrap;
  /*     align-items: center; */
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.rebooted_popup_wrpr .rebooted_popup_parent .close-icon {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 999999;
  padding: 0 10px;
}

@media (max-width: 767px) {
  .rebooted_popup_wrpr .rebooted_popup_parent .close-icon {
    top: 15px;
    right: 15px;
  }
}

.rebooted_popup_wrpr .rebooted_popup_parent .close-icon svg {
  width: 24px;
  height: 24px;
}

.rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar,
.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar {
  flex: 0 0 50%;
  max-width: 50%;
  text-align: left;
}

@media (max-width: 767px) {

  .rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar,
  .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar {
  margin: 0;
}

@media (min-width: 768px) {
  .rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}

.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar {
  padding: 30px 100px 30px 60px;
}

@media (max-width: 767px) {
  .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar {
    padding: 20px;
  }
}

.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .title {
  font-size: 24px;
  letter-spacing: 3px;
  line-height: 25px;
  color: #000;
  font-weight: 700;
  font-family: arimoregular !important;
  text-transform: uppercase;
  margin: 30px 0;
}

@media (max-width: 767px) {
  .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .title {
    margin: 15px 0;
  }
}

.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .rebooted_desc {
  position: relative;
  display: inline-block;
  word-wrap: break-word;
  overflow: hidden;
  /*         max-height: 36px; */
  line-height: 1.2em;
  text-align: justify;
  transition: 0.4s all;
  margin: 10px 0 0 0;
}

.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .rebooted_desc.active {
  max-height: initial;
}

.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .rebooted_desc * {
  font-family: acumin-pro, sans-serif;
}

.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .rebooted_readmore,
.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .rebooted_readless {
  width: 100%;
}

.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .rebooted_readmore a,
.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .rebooted_readless a {
  text-decoration: underline;
}

.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .rebooted_readless {
  display: none;
}

.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .type_p {
  width: 100%;
  display: block;
  margin: 0;
}

.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .type_p span:first-child {
  font-weight: bold;
  text-transform: uppercase;
}

.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .type_p .price_text {
  margin-top: 4px;
}

.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .breadcrumb {
  margin: 0 5px 0 0;
  text-transform: capitalize;
}

.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .breadcrumb span {
  color: #818181;
  font-size: 16px;
  padding: 0;
}

@media (max-width: 767px) {
  .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .breadcrumb span {
    font-size: 15px;
  }
}

.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .breadcrumb span a {
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
}

.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .revooted_variant_price {
  margin-left: 5px;
  font-weight: bold;
  color: #818181;
}

@media (max-width: 767px) {
  .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .revooted_variant_price {
    font-size: 15px;
  }
}

.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .addtocart {
  background: #000;
  color: #fff;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 18px;
  line-height: 1;
  padding: 12px 10px;
  max-width: 200px;
  width: 100%;
  margin: 20px 0 0;
  font-family: arimoregular;
}

.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .addtocart:hover {
  color: #fff;
  background: #000;
}

@media (max-width: 767px) {
  .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .addtocart {
    margin: 40px 0 0 0 !important;
    display: block;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .swym-button-bar {
    position: absolute;
  }
}

@media (max-width: 767px) {
  .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .description.rte {
    font-size: 14px;
  }
}

.rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .continue_shop_reboot {
  text-decoration: underline;
  margin-top: 30px;
  display: inline-block;
  font-size: 14px;
}

@media (max-width: 767px) {
  .rebooted_popup_wrpr .rebooted_popup_parent .right_side_bar .continue_shop_reboot {
    margin-top: 15px;
    display: block;
    text-align: center;
  }
}

.rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar {
  height: 100%;
}

@media (max-width: 767px) {
  .rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar {
    height: 400px;
  }
}

.rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-arrow {
  top: 50%;
  transform: translateY(-50%);
}

.rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-arrow.slick-next {
  right: 20px !important;
}

.rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-arrow.slick-prev {
  transform: translateY(-50%) rotate(180deg);
  left: 20px !important;
}

.rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-list,
.rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-track {
  display: flex;
  height: 100%;
}

.rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-slide {
  position: relative;
  background-color: #fafafa;
  border-radius: 15px;
}

@media (max-width: 767px) {
  .rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-slide {
    display: flex;
    align-items: flex-start;
  }
}

.rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-slide video,
.rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-slide img {
  width: 100%;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  mix-blend-mode: multiply;
}

@media (max-width: 767px) {

  .rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-slide video,
  .rebooted_popup_wrpr .rebooted_popup_parent .left_side_bar .slick-slide img {
    height: 100%;
    top: 0;
    transform: none;
    object-fit: scale-down;
  }
}

.rebooted_popup_wrpr .rebooted_popup_parent .rebooted_breadcrumb {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}

.rebooted_popup_wrpr button.slick-next,
.rebooted_popup_wrpr button.slick-prev {
  background-size: 60px auto;
  background-image: url(https://cdn.shopify.com/s/files/1/2170/8465/files/right_a.svg?v=1639487542);
}

.rebooted_popup_wrpr button.slick-prev {
  transform: rotate(180deg);
}

.rebooted_popup_wrpr del {
  display: none;
}

/************************* NEW PAGE CSS 02-02-2022 ***************************/
.page_re-booted .NewViewAllBx {
  display: none;
}

@media only screen and (max-width: 767px) {
  .page_re-booted .container {
    padding: 0 15px;
  }
}

.dc-btn {
  padding: 15px 25px !important;
  line-height: 1;
  min-width: 250px;
  border: 1px solid #000 !important;
  color: #000 !important;
  font-weight: 500 !important;
  display: inline-block !important;
  transition: all ease-in-out 0.5s !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
  text-align: center !important;
}

:is(.account) .dc-btn:hover {
  background: #000 !important;
  color: #fff !important;
  text-decoration: none !important;
}

@media (max-width: 767px) {
  .dc-btn {
    min-width: 200px;
  }
}

.dc-btn:hover {
  background: #000;
  color: #fff;
}

.section-title {
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .section-title {
    margin-bottom: 30px;
  }
}

.section-title h2,
.section-title .h2 {
  font-family: Baskerville !important;
  font-style: italic;
  text-transform: unset;
  font-size: 36px;
  letter-spacing: 0;
  line-height: 1;
}

@media (max-width: 767px) {

  .section-title h2,
  .section-title .h2 {
    font-size: 30px;
  }
}

.section-title p {
  margin-top: 30px;
  color: #000;
}

.desk-only {
  display: none !important;
}

.product-details--mob---only {
  display: none !important;
}

@media (min-width: 767px) {
  .desk-only {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .product-details--mob---only {
    display: block !important;
    padding: 0px 30px !important;
    margin-top: 30px !important;
    margin-bottom: 30px;
  }

  .product-details--mob---only h2 {
    letter-spacing: 2px !important;
    text-transform: uppercase;
    font-size: 12px !important;
    font-weight: 700 !important;
  }

  .product-details--mob---only p,
  ul {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #000 !important;
    line-height: 20px !important;
    letter-spacing: 1px !important;
  }
}

.mobile-only {
  display: block !important;
}

@media (min-width: 767px) {
  .mobile-only {
    display: none !important;
  }
}

.re-booted-banner-common {
  margin-left: calc((100% - 1400px) / 2);
}

.re-booted-banner-common.reboot_collection_banner {
  padding-bottom: 50px;
}

@media (max-width: 1440px) {
  .re-booted-banner-common {
    margin: 0;
  }
}

.re-booted-banner-common .flex-banner {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.re-booted-banner-common .re-booter-bnr-img {
  flex: 1;
  width: 100%;
}

@media (max-width: 767px) {
  .re-booted-banner-common .re-booter-bnr-img {
    flex: 0 0 100%;
  }
}

.re-booted-banner-common .re-booter-bnr-img img {
  width: 100%;
  object-fit: cover;
}

@media (max-width: 767px) {
  .re-booted-banner-common .re-booter-bnr-img img {
    min-height: 600px;
  }
}

.re-booted-banner-common .re-booted-banner-capt-col {
  width: 100%;
  max-width: 50%;
  text-align: center;
}

@media (max-width: 991px) {
  .re-booted-banner-common .re-booted-banner-capt-col {
    max-width: 50%;
  }
}

@media (max-width: 767px) {
  .re-booted-banner-common .re-booted-banner-capt-col {
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
  }
}

.re-booted-banner-common .re-booted-banner-capt-col .re-booted-banner-capt-col-inner {
  padding: 100px 80px 100px 0px;
}

@media (max-width: 1440px) {
  .re-booted-banner-common .re-booted-banner-capt-col .re-booted-banner-capt-col-inner {
    padding: 100px 80px;
  }
}

@media (max-width: 1200px) {
  .re-booted-banner-common .re-booted-banner-capt-col .re-booted-banner-capt-col-inner {
    padding: 100px 20px;
  }
}

.re-booted-banner-common .re-booted-banner-capt-col .re-booted-banner-capt-col-inner h1,
.re-booted-banner-common .re-booted-banner-capt-col .re-booted-banner-capt-col-inner .h1 {
  font-size: 70px;
  letter-spacing: 6px;
  line-height: 1;
  color: #000;
  font-weight: 700;
  margin: 0 0 30px;
  font-family: arimobold;
}

@media (max-width: 1200px) {

  .re-booted-banner-common .re-booted-banner-capt-col .re-booted-banner-capt-col-inner h1,
  .re-booted-banner-common .re-booted-banner-capt-col .re-booted-banner-capt-col-inner .h1 {
    font-size: 50px;
  }
}

@media (max-width: 991px) {

  .re-booted-banner-common .re-booted-banner-capt-col .re-booted-banner-capt-col-inner h1,
  .re-booted-banner-common .re-booted-banner-capt-col .re-booted-banner-capt-col-inner .h1 {
    font-size: 42px;
    margin: 0 0 20px;
  }
}

@media (max-width: 767px) {

  .re-booted-banner-common .re-booted-banner-capt-col .re-booted-banner-capt-col-inner h1,
  .re-booted-banner-common .re-booted-banner-capt-col .re-booted-banner-capt-col-inner .h1 {
    letter-spacing: 3px;
  }
}

.re-booted-banner-common .re-booted-banner-capt-col .re-booted-banner-capt-col-inner p {
  color: #000;
  letter-spacing: 0.4px;
  font-size: 17px;
  max-width: 400px;
  margin: 0 auto;
}

.re-booted-banner-common .re-booted-banner-capt-col .re-booted-banner-capt-col-inner .btn-wrap {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .re-booted-banner-common .re-booted-banner-capt-col .re-booted-banner-capt-col-inner .btn-wrap {
    flex-direction: column;
  }
}

.re-booted-banner-common .re-booted-banner-capt-col .re-booted-banner-capt-col-inner .btn-wrap a {
  margin: 0 10px;
  width: 100%;
  max-width: calc(50% - 20px);
  display: block;
  min-width: unset;
}

@media (max-width: 767px) {
  .re-booted-banner-common .re-booted-banner-capt-col .re-booted-banner-capt-col-inner .btn-wrap a {
    max-width: 200px;
    margin: 0 auto 20px;
    background: #fff;
    color: #000;
  }
}

.common-italic-cotent {
  padding: 50px 0 70px;
}

@media (max-width: 767px) {
  .common-italic-cotent {
    padding: 40px 0;
  }
}

.common-italic-cotent p {
  color: #000;
  font-family: Baskerville;
  font-style: italic;
  text-align: center;
  font-size: 35px;
  margin: 0;
}

@media (max-width: 1440px) {
  .common-italic-cotent p {
    font-size: 30px;
  }
}

@media (max-width: 991px) {
  .common-italic-cotent p {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .common-italic-cotent p {
    font-size: 22px;
    line-height: 1.5;
  }
}

.how-it-workes-section {
  background: #eff0f0;
  padding: 60px 0;
}

@media (max-width: 767px) {
  .how-it-workes-section {
    padding: 40px 0 0;
  }
}

.how-it-workes-section .how-it-wrk-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

@media (max-width: 767px) {
  .how-it-workes-section .how-it-wrk-row {
    margin: 0 -10px;
  }
}

.how-it-workes-section .how-it-wrk-row .how-it-wrk-col {
  padding: 0 15px;
  max-width: 25%;
  width: 100%;
}

@media (max-width: 767px) {
  .how-it-workes-section .how-it-wrk-row .how-it-wrk-col {
    max-width: 50%;
    margin-bottom: 40px;
    padding: 0 10px;
  }
}

.how-it-workes-section .how-it-wrk-row .how-it-wrk-col .how-it-wrk-col-inner {
  max-width: 210px;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 1024px) {
  .how-it-workes-section .how-it-wrk-row .how-it-wrk-col .how-it-wrk-col-inner {
    max-width: 190px;
  }
}

.how-it-workes-section .how-it-wrk-row .how-it-wrk-col .how-it-wrk-col-inner .num {
  display: block;
  font-size: 18px;
}

@media (max-width: 767px) {
  .how-it-workes-section .how-it-wrk-row .how-it-wrk-col .how-it-wrk-col-inner .num {
    font-size: 16px;
  }
}

.how-it-workes-section .how-it-wrk-row .how-it-wrk-col .how-it-wrk-col-inner h5,
.how-it-workes-section .how-it-wrk-row .how-it-wrk-col .how-it-wrk-col-inner .h5 {
  font-family: Baskerville !important;
  font-style: italic;
  font-size: 24px;
  color: #000;
  margin: 18px 0 15px;
}

@media (max-width: 767px) {

  .how-it-workes-section .how-it-wrk-row .how-it-wrk-col .how-it-wrk-col-inner h5,
  .how-it-workes-section .how-it-wrk-row .how-it-wrk-col .how-it-wrk-col-inner .h5 {
    margin: 11px 0 8px;
    font-size: 20px;
  }
}

.how-it-workes-section .how-it-wrk-row .how-it-wrk-col .how-it-wrk-col-inner p {
  margin: 0;
  font-size: 14px;
  color: #000;
  line-height: 1.6;
}

@media (max-width: 767px) {
  .how-it-workes-section .how-it-wrk-row .how-it-wrk-col .how-it-wrk-col-inner p {
    font-size: 13px;
  }
}

.how-it-workes-section .how-it-wrk-row .how-it-wrk-col .how-it-wrk-col-inner p a {
  display: inline-block;
  text-decoration: underline;
}

.frequently-asked-section {
  padding: 60px 0;
}

@media (max-width: 767px) {
  .frequently-asked-section {
    padding: 40px 0;
  }
}

.set:not(:last-of-type) {
  margin-bottom: 20px;
}

.set .faq_head {
  text-align: left;
  color: #000;
  position: relative;
  display: block;
  padding: 0 0 20px 0;
  transition: all ease-in-out 0.5s;
  border-bottom: 1px solid #000;
  text-decoration: none !important;
}

.set .faq_head:before {
  content: '';
  position: absolute;
  height: 10px;
  width: 2px;
  background: #000;
  right: 4px;
  top: 6px;
  transition: all ease-in-out 0.5s;
}

.set .faq_head:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 2px;
  background: #000;
  right: 0px;
  top: 10px;
  transition: all ease-in-out 0.5s;
}

.set .faq_head.active:before {
  opacity: 0;
}

.set .faq_head:focus {
  color: #000;
}

.set .faq_head span {
  font-weight: bold;
}

.set .content {
  padding: 20px 0 0;
  width: 100%;
  display: none;
  color: #000;
}

.set .content p,
.set .content a,
.set .content span {
  font-size: 14px;
  color: #000;
}

.set .content h3,
.set .content .h3 {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.sustainability-at-dc-vita img {
  width: 100%;
  object-fit: cover;
}

@media (max-width: 767px) {
  .sustainability-at-dc-vita img {
    min-height: 560px;
  }
}

.sustainability-at-dc-vita .container {
  position: relative;
}

@media (max-width: 767px) {
  .sustainability-at-dc-vita .container {
    padding: 0;
  }
}

.sustainability-at-dc-vita .sustainability-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  left: 0;
  right: 0;
  max-width: 650px;
  color: #fff;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .sustainability-at-dc-vita .sustainability-content {
    padding: 0 15px;
  }
}

.sustainability-at-dc-vita .sustainability-content p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 30px;
}

.sustainability-at-dc-vita .sustainability-content .section-title {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .sustainability-at-dc-vita .sustainability-content .section-title {
    max-width: 160px;
    margin: 0 auto 20px;
  }
}

.sustainability-at-dc-vita .sustainability-content .dc-btn {
  color: #fff;
  border-color: #fff;
}

.sustainability-at-dc-vita .sustainability-content .dc-btn:hover {
  border-color: #000;
}

section.sustainability-at-dc-vita.text-center {
  position: relative;
  margin: 0 70px;
}

.__wrapper {
  padding: 0 5%;
}

@media ((min-width: 20em)) {
  .__wrapper.container {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 60px;
    margin: 0 70px;
    width: 100% !important;
  }
}

.our-favorites-section {
  padding: 60px 0;
  margin: 0 70px;
}

@media (max-width: 767px) {
  .our-favorites-section {
    padding: 40px 0;
  }
}

.our-favorites-section .section-title h2 span,
.our-favorites-section .section-title .h2 span {
  font-family: 'arimoregular';
  font-style: normal;
  display: inline-block;
  font-weight: 600;
}

.our-favorites-section .our-favorites-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 0;
}

@media (max-width: 767px) {
  .our-favorites-section .our-favorites-row {
    margin: 0;
  }
}

.our-favorites-section .our-favorites-row .our-fav-col {
  padding: 0 10px;
  width: 100%;
  max-width: 33.33%;
  display: flex;
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .our-favorites-section .our-favorites-row .our-fav-col {
    max-width: 50%;
    padding: 0 5px;
  }
}

.our-favorites-section .our-favorites-row .our-fav-col .our-fav-col-inner {
  width: 100%;
}

.our-favorites-section .our-favorites-row .our-fav-col .our-fav-col-inner .our-fav-media {
  position: relative;
}

.our-favorites-section .our-favorites-row .our-fav-col .our-fav-col-inner .our-fav-media .pre-love-lbl {
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  padding: 6px 21px 10px;
  color: #fff;
  font-family: Baskerville !important;
  font-style: italic;
  font-size: 18px;
  line-height: 1;
  display: block;
  letter-spacing: 1px;
}

.our-favorites-section .our-favorites-row .our-fav-col .our-fav-col-inner .our-fav-media .media-wrp {
  display: block;
  position: relative;
  overflow: hidden;
  transition: all ease-in-out 0.5s;
  padding-top: 100%;
  width: 100%;
  text-decoration: none !important;
}

.our-favorites-section .our-favorites-row .our-fav-col .our-fav-col-inner .our-fav-media .media-wrp img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  transition: all ease-in-out 0.5s;
}

.our-favorites-section .our-favorites-row .our-fav-col .our-fav-col-inner .our-fav-media .media-wrp img.hover-img {
  opacity: 0;
  visibility: hidden;
}

.our-favorites-section .our-favorites-row .our-fav-col .our-fav-col-inner .our-fav-media .media-wrp:hover .hover-img {
  opacity: 1;
  visibility: visible;
}

.our-favorites-section .our-favorites-row .our-fav-col .our-fav-col-inner .our-fav-content {
  margin-top: 20px;
}

.our-favorites-section .our-favorites-row .our-fav-col .our-fav-col-inner .our-fav-content h4,
.our-favorites-section .our-favorites-row .our-fav-col .our-fav-col-inner .our-fav-content .h4 {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 10px;
}

.our-favorites-section .our-favorites-row .our-fav-col .our-fav-col-inner .our-fav-content h4 a,
.our-favorites-section .our-favorites-row .our-fav-col .our-fav-col-inner .our-fav-content .h4 a {
  text-decoration: none !important;
  color: #000;
}

.our-favorites-section .our-favorites-row .our-fav-col .our-fav-col-inner .our-fav-content .price {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.our-favorites-section .our-favorites-row .our-fav-col .our-fav-col-inner .our-fav-content .price del {
  color: #999;
  margin-right: 12px;
}

.our-favorites-section .our-favorites-row .our-fav-col .our-fav-col-inner .our-fav-content .price ins {
  text-decoration: none;
  font-weight: 600;
}

.our-favorites-section .our-favorites-row .our-fav-col .our-fav-col-inner .our-fav-content p {
  font-size: 14px;
  margin: 10px 0;
  color: #000;
}

.our-favorites-section .our-favorites-row .our-fav-col .our-fav-col-inner .our-fav-content .pro-color-variant {
  display: flex;
  flex-wrap: wrap;
}

.our-favorites-section .our-favorites-row .our-fav-col .our-fav-col-inner .our-fav-content .pro-color-variant .color {
  height: 15px;
  width: 15px;
  border-radius: 20px;
  margin: 0 5px 0 0;
}

.our-favorites-section .center-title-text p {
  text-align: center;
  color: #000;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
}

.our-favorites-section .center-title-text p span {
  display: block;
  font-family: Baskerville;
  margin-top: 22px;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 0;
}

/******** ACC BANNER CSS ***********/
.section-header .right-content {
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 1200px) {
  .section-header .right-content {
    padding-left: 20px;
  }
}

@media (max-width: 767px) {
  .section-header .right-content {
    padding-left: 0;
  }
}

.account_how_it_works {
  margin-bottom: 60px;
}

.acc-banner-booted {
  padding-bottom: 50px;
}

.acc-banner-booted .acc-banner-row {
  display: flex;
  flex-wrap: wrap;
}

.acc-banner-booted .acc-banner-row .acc-banner-col-left {
  max-width: 45%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
}

@media (max-width: 991px) {
  .acc-banner-booted .acc-banner-row .acc-banner-col-left {
    max-width: 100%;
  }
}

.acc-banner-booted .acc-banner-row .acc-banner-col-left .acc-banner-col-left-inner {
  width: 100%;
  padding: 60px 30px;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .acc-banner-booted .acc-banner-row .acc-banner-col-left .acc-banner-col-left-inner {
    padding: 40px 0;
  }
}

.acc-banner-booted .acc-banner-row .acc-banner-col-left .acc-banner-col-left-inner .acc-title {
  font-size: 32px;
  font-weight: 600;
  margin: 0 0 16px;
  line-height: 1;
  color: #1c1c1c;
}

@media (max-width: 991px) {
  .acc-banner-booted .acc-banner-row .acc-banner-col-left .acc-banner-col-left-inner .acc-title {
    font-size: 28px;
  }
}

.acc-banner-booted .acc-banner-row .acc-banner-col-left .acc-banner-col-left-inner p {
  font-size: 14px;
  color: #000;
  margin-bottom: 15px;
}

.acc-banner-booted .acc-banner-row .acc-banner-col-left .acc-banner-col-left-inner .dc-btn {
  min-width: 180px;
}

.acc-banner-booted .acc-banner-row .acc-banner-col-right {
  width: 100%;
  max-width: 55%;
  display: flex;
}

@media (max-width: 991px) {
  .acc-banner-booted .acc-banner-row .acc-banner-col-right {
    max-width: 100%;
  }
}

.acc-banner-booted .acc-banner-row .acc-banner-col-right .acc-banner-img {
  height: 100%;
  width: 100%;
}

.acc-banner-booted .acc-banner-row .acc-banner-col-right .acc-banner-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
}

.rebooted_buttons {
  justify-content: center;
  padding: 0 10px 60px 10px;
  display: flex;
  flex-wrap: wrap;
}

.rebooted_buttons a {
  margin: 10px;
}

.hero__overlay::before {
  opacity: 0 !important;
}

/*----- trevor-project css -----*/
/*---- START HERO SECTION ----*/
.container {
  /* width: 1300px; */
  width: 100%;
  margin: 0 auto;
}

.only-desktop {
  width: 100%;
}

.only-moblie {
  width: 100%;
  display: none;
}

/*----  END HERO SECTION  ----*/
/*---- START CUMMUNITY SECTION ----*/
.comunity-section {
  padding: 50px 0;
}

.comunity-section .comunity-section-wrapper .comunity-top {
  display: flex;
  justify-content: center;
  margin: 108px auto 80px auto;
  align-items: center;
}

.comunity-section .comunity-section-wrapper .comunity-top .comunity-left-img {
  max-width: 105px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .comunity-section .comunity-section-wrapper .comunity-top .comunity-left-img {
    max-width: 80px;
  }
}

@media only screen and (max-width: 540px) {
  .comunity-section .comunity-section-wrapper .comunity-top .comunity-left-img {
    max-width: 106px;
  }
}

@media only screen and (max-width: 425px) {
  .comunity-section .comunity-section-wrapper .comunity-top .comunity-left-img {
    max-width: 70px;
  }
}

.comunity-section .comunity-section-wrapper .comunity-top .center-text {
  margin: 7px 50px 0 50px;
}

.comunity-section .comunity-section-wrapper .comunity-top .center-text h3,
.comunity-section .comunity-section-wrapper .comunity-top .center-text .h3 {
  font-size: 26px;
  line-height: 30px;
  font-weight: 700;
  font-weight: bold;
  line-height: 1;
}

@media only screen and (max-width: 767px) {

  .comunity-section .comunity-section-wrapper .comunity-top .center-text h3,
  .comunity-section .comunity-section-wrapper .comunity-top .center-text .h3 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 540px) {

  .comunity-section .comunity-section-wrapper .comunity-top .center-text h3,
  .comunity-section .comunity-section-wrapper .comunity-top .center-text .h3 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .comunity-section .comunity-section-wrapper .comunity-top .center-text {
    margin: 0 0 0 15px;
  }
}

@media only screen and (max-width: 412px) {
  .comunity-section .comunity-section-wrapper .comunity-top .center-text {
    margin: 0 0px 0 0px;
  }
}

.comunity-section .comunity-section-wrapper .comunity-top .comunity-right-img {
  max-width: 233px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .comunity-section .comunity-section-wrapper .comunity-top .comunity-right-img {
    max-width: 150px;
  }
}

@media only screen and (max-width: 540px) {
  .comunity-section .comunity-section-wrapper .comunity-top .comunity-right-img {
    max-width: 180px;
  }
}

@media only screen and (max-width: 425px) {
  .comunity-section .comunity-section-wrapper .comunity-top .comunity-right-img {
    max-width: 110px;
  }
}

@media only screen and (max-width: 540px) {
  .comunity-section .comunity-section-wrapper .comunity-top {
    max-width: 413px;
  }
}

@media only screen and (max-width: 425px) {
  .comunity-section .comunity-section-wrapper .comunity-top {
    max-width: 100%;
    justify-content: space-between;
    /*padding: 0 20px;*/
  }
}

.comunity-section .comunity-section-wrapper .comunity-bottom {
  /*       max-width: 1000px; */
  margin: 0 auto;
  width: 100%;
}

.comunity-section .comunity-section-wrapper .comunity-bottom .comunity-bottom-img {
  margin: 70px 0 0;
}

.comunity-section .comunity-section-wrapper .comunity-bottom .comunity-bottom-img .comunity-bottom-img img {
  object-fit: cover;
}

@media only screen and (max-width: 991px) {
  .comunity-section .comunity-section-wrapper .comunity-bottom .comunity-bottom-img {
    margin: 90px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .comunity-section .comunity-section-wrapper .comunity-bottom .comunity-bottom-img {
    margin: 80px 0 0;
  }
}

@media only screen and (max-width: 540px) {
  .comunity-section .comunity-section-wrapper .comunity-bottom .comunity-bottom-img {
    margin: 118px 0 0;
  }
}

@media only screen and (max-width: 412px) {
  .comunity-section .comunity-section-wrapper .comunity-bottom .comunity-bottom-img {
    margin: 45px 0 0;
  }
}

.comunity-section .comunity-section-wrapper .hadding-section {
  margin: 50px 0 0px 0;
  text-align: center;
}

.comunity-section .comunity-section-wrapper .hadding-section h2,
.comunity-section .comunity-section-wrapper .hadding-section .h2 {
  font-size: 32pt;
  font-weight: normal;
  line-height: 1.2;
  font-family: arimobold;
  text-transform: lowercase;
  letter-spacing: 2.6px;
  /*        @media only screen and (max-width: 540px) {font-size: 24px;}
             @media only screen and (max-width: 425px) {font-size: 22px;} */
}

@media only screen and (max-width: 1024px) {

  .comunity-section .comunity-section-wrapper .hadding-section h2,
  .comunity-section .comunity-section-wrapper .hadding-section .h2 {
    font-size: 32px;
    line-height: 1;
  }
}

@media only screen and (max-width: 767px) {
  .comunity-section .comunity-section-wrapper .hadding-section {
    margin: 28px 0 28px 0;
    font-size: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .comunity-section .comunity-section-wrapper .hadding-section {
    margin: 20px 0 20px 0;
  }
}

@media only screen and (max-width: 540px) {
  .comunity-section .comunity-section-wrapper .hadding-section {
    margin: 55px 0 20px 0;
  }
}

@media only screen and (max-width: 425px) {
  .comunity-section .comunity-section-wrapper .hadding-section {
    margin: 15px 0 15px 0;
  }
}

.comunity-section .comunity-section-wrapper .section-sub-hadding {
  text-align: center;
  margin: 30px auto 0 auto;
  max-width: 88%;
  width: 100%;
}

.comunity-section .comunity-section-wrapper .section-sub-hadding h6,
.comunity-section .comunity-section-wrapper .section-sub-hadding .h6 {
  font-size: 18px;
  line-height: 1.4;
  margin: 0 auto;
}

@media only screen and (max-width: 1280px) {

  .comunity-section .comunity-section-wrapper .section-sub-hadding h6,
  .comunity-section .comunity-section-wrapper .section-sub-hadding .h6 {
    line-height: 1.8;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {

  .comunity-section .comunity-section-wrapper .section-sub-hadding h6,
  .comunity-section .comunity-section-wrapper .section-sub-hadding .h6 {
    font-size: 18px;
    line-height: 1.35;
    max-width: 100%;
    font-size: 18px;
  }
}

@media only screen and (max-width: 425px) {

  .comunity-section .comunity-section-wrapper .section-sub-hadding h6,
  .comunity-section .comunity-section-wrapper .section-sub-hadding .h6 {
    font-size: 14px;
    line-height: 1.6;
  }
}

@media only screen and (max-width: 1280px) {
  .comunity-section .comunity-section-wrapper .section-sub-hadding {
    margin: 20px auto 0 auto;
  }
}

@media only screen and (max-width: 767px) {
  .comunity-section .comunity-section-wrapper .section-sub-hadding {
    margin: 20px auto 0 auto;
  }
}

@media only screen and (max-width: 425px) {
  .comunity-section .comunity-section-wrapper .section-sub-hadding {
    margin: 15px auto 0 auto;
  }
}

@media only screen and (max-width: 768px) {
  .comunity-section {
    padding: 75px 0;
  }
}

@media only screen and (max-width: 540px) {
  .comunity-section {
    padding: 53px 0 109px 0px;
  }
}

@media only screen and (max-width: 412px) {
  .comunity-section {
    padding: 45px 0;
  }
}

/*----- END CUMMUNITY SECTION -----*/
/*----- START PARTNERSHIP SECTION -----*/
.partnrship-section .partnrship-boxs {
  display: flex;
  align-items: center;
}

.partnrship-section .partnrship-boxs .min-partnrship-left {
  max-width: 46.29%;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: block;
}

.partnrship-section .partnrship-boxs .min-partnrship-left .partnrship-img {
  padding-bottom: 117%;
  position: relative;
  display: block;
  overflow: hidden;
}

.partnrship-section .partnrship-boxs .min-partnrship-left .partnrship-img .partnrship-boxs-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {
  .partnrship-section .partnrship-boxs .min-partnrship-left .partnrship-img {
    padding-bottom: 107%;
  }
}

@media only screen and (max-width: 540px) {
  .partnrship-section .partnrship-boxs .min-partnrship-left .partnrship-img {
    padding-bottom: 119%;
  }
}

@media only screen and (max-width: 767px) {
  .partnrship-section .partnrship-boxs .min-partnrship-left {
    max-width: 75.5%;
    margin-bottom: -35%;
  }
}

@media only screen and (max-width: 540px) {
  .partnrship-section .partnrship-boxs .min-partnrship-left {
    max-width: 83.5%;
    margin-bottom: -73.69%;
  }
}

.partnrship-section .partnrship-boxs .min-partnrship-right h2,
.partnrship-section .partnrship-boxs .min-partnrship-right .h2 {
  text-transform: lowercase;
  font-size: 32pt;
  font-weight: normal;
  line-height: 1.2;
  margin-top: 10px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1280px) {

  .partnrship-section .partnrship-boxs .min-partnrship-right h2,
  .partnrship-section .partnrship-boxs .min-partnrship-right .h2 {
    line-height: 1;
    margin-top: 0px;
    margin-bottom: 18px;
  }
}

@media only screen and (max-width: 767px) {

  .partnrship-section .partnrship-boxs .min-partnrship-right h2,
  .partnrship-section .partnrship-boxs .min-partnrship-right .h2 {
    margin-bottom: 16px;
    font-size: 22pt;
  }
}

.partnrship-section .partnrship-boxs .min-partnrship-right p {
  font-size: 18px;
  line-height: 37px;
  color: #000;
}

@media only screen and (max-width: 1280px) {
  .partnrship-section .partnrship-boxs .min-partnrship-right p {
    line-height: 29px;
  }
}

@media only screen and (max-width: 1024px) {
  .partnrship-section .partnrship-boxs .min-partnrship-right p {
    line-height: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .partnrship-section .partnrship-boxs .min-partnrship-right p {
    font-size: 17px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 425px) {
  .partnrship-section .partnrship-boxs .min-partnrship-right p {
    font-size: 13px;
    line-height: 20px;
  }
}

.partnrship-section .partnrship-boxs .min-partnrship-right p a {
  display: inline-block;
  text-decoration: underline;
  color: #000;
}

@media only screen and (max-width: 767px) {
  .partnrship-section .partnrship-boxs {
    padding-bottom: 35%;
  }

  #shopify-section-template--15637176844354__trevor-partnership .partnrship-section .partnrship-boxs {
    padding-bottom: 0 !important;
  }
}

@media only screen and (max-width: 540px) {
  .partnrship-section .partnrship-boxs {
    padding-bottom: 72%;
  }

  #shopify-section-template--15637176844354__trevor-partnership .partnrship-section .partnrship-boxs {
    padding-bottom: 0 !important;
  }
}

@media only screen and (max-width: 475px) {
  .partnrship-section {
    padding-bottom: 60px;
  }

  #shopify-section-template--15637176844354__trevor-partnership .partnrship-section {
    padding-bottom: 0 !important;
  }
}

/*-----  END PARTNERSHIP SECTION  -----*/

/* Social club */

/* .sc-fHeRUh .cCunWv .pf-45_ span {
  color: #000 !important;
} */

.sc-iqseJM.ducrfW.pf-52_.pf-r.pf-r-eh .pf-c .sc-fHeRUh.cCunWv.pf-51_  {
  text-align: center;
}

.sc-iqseJM.ducrfW.pf-52_.pf-r.pf-r-eh .pf-c .sc-fHeRUh.cCunWv.pf-51_ .sc-hOGkXu.gCnidx.pf-50_.pf-button-4 {
  background-color: #000;
  text-decoration: none;
  font-family: 'Inter';
  font-weight: 700;
  padding-top: 20px;
  padding-bottom: 20px;
}

.sc-uojGG.hMqLeX.pf-43_.pf-color-scheme-1 .sc-ilfuhL.bpQaoh .sc-iqseJM.ducrfW.pf-44_.pf-r.pf-r-eh .pf-c .sc-fHeRUh.cCunWv.pf-45_ {
  text-align: center;
}

.sc-uojGG.hMqLeX.pf-43_.pf-color-scheme-1 .sc-ilfuhL.bpQaoh .sc-iqseJM.ducrfW.pf-44_.pf-r.pf-r-eh .pf-c .sc-fHeRUh.cCunWv.pf-45_ .sc-fIosxK.dnoTqD.pf-48_ span a{
  color: #000;
}
/* End Social club */

/*---- START DONATE AT CHECKOUT SECTION ----*/

.donate-section .top-collection-btn .top-collection-btn_wrp a.top-left-btn:hover {
  text-decoration: none !important;
  color: #000 !important;
}

.donate-section .top-collection-btn .top-collection-btn_wrp a.top-right-btn:hover {
  text-decoration: none !important;
  color: white !important;
}

.donate-section .donate-row {
  display: flex;
  align-items: flex-end;
  padding: 120px 0 250px;
}

.donate-section .donate-row .donate-left {
  max-width: 55%;
  width: 100%;
  margin-bottom: -9%;
  z-index: 2;
  position: relative;
  padding: 20px 20px 20px 0;
}

.donate-section .donate-row .donate-left .main-img-box-cs {
  display: flex;
  max-width: 220px;
  float: right;
  margin-top: -65%;
  position: relative;
  float: right;
  margin-right: -60%;
}

.donate-section .donate-row .donate-left .main-img-box-cs .arrow-moblie {
  position: absolute;
  max-width: 66px;
  top: 40%;
  left: -36%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .donate-section .donate-row .donate-left .main-img-box-cs .arrow-moblie {
    max-width: 45px;
  }
}

@media only screen and (max-width: 540px) {
  .donate-section .donate-row .donate-left .main-img-box-cs {
    max-width: 176px;
  }
}

@media only screen and (max-width: 475px) {
  .donate-section .donate-row .donate-left .main-img-box-cs {
    max-width: 160px;
    margin-right: -65%;
  }
}

@media only screen and (max-width: 375px) {
  .donate-section .donate-row .donate-left .main-img-box-cs {
    max-width: 145px;
    margin-right: -81%;
  }
}

.donate-section .donate-row .donate-left h3,
.donate-section .donate-row .donate-left .h3 {
  font-size: 32pt;
  font-weight: normal;
  letter-spacing: 2.5px;
}

@media only screen and (max-width: 1140px) {

  .donate-section .donate-row .donate-left h3,
  .donate-section .donate-row .donate-left .h3 {
    line-height: 1;
  }
}

@media only screen and (max-width: 540px) {

  .donate-section .donate-row .donate-left h3,
  .donate-section .donate-row .donate-left .h3 {
    font-size: 22pt;
  }
}

.donate-section .donate-row .donate-left p {
  font-size: 18px;
  color: #000;
  line-height: 37px;
  margin-top: 20px;
}

@media only screen and (max-width: 1140px) {
  .donate-section .donate-row .donate-left p {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 912px) {
  .donate-section .donate-row .donate-left p {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 540px) {
  .donate-section .donate-row .donate-left p {
    font-size: 15px;
  }
}

.donate-section .donate-row .donate-right {
  margin-left: -14.7%;
  z-index: 1;
  position: relative;
  max-width: 47.1%;
  width: 100%;
}

.donate-section .donate-row .donate-right .donate-img-box {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .donate-section .donate-row {
    padding-top: 24%;
  }
}

.donate-section .top-collection-btn .top-collection-btn_wrp {
  display: flex;
  justify-content: center;
  padding: 110px 0;
}

.donate-section .top-collection-btn .top-collection-btn_wrp .top-left-btn {
  padding: 10px 12px;
  background: #000;
  max-width: 400px;
  width: 100%;
  font-size: 19px;
  color: #fff !important;
  text-align: center;
  font-weight: 600;
  font-family: arimoregular;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 40px;
  margin-right: 32px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  height: auto;
  transition: 0.3s all ease-in-out;
}

.donate-section .top-collection-btn .top-collection-btn_wrp .top-left-btn:hover {
  background-image: url(https://cdn.shopify.com/s/files/1/0037/3807/5202/files/TTPLandingPage-6CTADonationButtonBG.png?v=1646997843);
  border: 2px solid #000;
  color: #000;
}

@media only screen and (max-width: 1919px) {
  .donate-section .top-collection-btn .top-collection-btn_wrp .top-left-btn {
    padding: 6px 12px;
    font-size: 12px;
    margin-right: 20px;
    height: 47px;
  }
}

@media only screen and (max-width: 767px) {
  .donate-section .top-collection-btn .top-collection-btn_wrp .top-left-btn {
    margin: 0 auto 24px auto;
    max-width: 349px;
  }
}

@media only screen and (max-width: 414px) {
  .donate-section .top-collection-btn .top-collection-btn_wrp .top-left-btn {
    max-width: 300px;
  }
}

.donate-section .top-collection-btn .top-collection-btn_wrp .top-right-btn {
  padding: 10px 12px;
  max-width: 400px;
  width: 100%;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-family: arimoregular;
  color: #000;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 40px;
  height: auto;
  text-decoration: none;
  border: 2px solid #000;
  background-color: #fff;
}

.donate-section .top-collection-btn .top-collection-btn_wrp .top-right-btn:hover {
  background-color: #000;
  color: #fff;
}

@media only screen and (max-width: 1919px) {
  .donate-section .top-collection-btn .top-collection-btn_wrp .top-right-btn {
    padding: 6px 12px;
    font-size: 12px;
    height: 47px;
  }
}

@media only screen and (max-width: 767px) {
  .donate-section .top-collection-btn .top-collection-btn_wrp .top-right-btn {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 767px) {
  .donate-section .top-collection-btn .top-collection-btn_wrp {
    flex-direction: column;
  }
}

/*---- END DONATE AT CHECKOUT SECTION ----*/
/*---- START SHOP COLLECTION SECTION ----*/
.shop-collection-section .container .collection-hadding {
  text-align: center;
  padding-top: 100px;
}

.shop-collection-section .container .collection-hadding h2,
.shop-collection-section .container .collection-hadding .h2 {
  font-size: 33px;
  text-transform: capitalize;
  font-weight: bold;
}

@media only screen and (max-width: 767px) {

  .shop-collection-section .container .collection-hadding h2,
  .shop-collection-section .container .collection-hadding .h2 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-collection-section .container .collection-hadding {
    padding-top: 85px;
  }
}

.shop-collection-section .container .main-collection-slider {
  margin: 53px auto 15px auto;
  display: flex;
  justify-content: space-between;
  max-width: 1015px;
  width: 100%;
}

.shop-collection-section .container .main-collection-slider .collection-slider {
  padding: 0 15px;
}

.shop-collection-section .container .main-collection-slider .collection-slider .collection-inner .collection-inner-text {
  margin-top: 8px;
}

.shop-collection-section .container .main-collection-slider .collection-slider .collection-inner .collection-inner-text h6,
.shop-collection-section .container .main-collection-slider .collection-slider .collection-inner .collection-inner-text .h6 {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 6px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 2px;
}

@media only screen and (max-width: 767px) {

  .shop-collection-section .container .main-collection-slider .collection-slider .collection-inner .collection-inner-text h6,
  .shop-collection-section .container .main-collection-slider .collection-slider .collection-inner .collection-inner-text .h6 {
    font-size: 14px;
    letter-spacing: 2px;
  }
}

.shop-collection-section .container .main-collection-slider .collection-slider .collection-inner .collection-inner-text p {
  font-size: 14px;
  color: #1c1c1c;
}

@media only screen and (max-width: 767px) {
  .shop-collection-section .container .main-collection-slider .collection-slider .collection-inner .collection-inner-text p {
    font-size: 14px;
  }
}

.shop-collection-section .container .main-collection-slider .collection-slider .collection-inner a {
  display: block;
  width: 100%;
}

.shop-collection-section .container .main-collection-slider .collection-slider .collection-inner a img {
  width: 100%;
}

.shop-collection-section .container .main-collection-slider .slick-prev {
  display: block;
  position: absolute;
  left: -5px;
  z-index: 2;
}

.shop-collection-section .container .main-collection-slider .slick-prev:before {
  content: '';
  background-image: url(https://cdn.shopify.com/s/files/1/0037/3807/5202/files/left.png?v=1615532227);
  background-size: contain;
  width: 100%;
  height: 100%;
  background-position: center;
  color: #000;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}

.shop-collection-section .container .main-collection-slider .slick-next {
  display: block;
  position: absolute;
  right: -5px;
}

.shop-collection-section .container .main-collection-slider .slick-next:before {
  content: '';
  background-image: url(https://cdn.shopify.com/s/files/1/0037/3807/5202/files/right.png?v=1615532227);
  background-size: contain;
  width: 100%;
  height: 100%;
  background-position: center;
  color: #000;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 767px) {
  .shop-collection-section .container .main-collection-slider {
    margin: 30px auto 15px auto;
  }
}

.shop-collection-section .container .our-shop-row {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

.shop-collection-section .container .our-shop-row .our-shop-button {
  padding: 6px 12px;
  max-width: 521px;
  width: 100%;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-family: arimoregular;
  color: #000;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 37px;
  height: 70px;
  text-decoration: none;
  border: 2px solid #000;
}

.shop-collection-section .container .our-shop-row .our-shop-button:hover {
  color: #fff;
  background: #000;
}

@media only screen and (max-width: 1919px) {
  .shop-collection-section .container .our-shop-row .our-shop-button {
    padding: 6px 12px;
    font-size: 12px;
    max-width: 360px;
    height: 47px;
  }
}

@media only screen and (max-width: 991px) {
  .shop-collection-section .container .our-shop-row .our-shop-button {
    max-width: 360px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-collection-section .container .our-shop-row .our-shop-button {
    max-width: 349px;
  }
}

@media only screen and (max-width: 414px) {
  .shop-collection-section .container .our-shop-row .our-shop-button {
    max-width: 300px;
  }
}

@media only screen and (max-width: 1024px) {
  .shop-collection-section .container .our-shop-row {
    padding-top: 63px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-collection-section .container .our-shop-row {
    padding-top: 16px;
  }
}

/*---- END SHOP COLLECTION SECTION ----*/
/*===============================================================================
                        START RESPONSIVE
=================================================================================*/
@media only screen and (max-width: 767px) {
  .only-desktop {
    display: none;
  }

  .only-moblie {
    display: block;
  }
}

/*===============================================================================
                        END RESPONSIVE
=================================================================================*/
.product_bag {
  position: absolute;
  z-index: 9;
  right: 10px;
  border: 2px solid #000;
  top: 10px;
  padding: 5px;
  color: #000;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
}

@media (max-width: 991px) {
  .product_bag {
    right: 0;
    top: 10px;
    font-size: 10px;
    letter-spacing: 0;
    margin: 0 5px;
  }
}

.do_not_sell_page {
  /*   b,strong{
  font-family: 'acumin-pro', sans-serif;
} */
}

.do_not_sell_page .bottom_button {
  padding: 50px 0;
  text-align: center;
}

.do_not_sell_page .span12.details.rte {
  padding: 30px 0 0;
}

.do_not_sell_page a {
  text-decoration: underline;
  color: #107bbd;
}

.do_not_sell_page .rte a {
  border-bottom: none;
}

.do_not_sell_page a.bottom_button_submit {
  /*     text-decoration: none;
    color: #fff;
    background-color: #9fc1d6;
    padding: 10px 20px; */
  background: #000;
  color: #fff;
  text-decoration: none;
  border: 0;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  cursor: pointer;
  border-radius: 0;
  outline: none !important;
  -webkit-appearance: none;
  padding: 10px 18px;
  letter-spacing: 1px;
  font-size: 24px;
}

.do_not_sell_page a.bottom_button_submit:hover {
  background: #999;
  color: #fff;
}

body #truyoConsentCookieSettings.truyoConsentCookieSettings {
  padding: 0 20px;
}

@media only screen and (min-width: 768px) {
  body #truyoConsentCookieSettings.truyoConsentCookieSettings .modal-dialog {
    width: 100%;
    max-width: 1140px;
  }
}

body #truyoConsentCookieSettings.truyoConsentCookieSettings .text-align-center {
  justify-content: center;
  display: flex;
}

body .truyoConsentCookieSettings .modal-header {
  border: 0;
}

body .truyoConsentCookieSettings .modal-body {
  padding-top: 0;
}

.text-center {
  text-align: center;
}

.toggle-icon:before {
  content: '+';
  /* Default content is plus sign */
}

.active .toggle-icon:before {
  content: '-';
  /* Change content to minus sign when accordion item is active */
}

span.toggle-icon {
  display: none;
}

.frequently-asked-section {
  margin: 0 70px;
}

/* rebooted end */

/* predictive-search {
  display: none !important;
} */

.aa-Autocomplete .aa-Form {
  border: none !important;
}

.aa-Form:focus-within {
  box-shadow: none !important;
}

.aa-Input {
  height: 20px !important;
  border-bottom: 1px solid black !important;
}

.aa-Input:focus-within {
  border-bottom: 1px solid black !important;
}

.aa-Panel {
  left: 5rem !important;
  right: 5rem !important;
}

.aa-Source[data-autocomplete-source-id='products'] .aa-ItemContent {
  display: inherit !important;
}

/* details-modal.header__search.mobile__Search {
  display: none !important;     edited by vikrant
} */

button.aa-DetachedSearchButton {
  border: none !important;
}

.aa-DetachedSearchButtonPlaceholder {
  display: none !important;
}

.aa-DetachedFormContainer .aa-Form {
  border: none !important;
}

#desktop_review_section .yotpo-shoppers-say .single-best-sentence .sentence,
#mobile_review_section .yotpo-shoppers-say .single-best-sentence .sentence {
  color: #000 !important;
}

#desktop_review_section .yotpo-shoppers-say .single-best-sentence .full-review-background,
#mobile_review_section .yotpo-shoppers-say .single-best-sentence .full-review-background {
  background: transparent !important;
}

#desktop_review_section .yotpo-shoppers-say .single-best-sentence .review-title,
#desktop_review_section .yotpo-shoppers-say .single-best-sentence .full-review,
#mobile_review_section .yotpo-shoppers-say .single-best-sentence .review-title,
#mobile_review_section .yotpo-shoppers-say .single-best-sentence .full-review {
  text-align: left;
  color: #000;
}

#desktop_review_section .yotpo-shoppers-say .single-best-sentence .more-reviews,
#mobile_review_section .yotpo-shoppers-say .single-best-sentence .more-reviews {
  color: #000 !important;
}

#desktop_review_section .yotpo-shoppers-say .shoppers-say-title {
  padding: 0 20px;
  margin-bottom: 12px;
}

#mobile_review_section .yotpo-shoppers-say .shoppers-say-title h5,
#desktop_review_section .yotpo-shoppers-say .shoppers-say-title h5 {
  text-transform: initial;
  margin-top: 5px;
}

#desktop_review_section .yotpo-shoppers-say .single-sentence-content-align {
  text-align: left !important;
}

#desktop_review_section .icon-holder {
  text-align: center;
}

#desktop_review_section .yotpo-shoppers-say .yotpo-icon-down-arrow,
#desktop_review_section .yotpo-shoppers-say .yotpo-icon-up-arrow,
#mobile_review_section .yotpo-shoppers-say .yotpo-icon-down-arrow,
#mobile_review_section .yotpo-shoppers-say .yotpo-icon-up-arrow {
  color: #000 !important;
}

#desktop_review_section .yotpo-display-wrapper.shoppers-say-display-wrapper {
  display: flex;
  flex-wrap: wrap;
}

#desktop_review_section .yotpo-shoppers-say .shoppers-say-title,
#mobile_review_section .shoppers-say-title {
  text-align: left;
  width: 100%;
  color: #000;
  font-family: arimoregular;
  font-size: 18px;
  text-transform: lowercase;
}

#desktop_review_section .yotpo-regular-box {
  width: 50%;
  margin: 0 !important;
  border: none;
  padding: 20px;
}

#desktop_review_section .yotpo-shoppers-say .single-best-sentence .review-stars,
#mobile_review_section .yotpo-shoppers-say .single-best-sentence .review-stars {
  margin: unset !important;
}

#desktop_review_section .yotpo-shoppers-say .single-sentence-content-align {
  text-align: left !important;
}

#desktop_review_section .sentence-container,
#mobile_review_section .sentence-container {
  padding: 0 !important;
}

#desktop_review_section .yotpo-shoppers-say .single-best-sentence .sentence,
#desktop_review_section .yotpo-shoppers-say .single-best-sentence .display-name,
#mobile_review_section .yotpo-shoppers-say .single-best-sentence .sentence,
#mobile_review_section .yotpo-shoppers-say .single-best-sentence .display-name {
  text-align: left;
}

#desktop_review_section .yotpo-shoppers-say .yotpo-icon-star,
#mobile_review_section .yotpo-shoppers-say .yotpo-icon-star,
#desktop_review_section .yotpo-shoppers-say .yotpo-icon-empty-star,
#mobile_review_section .yotpo-shoppers-say .yotpo-icon-empty-star,
#desktop_review_section .yotpo-shoppers-say .yotpo-icon-half-star,
#mobile_review_section .yotpo-shoppers-say .yotpo-icon-half-star {
  color: #000 !important;
}

#desktop_review_section .yotpo-display-wrapper .yotpo-regular-box:nth-child(even) {
  border-right: 2px solid #f2f2f2;
}

.single-sentence-text-content blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}

.section.shopify-section {
  padding: 0px 30px 10px 30px;
}

@media screen and (max-width: 749px) {
  .section.shopify-section {
    padding: 0px 0px 10px 0px;
  }

  .collage {
    flex-direction: column;
  }
}

#gorgias-chat-container {
  z-index: 1;
}

.slider-mobile-gutter .slider-buttons.no-js-hidden.large-up-hide {
  display: none;
}

.revita-slider {
  max-width: 180px;
  width: 100%;
  padding: 0 20px;
}

.revita-slider ul {
  max-height: 140px;
}

.revita-slider .slider-button {
  padding: 0;
  width: 20px;
}

.rebooted_grid .slider__slide .revita-link {
  width: 100%;
  height: 100%;
}

.rebooted_grid .slider__slide .revita-link img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.text-red {
  color: #8a0000 !important;
}

.all_rebooted_items_wrpr .rebooted_options .rebooted_grid .rebootedcnt_wrpr p {
  margin: 0 !important;
}

.popup_revita_slider {
  width: 100%;
  height: 100%;
}

.popup_revita_slider ul {
  height: 100%;
  margin: 0;
}

.popup_revita_slider li {
  max-width: 100% !important;
  height: 100%;
  width: 100% !important;
}

.popup_revita_slider .slider-button {
  padding: 0;
  width: 20px;
}

.popup_revita_slider img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.hidden-mobile {
  display: block !important;
}

.hidden-desktop {
  display: none !important;
}

@media screen and (max-width: 750px) {
  .hidden-desktop {
    display: block !important;
  }

  .hidden-mobile {
    display: none !important;
  }
}

@media screen and (max-width: 749px) {
  .revita-slider {
    max-width: 180px;
  }

  .revita-slider li {
    width: 100% !important;
  }
}

.afterpay-paragraph {
  display: block !important;
}

.product-info__block-item afterpay-placement {
  display: block !important;
}

p.afterpay-paragraph {
  display: block !important;
}


.recurate-container {
  margin-top: 50px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 960px !important;
}

.recurate-container * {
  color: #000 !important;
}

.recurate-h2 {
  font-family: arimoregular !important;
  text-transform: uppercase !important;
  margin: 0 !important;
}

.recurate-container .recurate-body,
.recurate-container .recurate-body p,
.recurate-card-secondary .recurate-body,
.recurate-card-secondary .recurate-body p {
  font-size: 1.5rem !important;
  font-family: arimoregular !important;
  font-weight: bold !important;
  margin: 0 !important;
  font-style: italic !important;
  color: #000 !important;
}

.recurate-line-1 {
  border-top: 1px solid #000 !important;
}

.recurate-container .card {
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  padding: 20px 10px !important;
}

.recurate-card-secondary {
  background-color: #fff !important;
  padding: 10px !important;
}

.recurate-h3 {
  font-size: 1.6rem !important;
  font-family: arimoregular !important;
  text-transform: uppercase !important;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  margin: 0 !important;
}

.recurate-container .mb-10 {
  margin-bottom: 10px !important;
}

.recurate-container .mb-30 {
  margin-bottom: 30px !important;
}

.recurate-card-secondary a.recurate-btn-secondary,
.recurate-container button.recurate-btn-secondary[type='submit'],
.recurate-container .recurate-btn-primary,
.recurate-container button.recurate-btn-primary[type='submit'],
.recurate-container a.recurate-btn-primary,
.recurate-card-secondary .recurate-btn-primary,
.recurate-card-secondary button.recurate-btn-primary[type='submit'],
.recurate-card-secondary a.recurate-btn-primary,
#recurate-cancel-address-btn,
.recurate-btn-secondary:not(a) {
  background-color: #000 !important;
  color: #fff !important;
  border: 1px solid #fff !important;
  height: 5.2rem !important;
  display: flex !important;
  align-items: center !important;
}

#recurate-back-btn,
.search-products form a,
.search-products .case-2-link {
  border-radius: 0 !important;
  background: 0;
  color: #000 !important;
  border: 0;
  border-bottom: 1px solid #000 !important;
  padding: 0 !important;
  line-height: unset !important;
  height: auto !important;
  margin: 0 auto;
  margin-bottom: 1.6rem;
  text-transform: none !important;
  text-decoration: none !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;
  font-family: arimoregular;
}

.recurate-container .my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

a.recurate-body.case-2-link {
  border-bottom: 1px solid #000 !important;
  display: inline-block !important;
  width: 100% !important;
  font-weight: bold !important;
  font-style: italic !important;
}

.recurate-container .my-30 {
  margin: 30px 0px !important;
}

.recurate-container .mt-5 {
  margin-top: 5px !important;
}

.recurate-container .mb-3 {
  margin-bottom: 1rem !important;
}

.recurate-container .recurate-h5,
.recurate-card-secondary .recurate-h5 {
  width: 100% !important;
  font-size: 1.6rem !important;
  font-family: arimoregular !important;
  font-style: normal !important;
  font-weight: bold !important;
  margin: 0 !important;
}

@media (max-width: 576px) {
  .recurate-container .recurate-h5 {
    margin: 0px 0px 20px 0px !important;
  }
}

.recurate-product-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.recurate-container .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
}

.recurate-product-list .card,
#orders-div .card {
  width: 33%;
}

.line-items-container,
.dashboard-container {
  border-bottom: 0 !important;
}

.recurate-h1 {
  font-size: 52px !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-family: arimoregular !important;
  margin: 0 !important;
}

.recurate-row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 !important;
  margin-right: 0;
  min-height: 316px;
  max-height: fit-content;
  margin-left: 0px;
}

#recurate-dashboard-subtitle {
  margin-bottom: 11px !important;
}

.seller-dashboard-view .recurate-row {
  display: flex;
  flex-direction: row;
  min-height: fit-content;
}

.recurate-modal-body .recurate-row {
  display: flex;
  flex-direction: row;
  min-height: fit-content;

}

.submission-seller-phone {
  display: none !important;
}

.recurate-container .mb-20 {
  margin-bottom: 20px !important;
}

.recurate-container .mr-20 {
  margin-right: 20px !important;
}

.recurate-container .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 12px + 2px);
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.recurate-container #new_submission .mt-25,
.recurate-container .edit-submission-form .mt-25,
.recurate-card-secondary #new_submission .mt-25,
.recurate-card-secondary .edit-submission-form .mt-25 {
  margin-top: 25px !important;
}

.recurate-container #new_submission div.mt-25:last-child {
  display: flex;
  column-gap: 5px;
}

@media screen and (max-width: 59em) {

  .recurate-product-list .card,
  #orders-div .card {
    width: 100%;
  }
}

.recurate-product-list .line-items-container .recurate-image-div,
#orders-div .line-items-container .recurate-image-div,
#recurate-submission-list .dashboard-container .recurate-image-div {
  max-width: 65%;
  margin: 0 auto;
  padding: 0;
  flex: auto;
}

@media screen and (max-width: 59em) {

  .recurate-product-list .product-image-size,
  #orders-div .product-image-size {
    margin: 0 auto;
  }
}

.recurate-product-list .line-items-container>div,
#orders-div .line-items-container>div,
#recurate-submission-list .dashboard-container div {
  max-width: 100%;
  padding: 0;
  margin: 0 auto;
  flex: auto;
  margin-top: 15px !important;
  text-align: center !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.recurate-product-list .card.width-100-percent,
#orders-div .card.width-100-percent {
  width: 100% !important;
}

.recurate-container button.recurate-btn-primary[type=submit]:disabled {
  background-color: #999 !important;
  border-color: #999 !important;
  text-decoration: none;
}

.recurate-product-list .display-flex.ptb-10 {
  width: 100% !important;
}

#foxy-mydropzone .featured-image-container .featured-header .text {
  color: #fff !important;
}

.yotpo-head {
  display: none !important;
}

.yotpo-base-layout {
  margin-top: 30px;
}

.yotpo-reviews-main-widget .sr-only {
  position: relative;
  display: none;
}

/* PDP Mobile Nav Dots */
.slick.large-up-hide.medium-hide.medium-up-hide.slick-initialized.slick-slider.slick-dotted {
  overflow: visible;
  margin-bottom: 30px;
}
.slick.large-up-hide.medium-hide.medium-up-hide.slick-initialized.slick-slider.slick-dotted .slick-dots {
  position: relative;
  margin-top: 20px;
}

.slick.large-up-hide.medium-hide.medium-up-hide.slick-initialized.slick-slider.slick-dotted .slick-dots li::after {
  border: none;
  background-color: #f1f1f1;;
  border-radius: 50%;
  opacity: 1 !important;
  content: ' ';
  width: 9px;
  height: 9px;
}

.slick.large-up-hide.medium-hide.medium-up-hide.slick-initialized.slick-slider.slick-dotted .slick-dots .slick-active::after {
  box-shadow: 0 0 0 0.1rem #000 !important;
  border-color: #000 !important;
  background-color: #000 !important;
}
afterpay-placement { 
  --logo-badge-width: 85px !important; 
 }
/* End PDP Mobile Dots*/

div#home_media_banner1 {
  cursor:default;
}

.ais-hit.ais-product .product_img_wp{
  padding-bottom: 0 !important;
}